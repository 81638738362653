import { useQuery } from 'react-query';

export function useDebug() {
  return useQuery('/debug', (): boolean => {
    try {
      // @ts-expect-error: we already catch the error
      return !!JSON.parse(localStorage.getItem('x-untha-debug'));
    } catch {
      return false;
    }
  });
}
