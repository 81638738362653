import React from 'react';
import { useAuth } from 'src/hooks/useAuth';
import { MainMenu } from 'src/components/MainMenu';
import { NavigationBar } from 'src/components/NavigationBar';
import { SideNav } from 'src/components/SideNav';
import { usePirate } from 'src/hooks/usePirate';
import { useLocation } from 'react-router-dom';

type AppShellProps = React.PropsWithChildren<any>;

export function AppShell({ children }: AppShellProps): React.ReactElement {
  const { isSignedIn } = useAuth();
  const _pirateId = usePirate(); // tmp workaround: subscribe to pirate state
  const location = useLocation();

  // reset scroll position on route change
  React.useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <>
      {isSignedIn && <NavigationBar />}
      {isSignedIn && <SideNav />}
      {isSignedIn && <MainMenu />}

      {children}
    </>
  );
}
