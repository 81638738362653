import type { Locale } from 'src/types';
import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import { setHours, setMinutes, startOfDay, subDays } from 'date-fns';

export const presets = {
  today: {
    id: 'today',
    title: 'datePicker.presets.today',
    dateFrom: startOfDay(new Date()),
    dateTo: setHours(setMinutes(startOfDay(new Date()), 55), 23),
  },
  yesterday: {
    id: 'yesterday',
    title: 'datePicker.presets.yesterday',
    dateFrom: subDays(startOfDay(new Date()), 1),
    dateTo: setHours(setMinutes(subDays(startOfDay(new Date()), 1), 55), 23),
  },
  past3Days: {
    id: 'past3Days',
    title: 'datePicker.presets.past3Days',
    dateFrom: subDays(startOfDay(new Date()), 2),
    dateTo: setHours(setMinutes(startOfDay(new Date()), 55), 23),
  },
  past7Days: {
    id: 'past7Days',
    title: 'datePicker.presets.past7Days',
    dateFrom: subDays(startOfDay(new Date()), 6),
    dateTo: setHours(setMinutes(startOfDay(new Date()), 55), 23),
  },
};

export const dateLocaleMap: Record<Locale, typeof de> = {
  en: enUS,
  de,
  es,
  fr,
  it,
};
