import React from 'react';
import { menuItems } from 'src/menuItems';
import { useShredders } from 'src/hooks/useShredders';
import { useDebug } from './useDebug';

export function useMenuItems() {
  const shredders = useShredders();
  const { data: debugEnabled } = useDebug();

  const toReducer = (to: string) => {
    switch (true) {
      case to === '/':
        if (shredders.length === 1) {
          return `/shredders/${shredders[0].cumulocityId}`;
        }
        break;
    }

    return to;
  };

  const value = React.useMemo(
    () =>
      menuItems
        .map((menuItem) => ({ ...menuItem, to: toReducer(menuItem.to) }))
        .filter((menuItem) => debugEnabled || !menuItem.debugOnly),
    [shredders, debugEnabled],
  );

  return value;
}
