import type { RouteProps } from 'react-router-dom';
import type { AuthRole, RuleId } from 'src/rbacRules';
import { preloadableLazy } from 'src/experimental';
import type { PreloadableLazyComponent } from 'src/experimental';

const ShredderDetailPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/shredders/shredder/details'),
);
const AdminPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/admin'),
);
const ShreddersPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/shredders'),
);
const ProfilePage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/profile'),
);
const ExplorerPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/explorer'),
);
const ExplorerIntroPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/explorer-intro'),
);
const ExplorerDetailPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/explorer-detail'),
);
const LoginPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/login'),
);
const ReportsPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/reports'),
);
const NotificationsPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/notifications'),
);
const FaqPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/faq'),
);
const RegisterPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/register'),
);
const ForgotPasswordPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/forgot-password'),
);
const NewPasswordPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/new-password'),
);
const RegistrationConfirmationPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/registration-confirmation'),
);
const CfPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/cfPage'),
);
const NotFoundPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/404'),
);
const DevPage = preloadableLazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/dev'),
);

const PdfReportCoverPage = preloadableLazy(
  /* webpackPrefetch: true */ () => import('src/pages/pdf-report/cover'),
);

const PdfReportPage = preloadableLazy(
  /* webpackPrefetch: true */ () => import('src/pages/pdf-report/index'),
);

export type RouteType = Omit<RouteProps, 'component'> & {
  component: PreloadableLazyComponent;
  protected?: boolean;
  rule?: RuleId;
};

export const entryPointByRole: Record<AuthRole, string> = {
  basic: '/',
  genius: '/',
  unthaAdmin: '/admin',
  companyAdmin: '/',
};

export const routes: RouteType[] = [
  {
    // NOTE:
    // This route is available under two main routes
    path: [
      '/shredders/:cumulocityId', // --> ShredderPage (nested inside ShreddersPage)
      '/shredders',
      '/',
    ],
    component: ShreddersPage,
    protected: true,
    exact: true,
    rule: 'shredders-page:visit',
  },
  {
    path: '/profile',
    component: ProfilePage,
    protected: true,
    exact: true,
    rule: 'profile-page:visit',
  },
  {
    path: '/shredders/:cumulocityId/:shredderDetail',
    component: ShredderDetailPage,
    protected: true,
    exact: true,
    rule: 'shredder-detail-page:visit',
  },
  {
    path: '/admin',
    component: AdminPage,
    protected: true,
    exact: true,
    rule: 'admin-page:visit',
  },
  {
    path: '/explorer',
    component: ExplorerPage,
    protected: true,
    exact: true,
    rule: 'explorer-page:visit',
  },
  {
    path: '/explorer/:cumulocityId',
    component: ExplorerIntroPage,
    protected: true,
    exact: true,
    rule: 'explorer-intro-page:visit',
  },
  {
    path: '/explorer/:cumulocityId/:presetId',
    component: ExplorerDetailPage,
    protected: true,
    exact: true,
    rule: 'explorer-detail-page:visit',
  },
  {
    path: '/reports',
    component: ReportsPage,
    protected: true,
    rule: 'reports-page:visit',
  },
  {
    path: '/notifications',
    component: NotificationsPage,
    protected: true,
    rule: 'notifications-page:visit',
  },
  {
    path: '/faq',
    component: FaqPage,
    protected: true,
    exact: true,
    rule: 'faq-page:visit',
  },
  {
    path: '/register',
    component: RegisterPage,
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
    exact: true,
  },
  {
    path: '/new-password',
    component: NewPasswordPage,
    exact: true,
  },
  {
    path: '/login',
    component: LoginPage,
    exact: true,
  },
  {
    path: '/registration-confirmation/:token',
    component: RegistrationConfirmationPage,
    exact: true,
  },
  {
    path: '/dev',
    component: DevPage,
    protected: true,
    exact: true,
    rule: 'dev-page:visit',
  },
  {
    path: '/404',
    component: NotFoundPage,
  },
  {
    path: '/pdf-report/cover/:reportId',
    exact: true,
    component: PdfReportCoverPage,
  },
  {
    path: '/pdf-report/:reportId',
    exact: true,
    component: PdfReportPage,
  },
  {
    path: '*', // catch-all route
    component: CfPage,
  },
];
