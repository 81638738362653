import { useQuery } from 'react-query';
import { queryKeys } from 'src/constants';
import { getUser } from 'src/services/user';
import { useAuth } from 'src/hooks/useAuth';

export const useUser = () => {
  const { isSignedIn } = useAuth();

  return useQuery(queryKeys.getUser, () => getUser(), {
    enabled: isSignedIn,
  });
};
