import React from 'react';
import clsx from 'clsx';
import { Stack, Icon, Button, Headline, tertiaryLinkHoverStyle, Tag } from '@untha/ui';
import { css } from '@emotion/react';
import { ReactComponent as MenuIcon } from 'src/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'src/icons/close.svg';
import { ProfileIcon } from 'src/components/ProfileIcon';
import { useMainMenu } from 'src/hooks/useMainMenu';
import { useSpring, animated } from 'react-spring';
import { useUser } from 'src/hooks/useUser';
import { useAuth } from 'src/hooks/useAuth';
import { PreloadNavLink } from 'src/components/PreloadNavLink';
import { NotificationCount } from 'src/components/NotificationCount';
import { AnimatedBrand } from 'src/components/AnimatedBrand';
import { useMenuItems } from 'src/hooks/useMenuItem';
import { useAnnouncementReadOnly } from 'src/hooks/useAnnouncementReadOnly';
import { ReactComponent as ShowIcon } from 'src/icons/show.svg';
import { usePirate } from 'src/hooks/usePirate';
import { useIntl } from 'react-intl';

const sidebarWidth = 72; // equals to space-12

const navLinkStyle = css`
  /* style react-router link */
  &.active {
    color: var(--color-primary);
  }
`;

const AnimatedInnerContainer = animated.div;

function QuickLink({
  to,
  icon,
  ...linkProps
}: {
  to: string;
  icon: React.FC<any>;
  title: string;
}): React.ReactElement {
  const { formatMessage } = useIntl();
  const { data: announcement } = useAnnouncementReadOnly(to);

  return (
    <PreloadNavLink
      activeClassName="active"
      css={[tertiaryLinkHoverStyle, navLinkStyle]}
      to={to}
      className="relative"
      {...linkProps}
    >
      <Icon icon={icon} size="large" />

      {to === '/notifications' && (
        <NotificationCount
          className="absolute"
          css={css`
            top: -8px;
            right: -8px; ;
          `}
        />
      )}

      {announcement?.read === false && (
        <Tag
          variant="info"
          className="absolute transform -translate-x-1/2 translate-y-full"
          css={css`
            bottom: calc(-1 * var(--space-1));
            left: 50%;
          `}
        >
          <Headline variant="tinyBold">
            {formatMessage({ id: 'tag.newAbbr.text' })}
          </Headline>
        </Tag>
      )}
    </PreloadNavLink>
  );
}

export function SideNav(): React.ReactElement {
  const { formatMessage } = useIntl();
  const { isAuthorized } = useAuth();
  const { isOpen, close, open } = useMainMenu();
  const { data: { profile } = {} } = useUser();
  const menuItems = useMenuItems();
  const { x } = useSpring({
    from: { x: 0 },
    to: { x: !isOpen ? 0 : sidebarWidth },
  });
  const { data: pirateUser } = usePirate();

  const handleToggleMenu = () => {
    isOpen ? close() : open();
  };

  return (
    <div
      css={css`
        z-index: var(--z-index-sidebar);
        background-color: var(--color-white);
      `}
      className={clsx(
        'hidden',
        'sm:flex',
        'print:hidden',
        'w-12',
        'py-6',
        'fixed',
        'top-0',
        'left-0',
        'h-full',
        'items-center',
        'flex-col',
        'overflow-hidden',
      )}
    >
      <div
        className="mb-10 flex-shrink-0"
        css={css`
          height: 34px; /* placeholder for brand svg */
        `}
      >
        <AnimatedBrand
          className="fixed"
          css={css`
            left: 15px;
          `}
          size={isOpen ? 'default' : 'small'}
        />
      </div>

      {/* moving aside container */}
      <AnimatedInnerContainer
        className="flex h-full self-end"
        style={{ transform: x.to((x) => `translate3d(${x}px, 0, 0) `) }}
      >
        {/* open state */}
        <div
          className={clsx(
            'flex',
            'flex-col',
            'flex-shrink-0',
            'items-center',
            'h-full',
            'w-12',
          )}
        >
          {/* menu label */}
          <div className={clsx('flex-grow', 'flex', 'items-center', '-mt-10')}>
            <Headline
              css={css`
                writing-mode: vertical-rl;
                transform: rotate(180deg);
              `}
              variant="caps"
            >
              Menu
            </Headline>
          </div>

          {/* close button */}
          <Button
            css={tertiaryLinkHoverStyle}
            onClick={handleToggleMenu}
            as="button"
            variant="tertiary"
          >
            <Icon icon={CloseIcon} size="large" />
          </Button>
        </div>

        {/* closed state */}
        <div
          className={clsx(
            'flex',
            'h-full',
            'flex-shrink-0',
            'items-center',
            'flex-col',
            'justify-between',
            'w-12',
          )}
        >
          {/* quick-links */}
          <Stack className="justify-items-center" gap={8}>
            {menuItems.map(
              ({ icon, rule, to, title, debugOnly: _debugOnly, ...linkProps }) =>
                isAuthorized(rule) ? (
                  <QuickLink
                    key={to}
                    icon={icon}
                    to={to}
                    title={formatMessage({ id: title })}
                    {...linkProps}
                  />
                ) : null,
            )}
          </Stack>

          <div className={clsx('flex', 'flex-col', 'items-center')}>
            {/* profile-icon */}
            {profile?.firstName && (
              <PreloadNavLink
                className="mb-4 relative"
                to="/profile"
                activeClassName="active"
                css={[tertiaryLinkHoverStyle, navLinkStyle]}
                title={formatMessage({ id: 'navItem.profile.title' })}
              >
                <Icon
                  css={css`
                    width: 40px;
                    height: 40px;
                  `}
                  icon={(props) => (
                    <ProfileIcon
                      {...props}
                      firstName={profile.firstName}
                      lastName={profile.lastName}
                    />
                  )}
                />

                {pirateUser !== undefined && (
                  <Icon
                    css={css`
                      background-color: var(--color-error-light);
                      border: 3px solid var(--color-white);
                    `}
                    size="large"
                    className="absolute text-error top-0 right-0 rounded-full transform translate-x-1/2 -translate-y-1/2"
                    icon={ShowIcon}
                  />
                )}
              </PreloadNavLink>
            )}

            {/* open button */}
            <Button
              css={tertiaryLinkHoverStyle}
              onClick={handleToggleMenu}
              as="button"
              variant="tertiary"
            >
              <Icon icon={MenuIcon} size="large" />
            </Button>
          </div>
        </div>
      </AnimatedInnerContainer>
    </div>
  );
}
