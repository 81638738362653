import type { User } from 'src/types';

// Role-Based Access Control Rules

// todo: add dynamicRule data context typings

export enum AuthRole {
  basic = 'basic',
  genius = 'genius',
  companyAdmin = 'companyAdmin',
  unthaAdmin = 'unthaAdmin',
}

export type RuleType = 'static' | 'dynamic';

/**
 * Available pages or apis
 */
export type ResourceApi = 'measurements-api' | 'sub-users-api' | 'notifications-api';

export type ResourcePage =
  | 'notification-page'
  | 'notifications-page'
  | 'explorer-page'
  | 'explorer-detail-page'
  | 'explorer-intro-page'
  | 'faq-page'
  | 'profile-page'
  | 'reports-page'
  | 'shredders-page'
  | 'shredder-page'
  | 'shredder-detail-page'
  | 'admin-page'
  | 'dev-page';

export type Action = 'get' | 'delete' | 'create' | 'update';

export type RuleId = `${ResourcePage}:visit` | `${ResourceApi}:${Action}`;

export type Rules = {
  [k in AuthRole]: {
    static?: RuleId[];
    dynamic?: {
      [k in RuleId]?: (data: any) => boolean;
    };
  };
};

// reusable tests
function pirateTest(data: { pirated: boolean }): boolean {
  return data.pirated;
}

export const rules: Rules = {
  basic: {
    static: [
      // apis
      'measurements-api:get',
      'notifications-api:get',
      'notifications-api:create',
      'notifications-api:delete',
      'notifications-api:update',

      // pages
      'notification-page:visit',
      'notifications-page:visit',
      'explorer-page:visit',
      'explorer-detail-page:visit',
      'explorer-intro-page:visit',
      'faq-page:visit',
      'profile-page:visit',
      'reports-page:visit',
      'shredders-page:visit',
      'shredder-page:visit',
      'shredder-detail-page:visit',
    ],
    dynamic: {
      'shredders-page:visit': (user: User): boolean => user.shredders.length > 1,
    },
  },
  genius: {
    static: [
      // apis
      'measurements-api:get',
      'notifications-api:get',
      'notifications-api:create',
      'notifications-api:delete',
      'notifications-api:update',

      // pages
      'notification-page:visit',
      'notifications-page:visit',
      'explorer-page:visit',
      'explorer-detail-page:visit',
      'explorer-intro-page:visit',
      'faq-page:visit',
      'profile-page:visit',
      'reports-page:visit',
      'shredder-page:visit',
      'shredders-page:visit',
      'shredder-detail-page:visit',
    ],
  },
  companyAdmin: {
    static: [
      // apis
      'sub-users-api:get',
      'sub-users-api:delete',
      'sub-users-api:create',
      'measurements-api:get',
      'notifications-api:get',
      'notifications-api:create',
      'notifications-api:delete',
      'notifications-api:update',

      // pages
      'notification-page:visit',
      'notifications-page:visit',
      'explorer-page:visit',
      'explorer-detail-page:visit',
      'explorer-intro-page:visit',
      'faq-page:visit',
      'profile-page:visit',
      'reports-page:visit',
      'shredder-page:visit',
      'shredders-page:visit',
      'shredder-detail-page:visit',
    ],
  },
  unthaAdmin: {
    static: [
      // apis
      'sub-users-api:get',
      'sub-users-api:delete',
      'sub-users-api:create',

      // pages
      'admin-page:visit',
      'profile-page:visit',
      'dev-page:visit',
    ],
    dynamic: {
      'reports-page:visit': pirateTest,
      'notification-page:visit': pirateTest,
      'notifications-page:visit': pirateTest,
      'explorer-page:visit': pirateTest,
      'explorer-intro-page:visit': pirateTest,
      'explorer-detail-page:visit': pirateTest,
      'shredder-page:visit': pirateTest,
      'shredders-page:visit': pirateTest,
      'shredder-detail-page:visit': pirateTest,
      'faq-page:visit': pirateTest,
    },
  },
};
