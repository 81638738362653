import type { NavLinkProps } from 'react-router-dom';
import { ReactComponent as GridIcon } from 'src/icons/grid.svg';
import { ReactComponent as HelpIcon } from 'src/icons/help.svg';
import { ReactComponent as ChartIcon } from 'src/icons/chart.svg';
import { ReactComponent as ReportIcon } from 'src/icons/report.svg';
import { ReactComponent as NotificationIcon } from 'src/icons/notification.svg';
import { ReactComponent as SettingsIcon } from 'src/icons/settings.svg';
import { ReactComponent as ToolsIcon } from 'src/icons/tools.svg';
import { RuleId } from 'src/rbacRules';

export type MenuItem = NavLinkProps & {
  to: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  rule?: RuleId;
  title: string;
  description: string;
  debugOnly?: boolean;
};

export const menuItems: MenuItem[] = [
  {
    to: '/admin',
    icon: SettingsIcon,
    rule: 'admin-page:visit',
    title: 'navItem.admin.title',
    description: 'navItem.admin.description',
  },
  {
    to: '/', // --> might get overridden if the user has only 1 shredder
    icon: GridIcon,
    exact: true, // prevent falsy link active states
    rule: 'shredders-page:visit',
    title: 'navItem.dashboard.title',
    description: 'navItem.dashboard.description',
  },
  {
    to: '/notifications',
    icon: NotificationIcon,
    rule: 'notifications-page:visit',
    title: 'navItem.notifications.title',
    description: 'navItem.notifications.description',
  },
  {
    to: '/reports',
    icon: ReportIcon,
    rule: 'reports-page:visit',
    title: 'navItem.report.title',
    description: 'navItem.report.description',
  },
  {
    to: '/explorer',
    icon: ChartIcon,
    rule: 'explorer-page:visit',
    title: 'navItem.explorer.title',
    description: 'navItem.explorer.description',
  },
  {
    to: '/faq',
    icon: HelpIcon,
    rule: 'faq-page:visit',
    title: 'navItem.faq.title',
    description: 'navItem.faq.description',
  },
  {
    to: '/dev',
    icon: ToolsIcon,
    rule: 'dev-page:visit',
    title: 'navItem.dev.title',
    description: 'navItem.dev.description',
    debugOnly: true,
  },
];
