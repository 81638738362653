import {
  format,
  parseISO,
  isSameDay,
  parseJSON,
  parse,
  isValid,
  differenceInSeconds,
  startOfDay,
} from 'date-fns';
import { matchSorter } from 'match-sorter';
import { Row } from 'react-table';
import { dateLocaleMap } from 'src/components/common/datePicker';
import type {
  AttrGroup,
  Period,
  RawMeasurement,
  Shredder,
  ShredderForeignAttribute,
  ShredderStat,
  StringifiedPeriod,
  UnitId,
  WarrantyPeriod,
} from 'src/types';
import { MeasurementUnit, measurementUnits } from 'src/units';

export function replaceItemAtIndex<T extends any>(
  list: T[],
  index: number,
  item: T,
): T[] {
  return [...list.slice(0, index), item, ...list.slice(index + 1)];
}

export function removeItemAtIndex<T extends any>(list: T[], index: number): T[] {
  return [...list.slice(0, index), ...list.slice(index + 1)];
}

/**
 * Safely parse periods with ISO date strings
 */
export const parsePeriod = ({ dateFrom, dateTo }: StringifiedPeriod): Period => ({
  dateFrom: typeof dateFrom === 'string' ? parseISO(dateFrom) : dateFrom,
  dateTo: typeof dateTo === 'string' ? parseISO(dateTo) : dateTo,
});

/**
 * Stringify a period in ISO date format
 */
export const stringifyPeriod = ({ dateFrom, dateTo }: Period): StringifiedPeriod => ({
  dateFrom: dateFrom instanceof Date ? dateFrom.toISOString() : dateFrom,
  dateTo: dateTo instanceof Date ? dateTo.toISOString() : dateTo,
});

export const isEqualTimeOfDay = (date1: Date, date2: Date): boolean =>
  Math.abs(differenceInSeconds(date1, startOfDay(date1))) ===
  Math.abs(differenceInSeconds(date2, startOfDay(date2)));

export const formatPeriods = (
  periods: StringifiedPeriod[],
  useExactDate?: boolean,
  timeFormat = 'HH:mm',
): string => {
  const results: string[] = [];
  const formatString = `dd.MM.yyyy ${timeFormat}`;
  const abbrFormatString = `EEEEEE ${timeFormat}`;
  // scheduled reports might include multiple periods and
  // we don't care about the exact date so we only show weekdays!
  const displayWeekdays = useExactDate ?? periods.length > 1;
  // Omit separate time ranges for weekdays if they have equal time of day
  const consistentTime = periods.every((period, index, self) => {
    const prevPeriod = self[index - 1];

    if (self.length === 1 || (self.length > 1 && !prevPeriod)) {
      return true;
    }

    const parsedPeriod = parsePeriod(period);
    const parsedPrevPeriod = parsePeriod(prevPeriod);

    return (
      isEqualTimeOfDay(parsedPrevPeriod.dateFrom, parsedPeriod.dateFrom) &&
      isEqualTimeOfDay(parsedPrevPeriod.dateTo, parsedPrevPeriod.dateTo)
    );
  });
  const formatConfig = {
    locale: dateLocaleMap[(JSON.parse(localStorage.getItem('locale')!) as 'en') ?? 'en'],
  };

  for (let i = 0; i < periods.length; i += 1) {
    const { dateFrom, dateTo } = parsePeriod(periods[i]);
    const sameDay = isSameDay(dateFrom, dateTo);

    let dateFromFormat: string;
    let dateToFormat: string | null;

    switch (true) {
      case consistentTime && useExactDate && i === periods.length - 1:
        // result: e.g. Fr 12:00 - 18:00
        dateFromFormat = abbrFormatString;
        dateToFormat = timeFormat;
        break;
      case consistentTime && useExactDate:
        // result: e.g. Mo; Tu; We
        dateFromFormat = 'EEEEEE';
        dateToFormat = null;
        break;
      case displayWeekdays && sameDay:
        // result: e.g. Mo 12:00 - 13:00
        dateFromFormat = abbrFormatString;
        dateToFormat = timeFormat;
        break;
      case displayWeekdays && !sameDay:
        // result: e.g. Mo 12:00 - Tue 13:00
        dateFromFormat = abbrFormatString;
        dateToFormat = `EEEEEE ${timeFormat}`;
        break;
      case !displayWeekdays && sameDay:
        // result: e.g. 01.12.2020 12:00 - 13:00
        dateFromFormat = formatString;
        dateToFormat = timeFormat;
        break;
      default:
        // result: e.g. 01.12.2020 12:00 - 02.12.2020 13:00
        dateFromFormat = formatString;
        dateToFormat = formatString;
    }

    const formattedDateFrom = format(dateFrom, dateFromFormat, formatConfig);

    if (dateToFormat && dateFromFormat) {
      const formattedDateTo = format(dateTo, dateToFormat, formatConfig);
      results.push(`${formattedDateFrom} - ${formattedDateTo}`);
    } else {
      results.push(format(dateFrom, dateFromFormat, formatConfig));
    }
  }

  return results.join(', ');
};

export function prettyEncodeURI(input: string): string {
  return encodeURI(
    input
      .trim()
      .replaceAll('é', '')
      .replaceAll('ä', 'ae')
      .replaceAll('ö', 'oe')
      .replaceAll('ü', 'ue')
      .replaceAll('ß', 'ss')
      .replace(/\s+/gm, '-'),
  );
}

export function getIANATimeZoneName(): string | undefined {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    return undefined;
  }
}

export function formatNumber(input: number, format: UnitId | MeasurementUnit): string {
  // EXAMPLES
  // 1. lang        'de' <- persisted in ls
  //    currency    'eur' <- persisted in ls
  //    unitSystem  'metric' <- persisted in ls
  //    result:     1.234,40€ <- note decimal point
  // -----------------------
  // 2. lang        'en'
  //    currency    'usd'
  //    unitSystem  'imperial'
  //    result:     $1,234.40

  const config = typeof format === 'string' ? measurementUnits[format] : format;

  if (!config) {
    console.warn(
      `No format found for "${format}". If the input format is correct, please adapt units.ts`,
    );
    return String(input);
  }

  const numberFormatter = new Intl.NumberFormat('de', {
    minimumFractionDigits: config.minFractions,
    maximumFractionDigits: config.maxFractions,
  });

  return numberFormatter.format(input);
}

export const isBrowser = (): boolean => typeof window !== 'undefined';

export function setCSSVariable(
  key: string,
  value: string | number,
  target?: HTMLElement | null,
): void {
  const finalTarget = target || (window.document.getRootNode() as HTMLElement);
  finalTarget.style.setProperty(key, String(value));
}

export function getStatus(state: number | undefined): ShredderStat {
  switch (state) {
    case 5:
    case 10:
      return 'defectExternal';
    case 11:
      return 'defect';
    case 14:
      return 'maintenance';
    case 0:
    case 4:
    case 15:
      return 'rest';
    case 3:
    case 16:
      return 'standby';
    case 1:
    case 2:
    case 17:
      return 'active';
    case 18:
      return 'cleaning';
    case 19:
      return 'overload';
    case 20:
      return 'idle';
    case 21:
      return 'eco';
    case 100:
      return 'offline';
    default:
      return 'offline';
  }
}

export function fuzzyTextFilterFn<T extends object>(
  rows: Row<T>[],
  id: keyof T,
  filterValue: string,
) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id as any]] });
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: string) => !val;

export const isAttrGroup = (input: any): input is AttrGroup =>
  'group' in input && Array.isArray(input.group);

/**
 * Group notifications (alarms/events) by day
 */
export function groupByDay<T extends { time: string }>(
  list: T[],
): { groupDate: Date; entries: T[] }[] {
  const groups: { groupDate: Date; entries: T[] }[] = [];

  for (let i = 0; i < list.length; i += 1) {
    const cur = list[i];
    const parsedTime = parseJSON(cur.time);
    const targetGroupIndex = groups.findIndex((g) => isSameDay(g.groupDate, parsedTime));

    if (targetGroupIndex !== -1) {
      groups[targetGroupIndex].entries.push(cur);
    } else {
      groups.push({ groupDate: parsedTime, entries: [cur] });
    }
  }

  return groups;
}

export const parseShredderCharacteristics = (
  characteristics: Record<string, string>,
): Shredder['shredderCharacteristics'] =>
  Object.fromEntries(
    Object.entries(characteristics).reduce<[string, number | boolean][]>(
      (entries, [key, value]) => {
        try {
          return [...entries, [key, JSON.parse(value)]];
        } catch (e) {
          console.warn('Unable to parse shredder characteristic', e);
          return entries;
        }
      },
      [],
    ),
  );

export function generateGetBoundingClientRect(x = 0, y = 0) {
  return () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
  });
}

export function generateVirtualElement() {
  return {
    getBoundingClientRect: generateGetBoundingClientRect(),
  };
}

export const formatWarrantyPeriod = ({ dateFrom, dateTo }: Partial<WarrantyPeriod>) => {
  if (!dateFrom || !dateTo) {
    return '-';
  }

  const parsedFrom = parse(
    dateFrom,
    dateFrom.includes('-') ? 'dd-MM-yyyy' : 'dd.MM.yyyy',
    new Date(),
  );
  const parsedTo = parse(
    dateTo,
    dateTo.includes('-') ? 'dd-MM-yyyy' : 'dd.MM.yyyy',
    new Date(),
  );

  return [parsedFrom, parsedTo].map((d) => format(d, 'dd.MM.yyyy')).join(' – ');
};

export function parseCalculation(calculation: string) {
  const templateReg = /\$\{(\w+)\}/g;
  const variables = [...calculation.matchAll(templateReg)]
    .map((group) => group?.[1])
    .filter(Boolean);
  const validatedCalculation = calculation.replace(templateReg, '$1');

  return {
    dependencies: variables,
    calculation: validatedCalculation,
  };
}

export const parseMeasurement = (rawMeasurement: RawMeasurement) => ({
  value: parseFloat(rawMeasurement.value) || 0,
  timestamp: isValid(new Date(rawMeasurement.date))
    ? new Date(rawMeasurement.date)
    : new Date(),
});

export const patchRemoteShredderAttr = ({
  thresholds,
  calculatedMax,
  calculatedMin,
  ...rest
}: ShredderForeignAttribute) => ({
  ...(thresholds ? { threshold: thresholds } : undefined),
  ...(calculatedMin ? { minValue: calculatedMin } : undefined),
  ...(calculatedMax ? { maxValue: calculatedMax } : undefined),
  ...rest,
});
