import React from 'react';
import ReactDOM from 'react-dom';
import App from 'src/App';
import { Auth } from '@aws-amplify/auth';
import amplifyConfig from 'src/amplify.config';
import { configure as configureHttpClient } from 'src/services';
import * as serviceWorkerRegistration from 'src/serviceWorkerRegistration';

Auth.configure(amplifyConfig);
configureHttpClient();

// @ts-expect-error: createRoot is currently experimental
ReactDOM.unstable_createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
