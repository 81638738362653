import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'src/constants';
import { updateUserProfile } from 'src/services/user';
import { toast } from 'react-toastify';
import type { User } from 'src/types';
import axios from 'axios';

type UseUpdateProfileProps = {
  successToastMessage?: string;
};

export const useUpdateProfile = ({ successToastMessage }: UseUpdateProfileProps = {}) => {
  const queryClient = useQueryClient();

  return useMutation(updateUserProfile, {
    onMutate: (updatedProfile) => {
      const prev = queryClient.getQueryData<User>(queryKeys.getUser);

      axios.defaults.headers['x-untha-language'] = updatedProfile.locale;

      queryClient.setQueryData<User | undefined>(queryKeys.getUser, (prevUser) => {
        if (prevUser) {
          return { ...prevUser, profile: updatedProfile };
        }
      });

      return () => {
        axios.defaults.headers['x-untha-language'] = prev?.profile.locale;
        queryClient.setQueryData(queryKeys.getUser, prev);
      };
    },
    onSuccess: () => {
      queryClient.refetchQueries(queryKeys.getUser);
      // todo: add update energy settings mutation
      queryClient.invalidateQueries(queryKeys.getMeasurement);
      queryClient.invalidateQueries(queryKeys.getMeasurements);
      queryClient.invalidateQueries(queryKeys.getAggregation);
      toast(successToastMessage);
    },
  });
};
