import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import type { UseMutationResult } from 'react-query';
import * as authService from 'src/services/auth';
import axios from 'axios';
import { rules } from 'src/rbacRules';
import type { AuthRole } from 'src/rbacRules';
import type { Rules, RuleId } from 'src/rbacRules';
import { queryKeys, authChallenges } from 'src/constants';
import type { CognitoUser } from '@aws-amplify/auth';
import type { UserCredentials, Locale } from 'src/types';
import { toast } from 'react-toastify';
import { useResetRecoilState } from 'recoil';
import { pirateIdState } from 'src/states/pirate';

declare module '@aws-amplify/auth' {
  interface CognitoUser {
    // todo: add challenge union types
    challengeName?: string;
  }
}

type AuthCtx = {
  signIn: UseMutationResult<CognitoUser, unknown, UserCredentials, unknown>;
  signOut: UseMutationResult<void, unknown, void, unknown>;
  isAuthorized: (action: RuleId | undefined, data?: any) => boolean;
  isSignedIn: boolean;
  roles: AuthRole[];
  iotToken: string;
  authChallenge?: {
    name: CognitoUser['challengeName'];
    data: CognitoUser;
  };
  completeChallenge: () => void;
};

// Unfortunately we don't have the locale context here, so we need to improvise.
const dict: Record<Locale, Record<string, string>> = {
  de: {
    'service.auth.login.error': 'Login fehlgeschlagen!',
  },
  en: {
    'service.auth.login.error': 'Invalid login!',
  },
  es: {
    'service.auth.login.error': 'Fallo en el inicio de sesión.',
  },
  fr: {
    'service.auth.login.error': 'Connexion invalide !',
  },
  it: {
    'service.auth.login.error': 'Accesso non valido!',
  },
};

const authContext = React.createContext(undefined as any);

function checkAuth(rules: Rules, role: AuthRole, action: RuleId, data: any): boolean {
  const permissions = rules[role];
  const localPirateId = localStorage.getItem('PIRATE/id');

  // just in case someone doesn't give a fuck about types
  // and the role is not present in the rule set
  if (!permissions) return false;

  const staticPermissions = permissions.static;

  if (staticPermissions?.includes(action)) {
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];

    if (
      permissionCondition &&
      permissionCondition({ ...data, pirated: !!localPirateId })
    ) {
      return true;
    }
  }

  return false;
}

export const AuthProvider: React.FC = ({ children }) => {
  const queryClient = useQueryClient();
  const [authChallenge, setAuthChallenge] = React.useState<{
    name: CognitoUser['challengeName'];
    data: CognitoUser;
  }>();
  // NOTE: undefined represents the loading state and is most likely not to be reached due to suspense
  // first things first, we need to check if the user still has an valid previous auth session
  const { data: authToken, refetch: refetchAuth } = useQuery(
    queryKeys.getAuthToken,
    () => authService.getAuthToken(),
    {
      staleTime: Infinity,
    },
  );
  // after the auth-token promise has settled, we continue with the iotToken and decide whether
  // to fetch the iot-token
  const { data: iotToken, refetch: refetchIot } = useQuery(
    queryKeys.getIotToken,
    () => authService.getIotToken(),
    {
      enabled: !!authToken,
      onSuccess: (iotToken) => {
        // set global httpClient iotToken
        axios.defaults.headers['x-untha-iot'] = iotToken;
      },
      onError: () =>
        void toast(
          dict[(JSON.parse(localStorage.getItem('locale')!) as 'en') ?? 'en'][
            'service.auth.login.error'
          ],
        ),
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 5, // 5 minutes
      retry: 2,
    },
  );
  // fetch roles for the role-based-access-control
  const { data: roles } = useQuery(queryKeys.getRoles, () => authService.getRoles(), {
    enabled: !!authToken,
  });
  const resetPirateId = useResetRecoilState(pirateIdState);

  /**
   * Sign-in via Amplify and kick off the whole auth process
   */
  const signIn = useMutation(authService.signIn, {
    onSuccess: async (data) => {
      setAuthChallenge({
        name: data.challengeName,
        data, // additional user data to complete challenges
      });
      await refetchAuth();
      if (data.challengeName !== authChallenges.resetPassword) {
        refetchIot();
      }
    },
    onError: () =>
      void toast(
        dict[(JSON.parse(localStorage.getItem('locale')!) as 'en') ?? 'en'][
          'service.auth.login.error'
        ],
      ),
  });

  /**
   * Sign-out the user and clear the auth-store to prepare for a fresh session
   */
  const signOut = useMutation(authService.signOut, {
    onSuccess: () => {
      // clear pirateId
      resetPirateId();

      // removing the auth token will redirect the user to /login
      queryClient.setQueryData(queryKeys.getAuthToken, null);
      queryClient.clear();
      // reset global httpClient auth
      axios.defaults.headers = {};
    },
  });

  const completeChallenge = React.useCallback(() => {
    // reset auth challenge
    setAuthChallenge(undefined);
    refetchIot();
  }, [refetchIot]);

  /**
   * Test if the user is allowed to perform a certain action
   */
  const isAuthorized = React.useCallback(
    (action: RuleId | undefined, additionalData?: Record<string, unknown>) => {
      return (
        !action ||
        !!roles?.some((role) =>
          checkAuth(rules, role, action, {
            ...additionalData,
          }),
        )
      );
    },
    [roles],
  );

  const value = React.useMemo(
    () => ({
      isSignedIn: !!(
        authToken &&
        iotToken &&
        authChallenge?.name !== authChallenges.resetPassword
      ),
      isAuthorized,
      iotToken,
      signIn,
      signOut,
      // we force the type here as we are most likely to use this prop
      // inside the private app context only and won't be accessible if
      // `isSignedIn` is false
      roles: roles as AuthRole[],
      authChallenge,
      completeChallenge,
    }),
    [iotToken, isAuthorized, roles, signIn, signOut, authChallenge, completeChallenge],
  );

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

export const useAuth = () => React.useContext(authContext) as AuthCtx;
