import axios from 'axios';
import { parseShredderCharacteristics } from 'src/helpers';
import type { Company, Shredder, UserProfile, User } from 'src/types';

export type GetUserProfile = () => Promise<User>;

export type UpdateUserProfile = (profile: UserProfile) => Promise<void>;

export const getUser: GetUserProfile = () =>
  axios
    .get<{
      companyData: Company;
      userData: Omit<UserProfile, 'additionalAttributes'> & {
        additionalAttributes: Record<string, string>;
      };
      shredders: (Omit<Shredder, 'shredderCharacteristics'> & {
        shredderCharacteristics: Record<string, string>;
      })[];
    }>(process.env.REACT_APP_USER_API_URL_V3 + '/userData')
    // rename response data attributes
    .then(({ data }) => {
      const energyPrice = data.userData?.additionalAttributes?.energyPrice
        ? parseFloat(data.userData?.additionalAttributes?.energyPrice.replace(',', '.'))
        : 0.21;
      return {
        shredders: data.shredders.map((s) => ({
          ...s,
          shredderCharacteristics: parseShredderCharacteristics(
            s.shredderCharacteristics,
          ),
        })),
        company: data.companyData,
        profile: {
          ...data.userData,
          additionalAttributes: {
            lastUsedShredder: '',
            unitSystem: 'metricUnits',
            currency: 'eur',
            timeFormat: '24h',
            ...data.userData?.additionalAttributes,
            energyPrice,
          },
        },
      };
    });

export const updateUserProfile: UpdateUserProfile = async (profile) =>
  axios.post(process.env.REACT_APP_USER_API_URL_V3 + '/updateUser', profile);
