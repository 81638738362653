import React from 'react';
import { RouteProps, Redirect } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import { RuleId } from 'src/rbacRules';
import { entryPointByRole } from 'src/routes';
import { RouteWithSuspense } from 'src/components/Route';

type ProtectedRouteProps = Omit<RouteProps, 'component'> & {
  component: React.ComponentType;
  rule?: RuleId;
};

export const ProtectedRoute = ({
  component: Component,
  rule,
  ...rest
}: ProtectedRouteProps): React.ReactElement => {
  const { isSignedIn, isAuthorized, roles } = useAuth();

  return (
    <RouteWithSuspense
      {...rest}
      render={({ location }) => {
        if (!isSignedIn)
          return (
            <Redirect
              push={false}
              to={{ pathname: '/login', state: { from: location.pathname } }}
            />
          );

        // check authorization if the route is protected by any additional rules
        if (rule) {
          return isAuthorized(rule) ? (
            <Component />
          ) : (
            // redirect un-authorized users to role specific entry points
            <Redirect push={false} to={entryPointByRole[roles[0]]} />
          );
        }

        return <Component />;
      }}
    />
  );
};
