import axios from 'axios';
import type {
  Alarm,
  AlarmSeverityLevel,
  Event,
  Period,
  Smartrule,
  SmartruleTemplateName,
  StepByStepInstruction,
  Notification,
} from 'src/types';
import { replaceItemAtIndex } from 'src/helpers';

export type GetAlarms = (payload?: {
  source?: string;
  period?: Partial<Period>;
  /**
   * @default 10
   */
  pageSize?: number;
  /**
   * @default 1
   */
  currentPage?: number;
  type?: string; // comparable to Notification['number']
  severities?: AlarmSeverityLevel[];
  resolved?: boolean;
}) => Promise<Alarm[]>;

export type GetEvents = (payload?: {
  source?: string;
  period?: Partial<Period>;
  /**
   * @default 10
   */
  pageSize?: number;
  /**
   * @default 1
   */
  currentPage?: number;
}) => Promise<Event[]>;

export type NotificationRichText = {
  json: string;
};

export type NotificationDetails = Notification & {
  reason?: NotificationRichText;
  reason2?: NotificationRichText;
  fix?: NotificationRichText;
  fix2?: NotificationRichText;
  stepByStep?: StepByStepInstruction;
  stepByStep2?: StepByStepInstruction;
};

export type GetNotifications = () => Promise<Notification[]>;

export type GetNotification = (number: string) => Promise<NotificationDetails>;

export const getAlarms: GetAlarms = ({ period, ...rest } = {}) =>
  axios
    .post<Alarm[]>(process.env.REACT_APP_IOT_API_URL_V3 + '/alarm/alarms', {
      pageSize: 10,
      currentPage: 1,
      ...period,
      ...rest,
    })
    .then((res) => res.data);

export const getEvents: GetEvents = ({ period, ...rest } = {}) =>
  axios
    .post<Event[]>(process.env.REACT_APP_IOT_API_URL_V3 + '/event/events', {
      pageSize: 10,
      currentPage: 1,
      ...period,
      ...rest,
    })
    .then((res) => res.data);

export const getNotifications: GetNotifications = () =>
  axios
    .get<{ notifications: Notification[] }>( 
      process.env.REACT_APP_CONTENT_API_URL + '/notifications',
    )
    .then((res) => res.data.notifications);

export const getNotification: GetNotification = (number: string) =>
  axios
    .get(process.env.REACT_APP_CONTENT_API_URL + `/notification/${number}`)
    .then((res) => res.data);

export const getSmartrules = async () => {
  try {
    const { data: smartrules } = await axios.get<
      {
        alarmType: string;
        ruleTemplateName: SmartruleTemplateName;
        sources: string[];
        to: string[];
        subject: string;
        text: string;
        cepModuleId: string;
      }[]
    >(process.env.REACT_APP_SMARTRULE_API_URL_V3 + '/smartrules');

    // sms and email rules are originally stored in separate entries in cumulocity, we merge
    // them into a custom entry for the sake of simplicity.
    return smartrules.reduce<Smartrule[]>(
      (res, { alarmType, cepModuleId: id, ruleTemplateName, to, subject, text }) => {
        const target = res.find((entry) => entry.type === alarmType);

        // create new entry
        if (!target) {
          return [
            ...res,
            {
              id,
              type: alarmType,
              subject,
              text,
              emailSrc: ruleTemplateName === 'onAlarmSendEmail' ? id : undefined,
              smsSrc: ruleTemplateName === 'onAlarmSendSms' ? id : undefined,
              emailRecipients: ruleTemplateName === 'onAlarmSendEmail' ? [...to] : [],
              smsRecipients: ruleTemplateName === 'onAlarmSendSms' ? [...to] : [],
            },
          ];
        }

        // merge existing entries
        return replaceItemAtIndex(res, res.indexOf(target), {
          ...target,
          emailSrc: ruleTemplateName === 'onAlarmSendEmail' ? id : target.emailSrc,
          smsSrc: ruleTemplateName === 'onAlarmSendSms' ? id : target.smsSrc,
          emailRecipients:
            ruleTemplateName === 'onAlarmSendEmail'
              ? [...target.emailRecipients, ...to]
              : target.emailRecipients,
          smsRecipients:
            ruleTemplateName === 'onAlarmSendSms'
              ? [...target.smsRecipients, ...to]
              : target.smsRecipients,
        });
      },
      [],
    );
  } catch (e) {
    console.error('Error fetching smartrules', e);
  }

  return [];
};

type CreateSmartRule = (payload: {
  recipients: string[];
  alarmType: string;
  ruleTemplateName: SmartruleTemplateName;
  cumulocityIds: string[];
  subject: string; // todo: move to backend (open XSS issue)
  text: string; // todo: move to backend
}) => Promise<any>;

export const createSmartrule: CreateSmartRule = ({
  recipients,
  alarmType,
  ruleTemplateName,
  cumulocityIds,
  subject,
  text,
}) =>
  axios.post(process.env.REACT_APP_SMARTRULE_API_URL_V3 + '/createSmartrule', {
    alarmType,
    ruleTemplateName,
    sources: cumulocityIds,
    to: recipients.filter(Boolean), // remove empty strings
    subject,
    text,
  });

type DeleteSmartrule = (payload: { id: string }) => Promise<void>;

export const deleteSmartrule: DeleteSmartrule = ({ id }) =>
  axios.delete(
    process.env.REACT_APP_SMARTRULE_API_URL_V3 + '/deleteSmartrule' + `/${id}`,
  );

type UpdateSmartrule = (payload: {
  alarmType: string;
  id: string;
  ruleTemplateName: SmartruleTemplateName;
  cumulocityIds: string[];
  subject: string;
  text: string;
  recipients: string[];
}) => Promise<void>;

export const updateSmartrule: UpdateSmartrule = ({
  id,
  recipients,
  cumulocityIds,
  ...rest
}) =>
  axios.put(process.env.REACT_APP_SMARTRULE_API_URL_V3 + '/updateSmartrule', {
    id,
    cepModuleId: id,
    sources: cumulocityIds,
    to: recipients.filter(Boolean),
    ...rest,
  });
