import axios from 'axios';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { DEFAULT_LOCALE } from 'src/constants';
import { useAuth } from 'src/hooks/useAuth';
import { useUpdateProfile } from 'src/hooks/useUpdateProfile';
import { useUser } from 'src/hooks/useUser';
import { localeState } from 'src/states/i18n';
import { dict } from 'src/translations';

type IntlProps = {
  children?: React.ReactNode;
};

const logMissingIntlError = (err: any) => {
  if (err.code === 'MISSING_TRANSLATION') {
    console.warn('Missing translation', err.message);
    return;
  }
  throw err;
};

export function Intl({ children }: IntlProps): React.ReactElement {
  const { mutate: updateProfile } = useUpdateProfile();
  const { isSignedIn } = useAuth();
  const { data: user } = useUser();
  const locale = useRecoilValue(localeState) || DEFAULT_LOCALE;
  const messages = React.useMemo(() => dict[locale], [locale]);

  axios.defaults.headers['x-untha-language'] = locale;

  // sync local locale state with server state
  React.useEffect(() => {
    if (isSignedIn && user && user.profile.locale !== locale) {
      updateProfile({ ...user.profile, locale });
    }
  }, [locale, user?.profile.locale]);

  return (
    <IntlProvider
      messages={messages}
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      onError={logMissingIntlError}
    >
      {children}
    </IntlProvider>
  );
}
