import React from 'react';

export type PreloadableLazyComponent = React.LazyExoticComponent<any> & {
  preload: () => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createResource = <T extends any>(asyncFn: () => Promise<T>) => {
  let status: 'pending' | 'success' | 'error' = 'pending';
  let result: T;

  const promise = asyncFn()
    .then((res) => {
      result = res;
      status = 'success';
    })
    .catch((err) => {
      result = err;
      status = 'error';
    });

  const read = () => {
    if (status === 'pending') throw promise;
    if (status === 'error') throw result;
    return result;
  };

  return {
    read,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const preloadableLazy = <T extends React.ComponentType<any>>(
  dynamicImport: () => Promise<{ default: T }>,
) => {
  let promise: Promise<{ default: T }>;

  const load = () => {
    if (!promise) {
      promise = dynamicImport();
    }

    return promise;
  };

  const Component = React.lazy(load) as PreloadableLazyComponent;

  Component.preload = load;

  return Component;
};
