import React from 'react';
import { Button, Modal, ModalContent, ModalFooter, Text } from '@untha/ui';
import { FallbackProps } from 'react-error-boundary';
import { atom, useRecoilState, useResetRecoilState } from 'recoil';
import { localStorageEffect } from 'src/states/effects/localStorage';

const retryAtomState = atom({
  key: 'AppErrorFallback/retries',
  default: '0',
  effects_UNSTABLE: [localStorageEffect('AppErrorFallback/retries')],
});

// todo: somehow localize error boundary without user context?!
export function AppErrorFallback(_props: FallbackProps): React.ReactElement {
  const [retries, setRetries] = useRecoilState(retryAtomState);
  const resetRetries = useResetRecoilState(retryAtomState);
  const maxRetries = 2;

  // todo: add more sophisticated error handling
  const handleClickRetry = () => {
    // rehydrate from localStorage
    const parsedRetries = parseInt(retries || '0');

    // full reset
    if (parsedRetries >= maxRetries) {
      resetRetries();
      localStorage.clear();
      location.reload();
    } else {
      setRetries(String(parsedRetries + 1));
      history.back();
      location.reload();
    }
  };

  return (
    <Modal isOpen={true} title="Error" onRequestClose={handleClickRetry}>
      <ModalContent>
        <Text variant="body">Oops, that looks wrong!</Text>
      </ModalContent>

      <ModalFooter>
        <Button as="button" type="button" onClick={handleClickRetry} variant="primary">
          Try Again
        </Button>
      </ModalFooter>
    </Modal>
  );
}
