import React from 'react';
import clsx from 'clsx';
import { Route, RouteProps } from 'react-router-dom';
import { Spinner } from 'src/components/Spinner';

export function RouteFallback({
  className,
  ...rest
}: React.ComponentPropsWithoutRef<'div'>): React.ReactElement {
  return (
    <div
      className={clsx(
        'fixed',
        'flex',
        'items-center',
        'justify-center',
        'w-full',
        'h-full',
        'top-0',
        'left-0',
        'text-gray', // apply color here in order to allow overrides via css prop
        className,
      )}
      {...rest}
    >
      <Spinner size="large" isLoading />
    </div>
  );
}

export function RouteWithSuspense({
  className,
  ...props
}: RouteProps & { className?: string }): React.ReactElement {
  return (
    <React.Suspense fallback={<RouteFallback className={className} />}>
      <Route {...props} />
    </React.Suspense>
  );
}
