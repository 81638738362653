import { Auth } from '@aws-amplify/auth';
import type { CognitoUser } from '@aws-amplify/auth';
import type { UserCredentials } from 'src/types';
import axios from 'axios';
import { AuthRole } from 'src/rbacRules';
import { amplifyAuthError } from 'src/constants';

export type SignUp = (userData: {
  email: string;
  password: string;
  companyId: string;
}) => Promise<void>;

export type SignIn = (credentials: UserCredentials) => Promise<CognitoUser>;

export type SignOut = () => Promise<void>;

export type GetIotToken = () => Promise<string>;

export type GetAuthToken = () => Promise<string | null>;

export type GetAccessToken = () => Promise<string | null>;

export type GetRoles = () => Promise<AuthRole[]>;

export type GetAuthId = () => Promise<string | null>;

export type ResetPassword = (payload: {
  email: string;
  companyId: string;
}) => Promise<void>;

export type ChangePassword = (payload: {
  newPassword: string;
  oldPassword: string;
}) => Promise<void>;

export type ConfirmSignUp = (token: string) => Promise<boolean>;

export const signIn: SignIn = (credentials) => Auth.signIn(credentials);

export const signOut: SignOut = () => Auth.signOut();

export const signUp: SignUp = async (userData) => {
  return axios.post(process.env.REACT_APP_USER_AUTH_API_URL_V3 + '/register', userData);
};

/**
 * Reset password and send reset mail with temporary password.
 */
export const resetPassword: ResetPassword = ({ email, companyId }) =>
  axios.post(process.env.REACT_APP_USER_AUTH_API_URL_V3 + '/resetPassword', {
    userId: email,
    companyId,
  });

/**
 * Change existing password
 */
export const changePassword: ChangePassword = async ({ oldPassword, newPassword }) =>
  axios.post(process.env.REACT_APP_USER_AUTH_API_URL_V3 + '/changePassword', {
    password: newPassword,
    oldPassword,
    accessToken: await getAccessToken(),
  });

/**
 * Fetches the cumulocity JWT
 */
export const getIotToken: GetIotToken = () =>
  axios
    .get<{ jwt: string }>(process.env.REACT_APP_IOT_API_URL_V3 + '/token/token')
    .then((res) => res.data.jwt);

/**
 * Fetches the cognito auth token for the current authenticated user
 * necessary for general API access.
 */
export const getAuthToken: GetAuthToken = () =>
  Auth.currentSession()
    .then((session) => session.getIdToken())
    .then((idToken) => idToken.getJwtToken())
    .catch(() => null);

/**
 * Fetches the cognito access token for the current authenticated user
 * necessary for password changes.
 */
export const getAccessToken: GetAccessToken = () =>
  Auth.currentSession()
    .then((session) => session.getAccessToken())
    .then((accessToken) => accessToken.getJwtToken())
    .catch(() => null);

/**
 * Get the role for the current authenticated user
 */
export const getRoles: GetRoles = () =>
  Auth.currentUserInfo().then((userInfo) => {
    const { attributes } = userInfo || {};

    if (!attributes) {
      throw Error(amplifyAuthError);
    }

    let roles: AuthRole[] = [AuthRole.basic];

    // userInfo attributes are stringified,
    // so we need to parse them first
    const safeParse = (val: any) => typeof val === 'string' && JSON.parse(val);

    if (safeParse(attributes['custom:isCompanyAdmin']) === true) {
      roles = [AuthRole.companyAdmin];
    }

    if (safeParse(attributes['custom:isUnthaAdmin']) === true) {
      roles = [AuthRole.unthaAdmin];
    }

    return roles;
  });

export const getAuthId: GetAuthId = () =>
  Auth.currentUserInfo().then((info) => info.username);

export const confirmSignUp: ConfirmSignUp = (token) =>
  axios
    .post<{ message: 'success' | 'error' }>(
      process.env.REACT_APP_USER_AUTH_API_URL_V3 + '/confirm',
      {
        confirmationToken: token,
      },
    )
    .then(({ status }) => status === 204)
    .catch(() => false);
