// prettier-ignore
const messages = {
  // project meta
  'project.title': 'MyUNTHA',
  'project.brand': 'UNTHA',
  'project.brandPhrase': 'shredding technology',
  'project.brandSub': 'Il tuo portale cliente personale',

  "locale.en": "Inglese",
  "locale.de": "Tedesco",
  "locale.es": "Spagnolo",
  "locale.fr": "Francese",
  "locale.it": "Italiano",

  // pages
  'page.dashboard.breadcrumb': 'Dashboard',
  'page.admin.breadcrumb': 'Amministrazione',
  'page.admin.section.management': 'Gestione',
  "page.admin.section.data": "Banca dati",
  "page.admin.companyTable.headline": "Clienti",
  "page.admin.csvImport.headline": "Importazione",
  "page.admin.csvExport.headline": "Esportazione",
  'page.notifications.title': 'Messaggi',
  'page.notifications.breadcrumb': 'Messaggi',
  'page.notifications.section.notifications': 'Messaggi attuali',
  'page.notifications.section.notifications.alarms': 'Allarmi',
  'page.notifications.section.notifications.events': 'Eventi',
  'page.notifications.section.settings': 'Impostazioni',
  'page.notifications.section.settings.notifications': 'Avvisi',
  'page.login.headline': 'Connettiti',
  'page.login.body': 'Nessun account?',
  'page.login.forgotPassword': 'Password dimenticata?',
  'page.register.headline': 'Registrati',
  'page.register.body': 'Sei un cliente UNTHA ma non hai ancora accesso al portale MyUNTHA? Registrati qui.',
  'page.register.back': 'Già registrato?',
  'page.register.toast.error': 'Nella registrazione si è verificato un errore. Riprova in un momento successivo o rivolgiti al supporto UNTHA.',
  'page.register.toast.success': 'Registrazione riuscita',
  'page.forgotPassword.headline': 'Reset password',
  'page.forgotPassword.body': 'Digita il tuo indirizzo e-mail e il numero cliente, ti invieremo una password temporanea. Al primo login potrai poi immettere una nuova password.',
  'page.forgotPassword.back': 'Indietro al login',
  'page.newPassword.headline': 'Aggiorna password',
  'page.newPassword.body': 'Selezionare una nuova password per i login successivi.',
  'page.profile.breadcrumb': 'MyPROFIL',
  'page.profile.section.user': 'Dati personali',
  'page.profile.section.user.profile': 'I tuoi dati',
  'page.profile.section.user.company': 'La tua azienda',
  'page.profile.section.settings': 'Impostazioni',
  'page.profile.section.settings.ui': 'Interfaccia utente',
  'page.profile.section.settings.password': 'Password',
  'page.profile.section.settings.energyCosts': 'Costi di energia',
  'page.profile.section.admin': 'Amministrazione',
  'page.profile.section.admin.subUser': 'Account utente',
  'page.profile.section.admin.subUser.info': 'In questo campo puoi gestire utenti esistenti o creare nuovi utenti. Per creare un utente aggiuntivo devono essere immessi l\'indirizzo e-mail, il nome e il cognome. Dopo la creazione dell\'account viene inviata una e-mail, mediante la quale l\'utente può registrarsi ed effettuare il login.',
  'page.report.breadcrumb': 'Report',
  'page.report.title': 'Report prestazioni',
  'page.report.history': 'Ultimi report prestazioni',
  'page.report.scheduled': 'Report prestazioni automatici',
  'page.explorer.presets.title': 'Preferiti',
  'page.explorer.manage.breadcrumb': 'Punti dati',
  'page.explorer.manage.search.placeholder': 'Ricerca punto dati...',
  'page.explorer.manage.search.empty': 'Nessun risultato trovato.',
  'page.explorer.manage.limit': 'Il numero massimo di punti dati è stato raggiunto.',
  'page.explorer.intro.section.favorites': 'Preferiti',
  'page.explorer.presets.action.load': 'Carica preferito',
  'page.explorer.presets.placeholder': 'Nessun preferito disponibile',
  'page.shredder.section.overview': 'Panoramica',
  'page.shredder.section.records': 'Record di dati',
  'page.shredder.downloads': 'Scaricamenti',
  'page.registrationConfirmation.section.success.headline': 'Registrazione terminata',
  'page.registrationConfirmation.section.success.body': 'La procedura di registrazione è terminata. Adesso puoi collegarti.',
  'page.registrationConfirmation.section.error.headline': 'Registrazione non riuscita',
  'page.registrationConfirmation.section.error.body': 'La procedura di registrazione non è riuscita. Contattare l’amministratore aziendale.',
  'page.shredders.empty': 'Nessun trituratore ancora disponibile.',
  'page.shredders.section.shredder': `{count, plural,
    one {Il tuo trituratore}
    other {Il tuo trituratore}
  }`,

  // CreatePreset
  'createPreset.title.create': 'Salva configurazione attuale come preferito',
  'createPreset.title.update': 'Aggiorna preferito corrent',

  // ExplorerZoom
  'explorerZoom.action.reset': 'Resetta zoom',

  // Table
  'table.th.email': 'E-Mail',
  'table.th.company': 'Nome azienda',
  'table.th.number': 'Numero',
  'table.th.alarm': 'Allarme',
  'table.th.priority': 'Priorità',
  'table.th.sms': 'SMS',
  'table.th.firstName': 'Nome',
  'table.th.lastName': 'Cognome',
  'table.th.period': 'Periodo',
  'table.th.reportType': 'Tipo',
  'table.th.file': 'File',
  'table.th.shredder': 'Trituratore',
  'table.th.id': 'ID',
  'table.th.status': 'Stato',

  // CompanyTable
  'companyTable.action.pirate': 'Vista cliente',
  'companyTable.toast.impersonate.active.success': 'Vista cliente attivata',
  'companyTable.toast.impersonate.disabled.success': 'Vista cliente disattivata',
  'companyTable.toast.impersonate.active.error': 'Errore all’attivazione della vista cliente',
  'companyTable.deleteDialog.title': 'Disattiva cliente',
  'companyTable.deleteDialog.headline': 'Cautela',
  'companyTable.deleteDialog.body': 'Disattivando un cliente, gli utenti correlati a questo cliente non possono più collegarsi al portale clienti.',
  'companyTable.deleteDialog.confirmation': 'Vuoi davvero proseguire?',
  'companyTable.deleteDialog.notify': "Comunicare l'avvenuta disattivazione a tutti gli utenti del cliente via e-mail",
  'companyTable.search.input.placeholder': 'Trova cliente...',

  // common actions
  'action.save': 'Salva',
  'action.disable': 'Disattiva',
  'action.enable': 'Attiva',
  'action.login': 'Connettiti',
  'action.logout': 'Disconnettiti',
  'action.register': 'Registrati',
  'action.edit': 'Modifica',
  'action.delete': 'Cancella',
  'action.cancel': 'Annulla',
  'action.create': 'Crea',
  'action.saveChanges': 'Salva modifiche',
  'action.revert': 'Ripristina',
  'action.download': 'Download',
  'action.upload': 'Carica',
  'action.update': 'Aggiorna',
  'action.close': 'Chiudi',
  'action.reset': 'Reset',
  'action.add': 'Aggiungi',

  // common states
  'state.enabled': 'in esercizio',
  'state.disabled': 'fuori esercizio',

  // shredder attributes
  'shredderAttr.ENERGY_SUPPLY.label': 'Consumo di energia',
  'shredderAttr.HOR_POWER_ACTIVE.label': 'Potenza attiva nastro orizzontale',
  'shredderAttr.ENERGY_SUPPLY_COSTS.label': 'Costi di energia',
  'shredderAttr.CHILLER_POWER_APPARENT.label': 'Potenza apparente raffreddamento acqua',
  'shredderAttr.RISE_POWER_ACTIVE.label': 'Potenza attiva nastro verticale',
  'shredderAttr.RISE_ENERGY.label': 'Consumo di energia nastro verticale',
  'shredderAttr.OIL_LIFETIME_HYDR.label': 'Vita utile residua olio idraulico',
  'shredderAttr.MAGN_ENERGY_COSTS.label': 'Costi di energia separatore magnetico',
  'shredderAttr.HEATPLATE_TEMP2.label': 'Temperatura corpo di raffreddamento 2',
  'shredderAttr.HEATPLATE_TEMP1.label': 'Temperatura corpo di raffreddamento 1',
  'shredderAttr.MAGN_POWER_ACTIVE.label': 'Potenza attiva separatore magnetico',
  'shredderAttr.OIL_CONDUCTIVITY_GEAR2.label': 'Conduttività olio per ingranaggi 2',
  'shredderAttr.OIL_CONDUCTIVITY_GEAR1.label': 'Conduttività olio per ingranaggi 1',
  'shredderAttr.RISE_ENERGY_COSTS.label': 'Costi di energia nastro verticale',
  'shredderAttr.OIL_PERMITIVITY_GEAR1.label': 'Permittività olio per ingranaggi 1',
  'shredderAttr.OIL_PERMITIVITY_GEAR2.label': 'Permittività olio per ingranaggi 2',
  'shredderAttr.OIL_CONDUCTIVITY_HYDR.label': 'Conduttività olio idraulico',
  'shredderAttr.POWER_APPARENT_ACT2.label': 'Potenza apparente motore 2',
  'shredderAttr.ROTOR_REVOLUTION.label': 'Numero di giri rotore',
  'shredderAttr.ROTOR_REVOLUTION1.label': 'Numero di giri rotore 1',
  'shredderAttr.ROTOR_REVOLUTION2.label': 'Numero di giri rotore 2',
  'shredderAttr.OIL_TEMPERATURE_HYDR.label': 'Temperatura olio idraulico',
  'shredderAttr.POWER_APPARENT_ACT1.label': 'Potenza apparente motore 1',
  'shredderAttr.COOLING_TEMPIN.label': 'Temperatura di ritorno raffreddamento acqua',
  'shredderAttr.TORQUE_ROT.label': 'Coppia rotore',
  'shredderAttr.TORQUE_ROT1.label': 'Coppia rotore 1',
  'shredderAttr.TORQUE_ROT2.label': 'Coppia rotore 2',
  'shredderAttr.MOTORTEMP_2_SENS.label': 'Temperatura motore 2',
  'shredderAttr.OIL_PERMITIVITY_HYDR.label': 'Permittività olio per ingranaggi',
  'shredderAttr.CHILLER_PB4.label': 'Temperatura ambiente',
  'shredderAttr.CHILLER_POWER_ACTIVE.label': 'Potenza attiva raffreddamento acqua',
  'shredderAttr.CHILLER_PB3.label': 'Pressione condensatore',
  'shredderAttr.CHILLER_PB2.label': 'Temperatura di mandata raffreddamento',
  'shredderAttr.UEFF_L23.label': 'Tensione conduttori U23',
  'shredderAttr.CHILLER_PB1.label': 'Temperatura evaporatore',
  'shredderAttr.OIL_TEMPERATURE_GEAR1.label': 'Temperatura olio per ingranaggi 1',
  'shredderAttr.OIL_TEMPERATURE_GEAR2.label': 'Temperatura olio per ingranaggi 2',
  'shredderAttr.CHILLER_ENERGY_COSTS.label': 'Costi di energia raffreddamento acqua',
  'shredderAttr.HOR_ENERGY_COSTS.label': 'Costi di energia nastro orizzontale',
  'shredderAttr.REVOLUTION_ACT2.label': 'Numero di giri motore 2',
  'shredderAttr.HYDR_POWER_APPARENT.label': 'Potenza apparente impianto idraulico',
  'shredderAttr.REVOLUTION_ACT1.label': 'Numero di giri motore 1',
  'shredderAttr.TORQUE1.label': 'Coppia 1',
  'shredderAttr.DEVICE_TEMP2.label': 'Temperatura inverter 2',
  'shredderAttr.DEVICE_TEMP1.label': 'Temperatura inverter 1',
  'shredderAttr.TORQUE2.label': 'Coppia 2',
  'shredderAttr.MAGN_ENERGY.label': 'Consumo di energia separatore magnetico',
  'shredderAttr.OIL_LEVEL_HYDR.label': 'Livello dell\'olio idraulico',
  'shredderAttr.UEFF_L31.label': 'Tensione conduttori U31',
  'shredderAttr.POWER_ACTIVE.label': 'Potenza attiva',
  'shredderAttr.OIL_LEVEL_GEAR1.label': 'Livello dell\'olio per ingranaggi 1',
  'shredderAttr.OIL_LEVEL_GEAR2.label': 'Livello dell\'olio per ingranaggi 2',
  'shredderAttr.VOLTAGE_OUT2.label': 'Tensione di uscita 2',
  'shredderAttr.VOLTAGE_OUT1.label': 'Tensione di uscita 1',
  'shredderAttr.POWER_ACTIVE_ACT2.label': 'Potenza attiva motore 2',
  'shredderAttr.POWER_ACTIVE_ACT1.label': 'Potenza attiva motore 1',
  'shredderAttr.OIL_AGE_GEAR1.label': 'Età olio per ingranaggi 1',
  'shredderAttr.OIL_AGE_GEAR2.label': 'Età olio per ingranaggi 2',
  'shredderAttr.HOR_ENERGY.label': 'Consumo di energia nastro orizzontale',
  'shredderAttr.CNT_REVERSING.label': 'Contatore inversioni',
  'shredderAttr.OIL_LIFETIME_GEAR1.label': 'Vita utile residua olio per ingranaggi 1',
  'shredderAttr.OIL_LIFETIME_GEAR2.label': 'Vita utile residua olio per ingranaggi 2',
  'shredderAttr.FUNNEL_LEVEL.label': 'Livello di riempimento tramoggia',
  'shredderAttr.HYDR_ENERGY.label': 'Consumo di energia impianto idraulico',
  'shredderAttr.HYDR_ENERGY_COSTS.label': 'Costi di energia impianto idraulico',
  'shredderAttr.COOLING_TEMPOUT.label': 'Temperatura di mandata raffreddamento acqua',
  'shredderAttr.OIL_HUMIDITY_HYDR.label': 'Umidità relativa olio idraulico',
  'shredderAttr.UEFF_L12.label': 'Tensione conduttori U12',
  'shredderAttr.MAGN_POWER_APPARENT.label': 'Potenza apparente separatore magnetico',
  'shredderAttr.CHILLER_ENERGY.label': 'Consumo di energia raffreddamento acqua',
  'shredderAttr.POWER_APPARENT.label': 'Potenza apparente',
  'shredderAttr.HYDR_POWER_ACTIVE.label': 'Potenza attiva impianto idraulico',
  'shredderAttr.CNT_SERVICEFLAP.label': 'Contatore sportello per la manutenzione aperto',
  'shredderAttr.OPERATING_HOURS.label': 'Ore di esercizio',
  'shredderAttr.MOTORTEMP_1_SENS.label': 'Temperatura motore 1',
  'shredderAttr.PUSHER_FORCE.label': 'Forza di spinta saracinesca',
  'shredderAttr.ROTOR_MECH_POWER.label': 'Potenza meccanica rotore',
  'shredderAttr.HOR_POWER_APPARENT.label': 'Potenza apparente nastro orizzontale',
  'shredderAttr.CNT_CLUTCHSLIP1.label': 'Contatore slittamenti frizione 1',
  'shredderAttr.CNT_CLUTCHSLIP2.label': 'Contatore slittamenti frizione 2',
  'shredderAttr.RISE_POWER_APPARENT.label': 'Potenza apparente nastro verticale',
  'shredderAttr.OIL_AGE_HYDR.label': 'Età olio idraulico',
  'shredderAttr.DC_CURRENT2.label': 'Tensione circuito intermedio 2',
  'shredderAttr.DC_CURRENT1.label': 'Tensione circuito intermedio 1',
  'shredderAttr.HYDCOOL_ENERGY.label': 'Consumo di energia raffreddamento impianto idraulico',
  'shredderAttr.HYDCOOL_ENERGY_COSTS.label': 'Costi di energia raffreddamento impianto idraulico',
  'shredderAttr.HYDCOOL_ENERGY_ACTIVE.label': 'Potenza attiva raffreddamento impianto idraulico',
  'shredderAttr.HYDCOOL_ENERGY_ACTIVE_COSTS.label': 'Consumo di energia raffreddamento impianto idraulico',
  'shredderAttr.HYDCOOL_POWER_APPARENT.label': 'Potenza apparente raffreddamento impianto idraulico',
  'shredderAttr.VOLTAGE_U12.label': 'Tensione conduttori U12',
  'shredderAttr.VOLTAGE_U23.label': 'Tensione conduttori U23',
  'shredderAttr.VOLTAGE_U31.label': 'Tensione conduttori U31',
  'shredderAttr.HORCONVEYOR_POWER_ACTIVE.label': 'Potenza attiva nastro orizzontale',
  'shredderAttr.RAISECONVEYOR_POWER_ACTIVE.label': 'Potenza attiva nastro verticale',
  'shredderAttr.RAISECONVEYOR_ENERGY.label': 'Consumo di energia nastro verticale',
  'shredderAttr.MAGCONVEYOR_ENERGY_COSTS.label': 'Costi di energia separatore magnetico',
  'shredderAttr.MAGCONVEYOR_POWER_ACTIVE.label': 'Potenza attiva separatore magnetico',
  'shredderAttr.GEAR_REVOLUTION1.label': 'GEAR_REVOLUTION1',
  'shredderAttr.GEAR_REVOLUTION2.label': 'GEAR_REVOLUTION2',
  'shredderAttr.RAISECONVEYOR_ENERGY_COSTS.label': 'Costi di energia nastro verticale',
  'shredderAttr.HORCONVEYOR_ENERGY_COSTS.label': 'Costi di energia nastro orizzontale',
  'shredderAttr.MAGCONVEYOR_ENERGY.label': 'Consumo di energia separatore magnetico',
  'shredderAttr.GRID_THD.label': 'Qualità della rete THD',
  'shredderAttr.HORCONVEYOR_ENERGY.label': 'Consumo di energia nastro orizzontale',
  'shredderAttr.MAGCONVEYOR_POWER_APPARENT.label': 'Potenza apparente separatore magnetico',
  'shredderAttr.ENGINE_MECH_POWER2.label': 'Potenza meccanica motore 2',
  'shredderAttr.ENGINE_MECH_POWER1.label': 'Potenza meccanica motore 1',
  'shredderAttr.HORCONVEYOR_POWER_APPARENT.label': 'Potenza apparente nastro orizzontale',
  'shredderAttr.OIL_HUMIDITY_GEAR2.label': 'Umidità relativa olio per ingranaggi 2',
  'shredderAttr.OIL_HUMIDITY_GEAR1.label': 'Umidità relativa olio per ingranaggi 1',
  'shredderAttr.RAISECONVEYOR_POWER_APPARENT.label': 'Potenza apparente nastro verticale',
  'shredderAttr.ACC_EFF_BEARING.label': 'Valore effettivo vibrazione radiale',
  'shredderAttr.ACTIVE_CURRENT1.label': 'Amperaggio 1',
  'shredderAttr.ACTIVE_CURRENT2.label': 'Amperaggio 2',
  'shredderAttr.GEARTEMP_1_SENS.label': 'Temperatura ingranaggi 1',
  'shredderAttr.GEARTEMP_2_SENS.label': 'Temperatura ingranaggi 2',
  'shredderAttr.GENERATOR_FUELLEVEL.label': 'Livello di riempimento del gruppo elettrogno',
  'shredderAttr.GENERATOR_POWER.label': 'Potenza attiva del gruppo elettrogno',
  'shredderAttr.GENERATOR_VOLTAGE.label': 'Tensione del gruppo elettrogno',
  'shredderAttr.GENERATOR_FREQUENCY.label': 'Frequenza del gruppo elettrogno',

  // shredder attribute clusters
  'shredderAttrCluster.gearOil1': 'Olio per ingranaggi 1',
  'shredderAttrCluster.gearOil2': 'Olio per ingranaggi 2',
  'shredderAttrCluster.counter': 'Contatore',
  'shredderAttrCluster.hydrOil': 'Olio idraulico',
  'shredderAttrCluster.engineTotalEnergyConsumption': 'Consumo energetico',
  'shredderAttrCluster.engineTotalPowerActive': 'Potenza totale macchina',
  'shredderAttrCluster.cntGearSkidding': 'Contatore inversioni',
  'shredderAttrCluster.cntServiceFlap': 'Contatore sportello per la manutenzione aperto',
  'shredderAttrCluster.rotorSpeed': 'Numero di giri rotore',
  'shredderAttrCluster.rotorTorque': 'Coppia rotore',
  'shredderAttrCluster.rotorMechPower': 'Potenza meccanica rotore',
  'shredderAttrCluster.conductorVoltages': 'Tensione conduttori',
  'shredderAttrCluster.netQuality': 'Qualità della rete THD',
  'shredderAttrCluster.engineTorque': 'Coppia motori',
  'shredderAttrCluster.engineSpeed': 'Coppia motori',
  'shredderAttrCluster.engineTemp': 'Temperatura motore',
  'shredderAttrCluster.enginePowerActive': 'Potenza attiva motori',
  'shredderAttrCluster.cntClutchSkidding': 'Contatore slittamenti frizione',
  'shredderAttrCluster.gearSpeed': 'Numero di giri degli ingranaggi',
  'shredderAttrCluster.outputVoltage': 'Tensione di uscita',
  'shredderAttrCluster.inverterTemp': 'Temperatura inverter',
  'shredderAttrCluster.heatSinkTemp': 'Temperatura corpo di raffreddamento',
  'shredderAttrCluster.intCircuitVoltage': 'Tensione circuito intermedio',
  'shredderAttrCluster.conveyorsEnergyConsumption': 'Consumo energetico tecnica di trasporto',
  'shredderAttrCluster.additionalEnergyConsumption': 'Consumo energetico gruppi derivati',
  'shredderAttrCluster.conveyorsPowerActive': 'Potenza tecnica di trasporto',
  'shredderAttrCluster.additionalPowerActive': 'Potenza gruppi derivati',
  'shredderAttrCluster.waterCoolingTemp': 'Temperature raffreddamento ad acqua',
  'shredderAttrCluster.oilLevel': 'Livelli dell\'olio',
  'shredderAttrCluster.oilTemp': 'Temperature dell\'olio',
  'shredderAttrCluster.radialVibration': 'Valore effettivo vibrazione radiale',
  'shredderAttrCluster.funnelLevel': 'Livello di riempimento tramoggia',
  'shredderAttrCluster.pusherForce': 'Forza cilindro saracinesca',
  'shredderAttrCluster.activePower': 'Corrente attiva',
  'shredderAttrCluster.gearTemp': 'Temperature ingranaggi',
  'shredderAttrCluster.generatorFuellevel':'Livello di riempimento del gruppo elettrogno',
  'shredderAttrCluster.generatorEffectivePower':'Potenza attiva del gruppo elettrogno',
  'shredderAttrCluster.generatorVoltage':'Tensione del gruppo elettrogno',
  'shredderAttrCluster.generatorFrequency':'Frequenza del gruppo elettrogno',

  // shredder attribute topics
  'shredderAttrTopic.hydraulic': 'Impianto idraulico',
  'shredderAttrTopic.driveTrain': 'Catena cinematica',
  'shredderAttrTopic.powerTrain': 'Catena cinematica',
  'shredderAttrTopic.waterCooling': 'Raffreddamento acqua',
  'shredderAttrTopic.extras': 'Accessori',
  'shredderAttrTopic.operatingData': 'Dati di esercizio',
  'shredderAttrTopic.pusher': 'Saracinesca',
  'shredderAttrTopic.rotor': 'Rotore',
  'shredderAttrTopic.clutch': 'Giunto',
  'shredderAttrTopic.coolingCircuit': 'Circuito di raffreddamento',
  'shredderAttrTopic.horizontalConveyor': 'Nastro orizzontale',
  'shredderAttrTopic.elevatingConveyor': 'Nastro verticale',
  'shredderAttrTopic.magneticSeparator': 'Separatore magnetico',
  'shredderAttrTopic.rotorShaft': 'Albero rotore',
  'shredderAttrTopic.slideCylinder': 'Cilindro saracinesca',
  'shredderAttrTopic.oilPumpDrive': 'Azionamento pompa dell\'olio',
  'shredderAttrTopic.oilPan': 'Vasca dell\'olio',
  'shredderAttrTopic.pusherForce': 'Forza cilindro saracinesca',
  'shredderAttrTopic.chiller': 'Raffreddamento acqua',
  'shredderAttrTopic.engineTotal': 'Macchina intera',
  'shredderAttrTopic.cuttingSystem': 'Sistema di taglio',
  'shredderAttrTopic.energySupply': 'Alimentazione energetica',
  'shredderAttrTopic.engine': 'Motore',
  'shredderAttrTopic.gear': 'Ingranaggi',
  'shredderAttrTopic.frequencyConverter': 'Convertitore di frequenza',
  'shredderAttrTopic.transportSystem': 'Tecnica di trasporto',
  'shredderAttrTopic.additional': 'Gruppi derivati',
  'shredderAttrTopic.operatingSupplies': 'Materiali di esercizio',
  'shredderAttrTopic.other': 'Altro',
  'shredderAttrTopic.powerUnit': 'Gruppo elettrogno',

  // notification severity
  'notificationSeverity.0': 'Avvertenza',
  'notificationSeverity.1': 'Meno importante',
  'notificationSeverity.2': 'Importante',
  'notificationSeverity.3': 'Critico',

  // notification acknowledged alarms
  'notificationSeverity.acknowledgedAlarms': 'Allarmi confermati',

  // NotificationSettings
  'notificationSettings.headline': 'Avvisi',

  // common form errors
  'formValidation.error.email.email': 'Immettere un indirizzo e-mail valido.',
  'formValidation.error.sms.sms': 'Immettere un numero di telefono valido. Il numero di telefono inizia con \'+\' o \'00\' e non può contenere caratteri speciali tranne \'+\'.',
  'formValidation.error.password.matches': 'La password deve essere lunga almeno 8 caratteri e contenere una lettera maiuscola e un carattere speciale.',
  'formValidation.error.password.required': 'Il campo password non può essere vuoto.',
  'formValidation.error.companyId.required': 'Il campo numero cliente non può essere vuoto.',
  'formValidation.error.terms.required': 'Accetta le disposizioni sulla protezione dei dati.',
  'formValidation.error.email.required': 'Il campo indirizzo e-mail non può essere vuoto.',
  'formValidation.error.energyCosts.required': 'Il campo costo energetico non può essere vuoto.',

  // common form fields
  'form.field.email': 'E-Mail',
  'form.field.firstName': 'Nome',
  'form.field.title': 'Titolo',
  'form.field.lastName': 'Cognome',
  'form.field.password': 'Password',
  'form.field.oldPassword': 'Vecchia password',
  'form.field.newPassword': 'Nuova password',
  'form.field.companyId': 'Numero cliente',
  'form.field.salutation': 'Appellativo',
  'form.field.mobileNumber': 'Numero di telefono cellulare',
  'form.field.phoneNumber': 'Numero di telefono',
  'form.field.street': 'Via',
  'form.field.addressPostalCode': 'CAP',
  'form.field.addressCity': 'Città',
  'form.field.country': 'Paese',
  'form.field.language': 'Lingua',
  'form.field.homepage': 'Website',
  'form.field.faxNumber': 'Fax',
  'form.field.shredderId': 'Numero di serie',
  'form.field.companyName': 'Nome azienda',
  'form.field.warranty': 'Garanzia',
  'form.field.unitSystem': 'Sistema unità di misura',
  'form.field.terms': 'Io (cliente o referente del cliente) dichiaro di accettare il<a href="https://www.untha.com/de/datenschutz#aws" target="_blank"> trattamento dei miei dati</a> e le disposizioni vigenti <a href="https://www.untha.com/de/datenschutz" target="_blank">sulla protezione dei dati</a> in relazione all\'utilizzo di MyUNTHA.',
  'form.field.timeFormat': 'Formato temporale',

  // PasswordField
  'passwordField.hint.body': 'La password deve essere lunga almeno 8 caratteri e contenere una lettera maiuscola e un carattere speciale.',
  'passwordField.action.password.show': 'Visualizza password',
  'passwordField.action.password.hide': 'Nascondi password',

  // SignUp
  'signUp.form.field.companyId.hint.body': 'Non trovi il numero cliente? Rivolgiti al n. tel. +43 6244 7016 0 o all\'indirizzo e-mail info@untha.com – Ti aiutiamo volentieri per la registrazione.',

  'notificationCard.action.show': 'Visualizza messaggi',
  'notificationCard.title': '{count, plural,\n    =0 {Nessun messaggio}\n    one {1 messaggio}\n    other {{count} messaggi}\n  }',
  'notificationCard.body': 'per questo trituratore',

  // PasswordSettings
  'passwordSettings.action.update': 'Aggiorna password',
  'passwordSettings.update.success': 'Password aggiornata',
  'passwordSettings.update.error': 'Password errata',

  // Service Notifications
  'service.admin.createSubUser.success': 'L\'utente è stato creato con successo.',
  'service.admin.createSubUser.error.duplicate': 'Questo utente esiste già!',
  'service.admin.deleteSubUser.success': 'L\'utente è stato eliminato con successo.',
  'service.admin.deleteSubUser.error.permission': 'Gli utenti Admin non possono essere eliminati!',
  'service.admin.createSubUser.error': "Errore nella creazione dell'utente!",
  'service.admin.deleteSubUser.error': 'Errore nella cancellazione dell’utente!',
  'service.profile.update.success': 'Profilo aggiornato',
  'service.auth.resetPassword.success': 'Password resettata con successo.',
  'service.auth.resetPassword.error': 'Reset della password non riuscito!',
  'service.auth.newPassword.success': 'Password aggiornata con successo.',
  'service.auth.newPassword.error': 'Aggiornamento della password non riuscito!',
  'service.notification.general.error': "Nell'elaborazione degli avvisi si è verificato un errore.",

  // AdditionalEnergyMeasurement
  'additionalEnergyMeasurement.entries.HOR_ENERGY.label': 'Nastro orizzontale',
  'additionalEnergyMeasurement.entries.HYDR_ENERGY.label': 'Impianto idraulico',
  'additionalEnergyMeasurement.entries.CHILLER_ENERGY.label': 'Raffreddamento acqua',
  'additionalEnergyMeasurement.entries.RISE_ENERGY.label': 'Nastro verticale',
  'additionalEnergyMeasurement.entries.MAGN_ENERGY.label': 'Separatore magnetic',
  'additionalEnergyMeasurement.entries.HYDCOOL_ENERGY_ACTIVE.label': 'Raffreddamento idraulico',
  'additionalEnergyMeasurement.title.front': 'Consumo energetico gruppi supplementari',
  'additionalEnergyMeasurement.title.back': 'Costo energetico gruppi supplementari',
  'additionalEnergyMeasurement.entries.HORCONVEYOR_ENERGY.label': 'Nastro orizzontale',
  'additionalEnergyMeasurement.entries.RAISECONVEYOR_ENERGY.label': 'Nastro verticale',
  'additionalEnergyMeasurement.entries.MAGCONVEYOR_ENERGY.label': 'Separatore magnetico',

  // Animated Brand
  'animatedBrand.cta.label': 'al sito web',

  // Tag
  'tag.customerView.text': 'Vista cliente',
  'tag.new.text': 'ora nuovo',
  'tag.newAbbr.text': 'nuovo',

  // ShredderHero
  'shredderHero.cta.live.label': 'Visualizza dati live',
  'shredderHero.cta.manual.label': 'Istruzioni per l\'uso',

  // 404 Page
  '404.card.headline': '404 - NOT FOUND OR SHREDDED',
  '404.card.text': 'Purtroppo la pagina desiderata è stata triturata da un nostro shredder e non può pertanto essere trovata.',

  // User Details
  'userDetails.action.update': 'Aggiorna dati',
  'userDetails.fallback.text': 'Carico profilo...',

  // Contact Callout
  'contactCallout.headline': 'Hai domande?',
  'contactCallout.text': 'Sono volentieri a tua disposizione.',

  // Notification Details
  'notificationDetails.title': 'Dettagli allarme',
  'notificationDetails.reason': 'Possibile causa',
  'notificationDetails.stepByStep': 'Istruzioni passo-passo',

  // Main Menu
  'mainMenu.headline.slice.1': 'MyUNTHA',
  'mainMenu.headline.slice.2': 'Cliente personale',
  'mainMenu.salutation': 'Benvenuto',
  'mainMenu.action.pirate.logout': 'Esci da vista cliente',

  // Nav Items
  'navItem.admin.title': 'Amministrazione',
  'navItem.admin.description': 'Gestione, importazione, esportazione',
  'navItem.dashboard.title': 'MySHREDDER',
  'navItem.dashboard.description': 'Vista d\'insieme del tuo shredder',
  'navItem.notifications.title': 'Messaggi',
  'navItem.notifications.description': 'Allarmi ed eventi',
  'navItem.report.title': 'Report',
  'navItem.report.description': 'Creazione e gestione di rapporti sulle prestazioni',
  'navItem.explorer.title': 'Data Explorer',
  'navItem.explorer.description': 'Analisi dettagliata di tutti i dati operativi e di processo',
  'navItem.profile.title': 'MyPROFIL',
  'navItem.profile.description': 'Dati utenti e impostazioni',
  'navItem.faq.title': 'FAQ',
  'navItem.faq.description': 'Domande frequenti',

  // date stuff
  'date.days': 'giorni',
  'date.weeks': 'settimane',
  'date.months': 'mesi',
  'date.day': 'Giorno',
  'date.week': 'Settimana',
  'date.month': 'Mese',

  // unitSystem
  'unitSystem.metricUnits': 'metrico',
  'unitSystem.imperialUnits': 'angloamericano',

  // ReportHistory
  'reportHistory.type.scheduled': 'automatico',
  'reportHistory.type.single': 'una sola volta',
  'reportHistory.status.error': 'Errore',
  'reportHistory.status.pending': 'in lavorazione',
  'reportHistory.noResults': 'Nessun report precedente.',

  // CreateReport
  'createReport.headline': 'Nuovo report prestazioni',
  'createReport.action.create': 'Nuovo report',

  // ReportForm
  'reportForm.type.single.title': 'Report unico',
  'reportForm.type.single.description': 'Riceverete il report prestazioni con un unico invio per e-mail. Si tenga presente che la generazione del report richiede una decina di minuti.',
  'reportForm.type.scheduled.title': 'Report automatico',
  'reportForm.type.scheduled.description': 'Ogni giorno o periodo selezionato viene annotato nel report prestazioni. L\'invio del report settimanale viene effettuato un\'ora dopo l\'ultima ora selezionata (ad es. ultima ora selezionata Giovedì 16:00 – 18:00, il report verrà inviato alle ore 19:00).',
  'reportForm.shredder.title': 'Trituratore',
  'reportForm.shredder.description': 'Selezionare il trituratore per il quale creare il report prestazioni.',
  'reportForm.shredder.select.placeholder': 'Selezionare un trituratore',
  'reportForm.period.title': 'Periodo',
  'reportForm.period.description': 'I report prestazioni possono estendersi su un periodo di 8 ore. È possibile generare report risalenti fino a 4 mesi prima.',
  'reportForm.materialDensity.title': 'Densità del materiale (opzionale)',
  'reportForm.materialDensity.description': 'La densità del materiale si utilizza per calcolare la quantità del materiale di lavorazione.',
  'reportForm.materialWeight.title': 'Massa lavorata nell\'arco di tempo indicato (opzionale)',
  'reportForm.materialWeight.description': 'Il peso si utilizza per calcolare i parametri basilari.',
  'reportForm.energyCosts.title': 'Costo energetico',
  'reportForm.energyCosts.description': 'Gli indici del costo energetico si utilizzano per calcolare il costo complessivo e il costo per tonnellata.',
  'reportForm.technical.title': 'Rappresentazione',
  'reportForm.technical.description': 'L’emissione delle curve caratteristiche avviene solo selezionando un periodo massimo di 8 ore.',
  'reportForm.technical.checkbox.label': 'Visualizza curve caratteristiche',

  // CsvImport
  'csvImport.step.companies': 'Aziende',
  'csvImport.step.users': 'Utente',
  'csvImport.step.shredders': 'Trituratore',
  'csvImport.step.shredderAttributes': 'Caratteristiche',
  'csvImport.step.salesPersons': 'Venditore',
  'csvImport.dropArea.headline': 'Trascinare qui i dati CSV',
  'csvImport.dropArea.body': 'o',
  'csvImport.dropArea.cta': 'Selezionare i dati CSV',
  'csvImport.warning': 'Avvertenza: con l’importazione la banca dati attuale viene sostituita.',
  'csvImport.action.upload.success': '{fileName} caricato con successo.',
  'csvImport.action.upload.error': "Nell'aggiornamento dei dati si è verificato un errore. Riprova in un momento successivo o rivolgiti al supporto UNTHA.",
  'csvImport.action.complete.success': 'Tutti i file sono stati caricati con successo',

  // CsvExport
  'csvExport.data.companies': 'Aziende',
  'csvExport.data.users': 'Utente',
  'csvExport.data.shredders': 'Trituratore',
  'csvExport.data.shredderAttributes': 'Caratteristiche',
  'csvExport.data.salesPersons': 'Venditore',

  // ScheduledReports
  'scheduledReports.modal.title': 'Modifica report prestazioni',
  'scheduledReports.noResults': 'Nessun report automatico.',
  'scheduledReports.limit': '{count} di {limit} report automatici',

  // ExplorerKickoffCard
  'explorerKickoffCard.headline': 'Seleziona punti dati',
  'explorerKickoffCard.body': 'Data Explorer consente di confrontare diversi punti dati o valori di processo di un trituratore. Selezionare prima i punti dati che si desiderano confrontare.',
  'explorerKickoffCard.action.choose': 'Seleziona punti dati',

  // ExplorerPreset
  'explorerPreset.body': `{count, plural,
    =0 {Nessun punto dati}
    one {1 punto dati}
    other {{count}Punti dati}
  }`,

  // ExplorerLayout
  'explorerLayout.breadcrumb': 'Explorer dati',
  'explorerLayout.action.data': 'Punti dati',
  'explorerLayout.action.favorites': 'Preferiti',
  'explorerLayout.action.realtime': 'Tempo real',
  'explorerLayout.aggregation.title': 'Risoluzione temporale',

  // ExplorerEntry
  'explorerEntry.controls.filter.switch.costs': 'Visualizza costo energetico',
  'explorerEntry.controls.filter.switch.activePower': 'Visualizza potenza apparente',
  'explorerEntry.emptySearch.text': 'Non sono disponibili dati relativi al periodo selezionato.',

  // ShredderStatus
  'shredderStatus.title': 'Stato',
  'shredderStatus.since': 'da',
  'shredderStatus.status.active.label': '<strong>Produzione</strong>',
  'shredderStatus.status.idle.label': '<strong>A vuoto</strong>',
  'shredderStatus.status.rest.label': '<strong>Fermo</strong>',
  'shredderStatus.status.overload.label': '<strong>Inversione</strong> Sovraccarico',
  'shredderStatus.status.cleaning.label': '<strong>Inversione</strong> Pulizia del rotore',
  'shredderStatus.status.defect.label': '<strong>Guasto</strong> Macchina',
  'shredderStatus.status.defectExternal.label': '<strong>Guasto</strong> Esterno',
  'shredderStatus.status.maintenance.label': '<strong>Manutenzione</strong>',
  'shredderStatus.status.standby.label': '<strong>Standby</strong>',
  'shredderStatus.status.offline.label': '<strong>Offline</strong>',
  'shredderStatus.status.eco.label': '<strong>Modo ECO</strong>',

  // StateOneMeasurement
  'stateOneMeasurement.preset.last24Hours': 'Ultime 24 ore',
  'stateOneMeasurement.preset.prev7Days': 'Ultimi 7 giorni',
  'stateOneMeasurement.preset.prev30Days': 'Ultimi 30 giorni',
  'stateOneMeasurement.state.active.label': 'Produzione',
  'stateOneMeasurement.state.idle.label': 'A vuoto',
  'stateOneMeasurement.state.standby.label': 'Standby',
  'stateOneMeasurement.state.rest.label': 'Fermo',
  'stateOneMeasurement.state.overload.label': 'Inversione sovraccarico',
  'stateOneMeasurement.state.cleaning.label': 'Inversione pulizia del rotore',
  'stateOneMeasurement.state.defect.label': 'Guasto macchina',
  'stateOneMeasurement.state.defectExternal.label': 'Guasto esterno',
  'stateOneMeasurement.state.maintenance.label': 'Manutenzione',
  'stateOneMeasurement.state.offline.label': 'Offline',
  'stateOneMeasurement.state.eco.label': 'Modo ECO',

  // UI Settings
  'uiSettings.update.success': 'Interfaccia utente aggiornata con successo',

  // NotificationTable
  'notificationTable.search.input.placeholder': 'Trova allarme...',

  // Copyright
  'copyright.label': '© {year} by UNTHA shredding technology GmbH',

  // Notification Settings
  'notificationSettings.text': 'Consenti a questo allarme di avvisarti se si verifica di nuovo.',
  'notificationSettings.type.email': 'Avvisi e-mail',
  'notificationSettings.type.sms': 'Avvisi SMS',
  'notificationSettings.template.subject': '{priority}: allarme nel trituratore {shredder}',
  'notificationSettings.template.text': 'Il seguente allarme si è appena attivato nel trituratore {shredder}: "{title}" | {priority}',
  'notificationSettings.toast.sms.update.success': 'Avviso SMS aggiornato',
  'notificationSettings.toast.email.update.success': 'Avviso e-mail aggiornato',
  'notificationSettings.toast.sms.delete.success': 'Avviso SMS cancellato',
  'notificationSettings.toast.email.delete.success': 'Avviso e-mail cancellato',
  'notificationSettings.toast.sms.create.success': 'Avviso SMS creato',
  'notificationSettings.toast.email.create.success': 'Avviso e-mail creato',
  'notificationSettings.action.recipient.add': 'Aggiungi destinatario',
  'notificationSettings.action.recipient.remove': 'Cancella destinatario',

  // units
  'units.label.celsius': 'Celsius',
  'units.label.fahrenheit': 'Fahrenheit',
  'units.label.voltage': 'Volt',
  'units.label.rpm': 'Giri al minuto',
  'units.label.watt': 'Watt',
  'units.label.kilowatt': 'Kilowatt',
  'units.label.voltampere': 'Volt Ampere',
  'units.label.kva': 'Kilovolt Ampere',
  'units.label.microsiemensPerMeter': 'Microsiemens al metro',
  'units.label.fillLevel': 'Livello di riempimento',
  'units.label.hours': 'Ore',
  'units.label.newton': 'Newton',
  'units.label.torque': 'Coppia',
  'units.label.permittivity': 'Permettività',
  'units.label.percentage': 'Percento',
  'units.label.bar': 'Bar',
  'units.label.ampere': 'Ampere',
  'units.label.psi': 'Libbra per pollice quadrato',
  'units.label.acceleration': 'Accelerazione',
  'units.label.frequency': 'Hertz',

  'units.symbol.celsius': '°C',
  'units.symbol.fahrenheit': '°F',
  'units.symbol.voltage': 'V',
  'units.symbol.rpm': 'RPM',
  'units.symbol.watt': 'W',
  'units.symbol.kilowatt': 'kW',
  'units.symbol.voltampere': 'VA',
  'units.symbol.kva': 'kVA',
  'units.symbol.kwh': 'kWh',
  'units.symbol.microsiemensPerMeter': 'µS/m',
  'units.symbol.percentage': '%',
  'units.symbol.hours': 'h',
  'units.symbol.newton': 'N',
  'units.symbol.torque': 'Nm',
  'units.symbol.bar': 'bar',
  'units.symbol.psi': 'psi',
  'units.symbol.count': 'x',
  'units.symbol.ampere': 'A',
  'units.symbol.acceleration': 'm/s^2',
  'units.symbol.frequency': 'Hz',

  // datePicker
  'datePicker.presets.today': 'Oggi',
  'datePicker.presets.yesterday': 'Ieri',
  'datePicker.presets.past3Days': 'Ultimi 3 giorni',
  'datePicker.presets.past7Days': 'Ultima settimana',

  // salutations
  'salutation.mr': 'Signor',
  'salutation.mrs': 'Signora',

  // currencies
  'currencies.eur': 'Euro',
  'currencies.usd': 'Dollaro USA',
  'currencies.gbp': 'Lira sterlina',
  'currencies.jpy': 'Yen',

  // UseShredderSub
  'useShredderSub.handshake.error': 'Impossibile stabilire la connessione ai dati live',
  'useShredderSub.subscription.error': 'Errore nella connessione ai dati live',

  'aggregation.minutely': 'ogni minuto',
  'aggregation.secondly': 'ogni secondo',
  'aggregation.hourly': 'ogni ora',

  'timeFormat.24h': '24 ore',
  'timeFormat.12h': '12 ore',

  'explorerSupportCard.headline': 'Attenzione!',
  'explorerSupportCard.body': 'Questa funzione purtroppo non è disponibile su questo apparecchio. Passare a un apparecchio con almeno 768 pixel.',

  'userTable.dialog.delete.title': 'Cancella utente',
  'userTable.dialog.delete.headline': 'Attenzione',
  'userTable.dialog.delete.text': 'La rimozione dell’utente "{firstName} {lastName}" è irrevocabile.',

  'explorerAnnouncement.title': 'Tutorial Explorer dati',
  'explorerAnnouncement.body': 'L’Explorer dati consente di affiancare e confrontare diversi dati di esercizio e di processo.',
  'explorerAnnouncement.videoSrc.webm': '/videos/tutorials/untha_tutorial_data_explorer_en.webm',
  'explorerAnnouncement.videoSrc.mp4': '/videos/tutorials/untha_tutorial_data_explorer_en.mp4',

  // Downloads
  'downloads.headline.fallback': 'Download',

  // EmptySearch
  'emptySearch.headline': 'Nessun risultato della ricerca',

  // HistoryChart
  'historyChart.empty': 'Non sono disponibili dati relativi a questa selezione.',
  'historyChart.action.reselect': 'Nuova selezione',
  'historyChart.error': 'Nel trattamento dei dati cronologici si è verificato un errore. Riprova in un momento successivo o rivolgiti al supporto UNTHA.',

  'reportAnnouncement.title': 'Tutorial report prestazioni',
  'reportAnnouncement.body': 'Il report prestazioni consente di creare facilmente una panoramica dei dati della macchina. I dati sintetizzati vengono inviati come file PDF a un indirizzo e-mail indicato – con un invio unico o regolarmente con invii settimanali.',
  'reportAnnouncement.videoSrc.webm': '/videos/tutorials/untha_tutorial_report_en.webm',
  'reportAnnouncement.videoSrc.mp4': '/videos/tutorials/untha_tutorial_report_en.mp4',

  // Announcer
  'announcer.action.read': 'Non visualizzare più',

  // ShredderDataTile
  'shredderDataTile.body.count': `{count, plural,
    =0 {Nessun record}
    one {1 record}
    other {{count} record di dati}
  }`,

  // SignIn
  'signIn.toast.spam': 'Collegamento già in corso.',

  // TimestampIndicator
  'timestampIndicator.live': 'Dati attuali',
  'timestampIndicator.recent': 'ultimo aggiornamento {timestamp}',

  'pdfReport.header.label': 'Report prestazioni',
  'pdfReport.header.machine': 'Macchina',
  'pdfReport.header.timerange': 'Periodo',
  'pdfReport.logBook.header': 'Registro',
  'pdfReport.stateOne.entryHeader': 'Stato',
  'pdfReport.stateOne.states.active': 'Produzione',
  'pdfReport.stateOne.states.idle': 'A vuoto',
  'pdfReport.stateOne.states.standby': 'Standby',
  'pdfReport.stateOne.states.rest': 'Fermo',
  'pdfReport.stateOne.states.overload': 'Inversione Sovraccarico',
  'pdfReport.stateOne.states.cleaning': 'Inversione Pulizia del rotore',
  'pdfReport.stateOne.states.defect': 'Guasto Macchina',
  'pdfReport.stateOne.states.defectExternal': 'Guasto Esterno',
  'pdfReport.stateOne.states.maintenance': 'Manutenzione',
  'pdfReport.stateOne.states.offline': 'Offline',
  'pdfReport.stateOne.states.eco': 'Modo ECO',
  'pdfReport.globalParamsrevUnplanned': 'Inversione dovuta a sovraccarico',
  'pdfReport.globalParamsoperatingHours': 'Ore di esercizio (alla fine del periodo di osservazione)',
  'pdfReport.globalParamsdisruptiveOffActive': 'Disattivazioni impurità',
};

export default messages;
