import React from 'react';
import { useUser } from 'src/hooks/useUser';
import { usePirate } from 'src/hooks/usePirate';
import type { Shredder } from 'src/types';

/**
 * Fetch all available shredders for the current authenticated user.
 */
export function useShredders() {
  const { data: user } = useUser();
  const { data: pirateUser } = usePirate();

  const shredders = React.useMemo(() => {
    const addCompanyId = (shredder: Shredder, companyId?: string) => ({
      ...shredder,
      companyId,
    });

    return [
      ...(user?.shredders || []).map((s) => addCompanyId(s, user?.company.companyId)),
      ...(pirateUser?.shredders || []).map((s) =>
        addCompanyId(s, pirateUser?.company.companyId),
      ),
    ].sort((a, b) => a.shredderId.localeCompare(b.shredderId));
  }, [user, pirateUser]);

  return shredders;
}
