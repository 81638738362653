import { atom } from 'recoil';
import { DEFAULT_LOCALE } from 'src/constants';
import { Locale } from 'src/types';
import { localStorageEffect } from './effects/localStorage';

export const localeState = atom<Locale | undefined>({
  key: 'i18n/locale',
  default: DEFAULT_LOCALE,
  effects_UNSTABLE: [localStorageEffect<Locale>('locale')],
});
