import { css } from '@emotion/react';
import { Icon, primaryLinkHoverStyle, Text } from '@untha/ui';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSpring, a } from 'react-spring';

type AnimatedBrandProps = {
  className?: string;
  size: 'small' | 'default';
};

const commonStyle = css`
  height: 34px;
`;

export function AnimatedBrand({ className, size }: AnimatedBrandProps) {
  const { formatMessage } = useIntl();
  const { x } = useSpring({ x: size === 'default' ? 0 : 0.9 });

  return (
    <div
      className={clsx(className, 'grid', 'pointer-events-none')}
      css={css`
        /* stack items */
        & > * {
          grid-area: 1 / 1 / 1 / 1;
        }
      `}
    >
      <a.div
        style={{ clipPath: x.to((x) => `inset(0px ${x * 100}% 0px 0px round 99rem)`) }}
        css={commonStyle}
        className="flex items-center"
      >
        <img src="/images/untha.svg" />
        <a
          className="ml-6 font-bold flex items-center pointer-events-auto"
          css={primaryLinkHoverStyle}
          href="https://www.untha.com"
        >
          <Text variant="small">{formatMessage({ id: 'animatedBrand.cta.label' })}</Text>
          <Icon iconName="ArrowRight" className="ml-1" size="large" />
        </a>
      </a.div>
      <img css={commonStyle} src="/images/untha-small.svg" />
    </div>
  );
}
