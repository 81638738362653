import axios, { AxiosRequestConfig } from 'axios';
import { parseShredderCharacteristics } from 'src/helpers';
import type { Company, Shredder, PirateUser } from 'src/types';

export type GetPirateUserProfile = (
  companyId: string,
  /**
   * @default false
   */
  setInterceptor?: boolean,
) => Promise<PirateUser>;

export const getPirateUserProfile: GetPirateUserProfile = async (
  companyId,
  setInterceptor = false,
) => {
  const res = await axios.post<{
    companyData: Company;
    shredders: (Omit<Shredder, 'shredderCharacteristics'> & {
      shredderCharacteristics: Record<string, string>;
    })[];
  }>(process.env.REACT_APP_USER_API_URL_V3 + '/adminData', { companyId });

  const {
    data: { jwt: iotJwt },
  } = await axios.get<{ jwt: string; expires: string }>(
    process.env.REACT_APP_IOT_API_URL_V3 + '/token/token',
    {
      params: { companyId },
    },
  );

  // register global interceptor before all react-query requests
  // (gets cleared in `usePirate` hook)
  let interceptorId: number | undefined;
  if (setInterceptor) {
    interceptorId = axios.interceptors.request.use((config: AxiosRequestConfig) => {
      config.headers['x-untha-iot'] = iotJwt;
      return config;
    });
  }

  const data = {
    company: res.data.companyData,
    // keep similar shape to the useAuth hook
    auth: {
      iotToken: iotJwt,
      interceptorId,
    },
    shredders: res.data.shredders.map((s) => ({
      ...s,
      shredderCharacteristics: parseShredderCharacteristics(s.shredderCharacteristics),
    })),
  };

  return data;
};
