import { useQuery } from 'react-query';
import { queryKeys } from 'src/constants';
import { getAlarms } from 'src/services/notification';
import type { Alarm, AlarmSeverityLevel, Period } from 'src/types';

type UseAlarmsProps = {
  cumulocityId?: string;
  period?: Partial<Period>;
  type?: string;
  /**
   * @default 10
   */
  pageSize?: number;
  severities?: AlarmSeverityLevel[];
  resolved?: boolean;
  /**
   * This callback will fire any time the query successfully fetches new data.
   */
  onSuccess?: (data: Alarm[]) => void;
  refetchInterval?: number;
  enabled?: boolean;
  suspense?: boolean;
  onError?: () => void;
  useErrorBoundary?: boolean;
};

export function useAlarms({
  cumulocityId,
  period,
  type,
  pageSize = 10,
  severities,
  resolved,
  onSuccess,
  refetchInterval,
  enabled,
  suspense = true,
  onError,
  useErrorBoundary,
}: UseAlarmsProps | undefined = {}) {
  const { dateFrom, dateTo } = period || {};

  return useQuery(
    [queryKeys.getAlarms, cumulocityId, type, severities, resolved, dateFrom, dateTo],
    () =>
      getAlarms({ source: cumulocityId, period, type, pageSize, severities, resolved }),
    {
      suspense,
      enabled,
      onSuccess,
      refetchInterval,
      onError,
      useErrorBoundary,
    },
  );
}
