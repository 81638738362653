// prettier-ignore
const messages = {
  // project meta
  'project.title': 'MyUNTHA',
  'project.brand': 'UNTHA',
  'project.brandPhrase': 'shredding technology',
  'project.brandSub': 'Ihr persönliches Kundenportal',

  // locales
  'locale.en': 'Englisch',
  'locale.de': 'Deutsch',
  'locale.es': 'Spanisch',
  'locale.fr': 'Französisch',
  'locale.it': 'Italienisch',

  // pages
  'page.dashboard.breadcrumb': 'Dashboard',
  'page.report.breadcrumb': 'Report',
  'page.report.title': 'Performance Reports',
  'page.report.history': 'Letzte Performance-Reports',
  'page.report.scheduled': 'Automatische Performance-Reports',
  'page.admin.breadcrumb': 'Administration',
  'page.admin.section.management': 'Verwaltung',
  'page.admin.section.data': 'Datenbank',
  'page.admin.companyTable.headline': 'Kunden',
  'page.admin.csvImport.headline': 'Import',
  'page.admin.csvExport.headline': 'Export',
  'page.explorer.manage.breadcrumb': 'Datenpunkte',
  'page.explorer.manage.search.placeholder': 'Datenpunkt suchen...',
  'page.explorer.manage.search.empty': 'Es wurden keine Ergebnisse gefunden.',
  'page.explorer.manage.limit': 'Die maximale Anzahl an Datenpunkten wurde erreicht.',
  'page.explorer.intro.section.favorites': 'Favoriten',
  'page.shredder.section.overview': 'Übersicht',
  'page.shredder.section.records': 'Prozesswerte',
  'page.shredder.downloads': 'Downloads',
  'page.explorer.presets.placeholder': 'Keine Favoriten vorhanden.',
  'page.explorer.presets.action.load': 'Favorit Laden',
  'page.explorer.presets.title': 'Favoriten',
  'page.explorer.presets.input.placeholder': 'Favoriten Titel',
  'page.notifications.title': 'Mitteilungen',
  'page.notifications.breadcrumb': 'Mitteilungen',
  'page.notifications.section.notifications': 'Aktuelle Mitteilungen',
  'page.notifications.section.notifications.alarms': 'Alarme',
  'page.notifications.section.notifications.events': 'Ereignisse',
  'page.notifications.section.settings': 'Einstellungen',
  'page.notifications.section.settings.notifications': 'Benachrichtigungen',
  'page.registrationConfirmation.section.success.headline': 'Registrierung abgeschlossen',
  'page.registrationConfirmation.section.success.body': 'Dein Registrierungsprozess ist abgeschlossen. Du kannst dich jetzt anmelden.',
  'page.registrationConfirmation.section.error.headline': 'Registrierung fehlgeschlagen',
  'page.registrationConfirmation.section.error.body': 'Dein Registrierungsprozess ist fehlgeschlagen. Bitte kontaktiere deinen Firmen Admin.',
  'page.login.headline': 'Anmelden',
  'page.login.body': 'Noch kein Konto?',
  'page.login.forgotPassword': 'Passwort vergessen?',
  'page.register.headline': 'Registrieren',
  'page.register.body': 'Sie sind Kunde von UNTHA und haben noch keinen Zugang zum MyUNTHA-Portal? Registrieren Sie sich hier.',
  'page.register.back': 'Schon registriert?',
  'page.register.toast.success': 'Registrierung erfolgreich',
  'page.register.toast.error': 'Beim Registrieren ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder wenden Sie sich an den UNTHA-Support.',
  'page.forgotPassword.headline': 'Passwort zurücksetzen',
  'page.forgotPassword.body': 'Bitte geben Sie Ihre E-Mail-Adresse und Kundennummer ein und wir senden Ihnen ein temporäres Passwort zu. Beim erstmaligen Login können Sie anschließend ein neues Passwort vergeben.',
  'page.forgotPassword.back': 'Zurück zum Login',
  'page.newPassword.headline': 'Passwort aktualisieren',
  'page.newPassword.body': 'Wählen Sie ein neues Passwort für Ihren zukünftigen Login.',
  'page.profile.breadcrumb': 'MyPROFIL',
  'page.profile.section.user': 'Persönliche Daten',
  'page.profile.section.user.profile': 'Ihre Daten',
  'page.profile.section.user.company': 'Ihr Unternehmen',
  'page.profile.section.settings': 'Einstellungen',
  'page.profile.section.settings.ui': 'Benutzeroberfläche',
  'page.profile.section.settings.password': 'Passwort',
  'page.profile.section.settings.energyCosts': 'Energiekosten',
  'page.profile.section.admin': 'Administration',
  'page.profile.section.admin.subUser': 'Benutzerkonten',
  'page.profile.section.admin.subUser.info': 'In diesem Bereich können Sie bestehende Benutzer verwalten oder zusätzliche Benutzer anlegen. Für die Anlage eines zusätzlichen Benutzers muss E-Mailadresse, Vorname und Nachname eingegeben werden. Nach Kontoanlage wird eine E-Mail verschickt, über welche sich der Benutzer registrieren und einloggen kann.',
  'page.shredders.empty': 'Noch kein Schredder verfügbar.',
  'page.shredders.section.shredder': `{count, plural,
    one {Ihr Schredder}
    other {Ihre Schredder}
  }`,

  // Table
  'table.th.period': 'Zeitraum',
  'table.th.reportType': 'Typ',
  'table.th.file': 'Datei',
  'table.th.shredder': 'Schredder',
  'table.th.email': 'E-Mail',
  'table.th.id': 'ID',
  'table.th.company': 'Firmenname',
  'table.th.status': 'Status',
  'table.th.number': 'Nr.',
  'table.th.alarm': 'Alarm',
  'table.th.priority': 'Priorität',
  'table.th.sms': 'SMS',
  'table.th.firstName': 'Vorname',
  'table.th.lastName': 'Nachname',

  // ReportHistory
  'reportHistory.type.scheduled': 'automatisch',
  'reportHistory.type.single': 'einmalig',
  'reportHistory.status.error': 'Fehler',
  'reportHistory.status.pending': 'in Arbeit',
  'reportHistory.noResults': 'Keine vergangenen Reports.',
  'reportHistory.modal.title': 'Neuer Performance Report',

  // ReportForm
  'reportForm.type.single.title': 'Einmaliger Report',
  'reportForm.type.single.description': 'Sie erhalten das Ergebnis des Performance-Reports einmalig per E-Mail. Bitte beachten Sie, dass das Generieren des Reports bis zu 10 Minuten dauern kann.',
  'reportForm.type.scheduled.title': 'Automatischer Report',
  'reportForm.type.scheduled.description': 'Jeder gewählte Tag bzw. Zeitraum wird im Performance-Report vermerkt. Der Report wird am Tag nach dem letzten gewählten Zeitpunkt versendet.',
  'reportForm.shredder.title': 'Schredder',
  'reportForm.shredder.description': 'Wählen Sie den Shredder, für den der Performance-Report erstellt werden soll.',
  'reportForm.shredder.select.placeholder': 'Wählen Sie einen Shredder',
  'reportForm.period.title': 'Zeitraum',
  'reportForm.period.description': 'Performance-Reports können einen Zeitraum von bis zu 8 Stunden umfassen. Die Reports sind 4 Monate in die Vergangenheit möglich.',
  'reportForm.period.error.period': 'Der Zeitraum muss kleiner als 8 Stunden sein',
  'reportForm.period.error.periodInvalid': 'Bitte wählen Sie einen gültigen Zeitraum.',
  'reportForm.apiError': 'Beim Anlegen des Reports ist ein Fehler aufgetreten.',
  'reportForm.materialDensity.title': 'Materialdichte',
  'reportForm.materialDensity.description': 'Die Materialdichte wird zur Berechnung des Durchsatzes verwendet.',
  'reportForm.materialWeight.title': 'Gewicht',
  'reportForm.materialWeight.description': 'Das Gewicht wird zur Berechnung der Basis-Kennzahlen verwendet.',
  'reportForm.energyCosts.title': 'Energiekosten',
  'reportForm.energyCosts.description': 'Die Energiekosten werden zur Berechnung der Gesamtkosten und Kosten pro Tonne verwendet.',
  'reportForm.technical.title': 'Darstellung',
  'reportForm.technical.description': 'Technische Kennlinien werden nur dann ausgegeben, wenn der ausgewählte Zeitraum maximal 8 Stunden beträgt.',
  'reportForm.technical.checkbox.label': 'Technische Kennlinien anzeigen',

  // ScheduledReports
  'scheduledReports.modal.title': 'Performance-Report bearbeiten',
  'scheduledReports.noResults': 'Keine automatischen Reports.',
  'scheduledReports.limit': '{count} von {limit} automatischen Reports',

  // CompanyTable
  'companyTable.action.pirate': 'Kundenansicht',
  'companyTable.deleteDialog.title': 'Kunde deaktivieren',
  'companyTable.deleteDialog.headline': 'Vorsicht',
  'companyTable.deleteDialog.body': 'Durch das Deaktivieren eines Kuden können sich alle ihm zugeordneten Benutzer nicht mehr im Kundenportal einloggen.',
  'companyTable.deleteDialog.confirmation': 'Wollen Sie wirklich fortfahren?',
  'companyTable.deleteDialog.notify': 'E-Mail über Deaktivierung an alle Benutzer des Kunden versenden',
  'companyTable.search.input.placeholder': 'Suche Kunden...',
  'companyTable.toast.impersonate.active.success': 'Kundenansicht aktiviert',
  'companyTable.toast.impersonate.active.error': 'Fehler bei Kundenansicht Aktivierung',
  'companyTable.toast.impersonate.disabled.success': 'Kundenansicht deaktiviert',

  // CsvImport
  'csvImport.step.companies': 'Firmen',
  'csvImport.step.users': 'Benutzer',
  'csvImport.step.shredders': 'Schredder',
  'csvImport.step.shredderAttributes': 'Merkmale',
  'csvImport.step.salesPersons': 'Verkäufer',
  'csvImport.dropArea.headline': 'CSV-Datei hierher ziehen',
  'csvImport.dropArea.body': 'oder',
  'csvImport.dropArea.cta': 'CSV-Datei auswählen',
  'csvImport.warning': 'Warnung: Durch den Import wird die aktuelle Datenbank ersetzt.',
  'csvImport.action.upload.success': '{fileName} erfolgreich hochgeladen.',
  'csvImport.action.upload.error': 'Bei der Datenaktualisierung ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder wenden Sie sich an den UNTHA-Support.',
  'csvImport.action.complete.success': 'Alle Dateien wurden erfolgreich hochgeladen',

  // CsvExport
  'csvExport.data.companies': 'Firmen',
  'csvExport.data.users': 'Benutzer',
  'csvExport.data.shredders': 'Schredder',
  'csvExport.data.shredderAttributes': 'Merkmale',
  'csvExport.data.salesPersons': 'Verkäufer',

  // ExplorerKickoffCard
  'explorerKickoffCard.headline': 'Datenauswahl',
  'explorerKickoffCard.body': 'Mit dem Data Explorer können verschiedene Datenpunkte bzw. Prozesswerte eines Shredders verglichen werden. Wählen Sie zunächst die Datenpunkte aus, die Sie vergleichen wollen.',
  'explorerKickoffCard.action.choose': 'Datenpunkte wählen',

  // ExplorerPreset
  'explorerPreset.body': `{count, plural,
    =0 {Keine Datepunkte}
    one {1 Datenpunkt}
    other {{count} Datenpunkte}
  }`,

  // ExplorerLayout
  'explorerLayout.breadcrumb': 'Data Explorer',
  'explorerLayout.aggregation.title': 'Zeitliche Auflösung',
  'explorerLayout.action.data': 'Datenpunkte',
  'explorerLayout.action.favorites': 'Favoriten',
  'explorerLayout.action.realtime': 'Echtzeit',

  // ExplorerEntry
  'explorerEntry.controls.filter.switch.costs': 'Energiekosten anzeigen',
  'explorerEntry.controls.filter.switch.activePower': 'Scheinleistung anzeigen',
  'explorerEntry.emptySearch.text': 'Für Ihren ausgewählten Zeitraum sind keine Daten verfügbar.',

  // ExplorerZoom
  'explorerZoom.action.reset': 'Zoom zurücksetzen',

  // CreatePreset
  'createPreset.title.create': 'Aktuelle Konfiguration als Favorit speichern',
  'createPreset.title.update': 'Aktuellen Favorit aktualisieren',

  // common actions
  'action.edit': 'Bearbeiten',
  'action.delete': 'Löschen',
  'action.cancel': 'Abbrechen',
  'action.create': 'Erstellen',
  'action.save': 'Speichern',
  'action.saveChanges': 'Änderungen speichern',
  'action.disable': 'Deaktivieren',
  'action.enable': 'Aktivieren',
  'action.revert': 'Wiederherstellen',
  'action.download': 'Download',
  'action.upload': 'Hochladen',
  'action.update': 'Aktualisieren',
  'action.close': 'Schließen',
  'action.login': 'Anmelden',
  'action.logout': 'Abmelden',
  'action.register': 'Registrieren',
  'action.reset': 'Zurücksetzen',
  'action.add': 'Hinzufügen',

  // common states
  'state.enabled': 'aktiv',
  'state.disabled': 'inaktiv',

  // shredder attributes
  'shredderAttr.ENERGY_SUPPLY.label': 'Energieverbrauch',
  'shredderAttr.HOR_POWER_ACTIVE.label': 'Horizontalband Wirkleistung',
  'shredderAttr.HORCONVEYOR_POWER_ACTIVE.label': 'Horizontalband Wirkleistung',
  'shredderAttr.ENERGY_SUPPLY_COSTS.label': 'Energiekosten',
  'shredderAttr.CHILLER_POWER_APPARENT.label': 'Wasserkühlung Scheinleistung',
  'shredderAttr.RISE_POWER_ACTIVE.label': 'Steigband Wirkleistung',
  'shredderAttr.RAISECONVEYOR_POWER_ACTIVE.label': 'Steigband Wirkleistung',
  'shredderAttr.RISE_ENERGY.label': 'Steigband Energieverbrauch',
  'shredderAttr.RAISECONVEYOR_ENERGY.label': 'Steigband Energieverbrauch',
  'shredderAttr.OIL_LIFETIME_HYDR.label': 'Hydrauliköl Restlebensdauer',
  'shredderAttr.MAGN_ENERGY_COSTS.label': 'Magnetabscheider Energiekosten',
  'shredderAttr.MAGCONVEYOR_ENERGY_COSTS.label': 'Magnetabscheider Energiekosten',
  'shredderAttr.HEATPLATE_TEMP2.label': 'Kühlkörpertemperatur 2',
  'shredderAttr.HEATPLATE_TEMP1.label': 'Kühlkörpertemperatur 1',
  'shredderAttr.MAGN_POWER_ACTIVE.label': 'Magnetabscheider Wirkleistung',
  'shredderAttr.MAGCONVEYOR_POWER_ACTIVE.label': 'Magnetabscheider Wirkleistung',
  'shredderAttr.OIL_CONDUCTIVITY_GEAR2.label': 'Getriebeöl Leitfähigkeit 2',
  'shredderAttr.OIL_CONDUCTIVITY_GEAR1.label': 'Getriebeöl Leitfähigkeit 1',
  'shredderAttr.GEAR_REVOLUTION1.label': 'Getriebedrehzahl 1',
  'shredderAttr.GEAR_REVOLUTION2.label': 'Getriebedrehzahl 2',
  'shredderAttr.RISE_ENERGY_COSTS.label': 'Steigband Energiekosten',
  'shredderAttr.RAISECONVEYOR_ENERGY_COSTS.label': 'Steigband Energiekosten',
  'shredderAttr.OIL_PERMITIVITY_GEAR1.label': 'Getriebeöl Permitivität 1',
  'shredderAttr.OIL_PERMITIVITY_GEAR2.label': 'Getriebeöl Permitivität 2',
  'shredderAttr.OIL_CONDUCTIVITY_HYDR.label': 'Hydrauliköl Leitfähigkeit',
  'shredderAttr.POWER_APPARENT_ACT2.label': 'Scheinleistung Motor 2',
  'shredderAttr.ROTOR_REVOLUTION.label': 'Rotordrehzahl',
  'shredderAttr.ROTOR_REVOLUTION1.label': 'Rotordrehzahl 1',
  'shredderAttr.ROTOR_REVOLUTION2.label': 'Rotordrehzahl 2',
  'shredderAttr.OIL_TEMPERATURE_HYDR.label': 'Hydrauliköl Temperatur',
  'shredderAttr.POWER_APPARENT_ACT1.label': 'Scheinleistung Motor 1',
  'shredderAttr.COOLING_TEMPIN.label': 'Wasserkühlung Rücklauftemperatur',
  'shredderAttr.TORQUE_ROT.label': 'Rotormoment',
  'shredderAttr.TORQUE_ROT1.label': 'Rotormoment 1',
  'shredderAttr.TORQUE_ROT2.label': 'Rotormoment 2',
  'shredderAttr.MOTORTEMP_2_SENS.label': 'Motortemperatur 2',
  'shredderAttr.OIL_PERMITIVITY_HYDR.label': 'Hydrauliköl Permitivität',
  'shredderAttr.CHILLER_PB4.label': 'Raumtemperatur',
  'shredderAttr.CHILLER_POWER_ACTIVE.label': 'Wasserkühlung Wirkleistung',
  'shredderAttr.CHILLER_PB3.label': 'Kondensatordruck',
  'shredderAttr.CHILLER_PB2.label': 'Kühlung Vorlauftemperatur',
  'shredderAttr.UEFF_L23.label': 'Leiterspannung U23',
  'shredderAttr.CHILLER_PB1.label': 'Verdampfer Temperatur',
  'shredderAttr.OIL_TEMPERATURE_GEAR1.label': 'Getriebeöl Temperatur 1',
  'shredderAttr.OIL_TEMPERATURE_GEAR2.label': 'Getriebeöl Temperatur 2',
  'shredderAttr.CHILLER_ENERGY_COSTS.label': 'Wasserkühlung Energiekosten',
  'shredderAttr.HOR_ENERGY_COSTS.label': 'Horizontalband Energiekosten',
  'shredderAttr.HORCONVEYOR_ENERGY_COSTS.label': 'Horizontalband Energiekosten',
  'shredderAttr.REVOLUTION_ACT2.label': 'Motordrehzahl 2',
  'shredderAttr.HYDR_POWER_APPARENT.label': 'Hydraulik Scheinleistung',
  'shredderAttr.REVOLUTION_ACT1.label': 'Motordrehzahl 1',
  'shredderAttr.TORQUE1.label': 'Drehmoment 1',
  'shredderAttr.DEVICE_TEMP2.label': 'Invertertemperatur 2',
  'shredderAttr.DEVICE_TEMP1.label': 'Invertertemperatur 1',
  'shredderAttr.TORQUE2.label': 'Drehmoment 2',
  'shredderAttr.MAGN_ENERGY.label': 'Magnetabscheider Energieverbrauch',
  'shredderAttr.MAGCONVEYOR_ENERGY.label': 'Magnetabscheider Energieverbrauch',
  'shredderAttr.OIL_LEVEL_HYDR.label': 'Hydrauliköl Ölstand',
  'shredderAttr.UEFF_L31.label': 'Leiterspannung U31',
  'shredderAttr.POWER_ACTIVE.label': 'Wirkleistung',
  'shredderAttr.GRID_THD.label': 'Netzqualität THD',
  'shredderAttr.GRID_THD.description': 'Die Total Harmonic Distortion (THD) ist ein Maß für die Qualität eines Stromnetzes und darf laut EN50160 den Grenzwert von 8% nicht überschreiten. Im Detail beschreibt diese Kennzahl das Verhältnis der harmonischen Oberschwingungen (bis zur 40-fachen Oberwelle) zur eigentlichen Netzfrequenz.',
  'shredderAttr.OIL_LEVEL_GEAR1.label': 'Getriebeöl Ölstand 1',
  'shredderAttr.OIL_LEVEL_GEAR2.label': 'Getriebeöl Ölstand 2',
  'shredderAttr.VOLTAGE_OUT2.label': 'Ausgangsspannung 2',
  'shredderAttr.VOLTAGE_OUT1.label': 'Ausgangsspannung 1',
  'shredderAttr.POWER_ACTIVE_ACT2.label': 'Wirkleistung Motor 2',
  'shredderAttr.POWER_ACTIVE_ACT1.label': 'Wirkleistung Motor 1',
  'shredderAttr.OIL_AGE_GEAR1.label': 'Getriebeöl Alter 1',
  'shredderAttr.OIL_AGE_GEAR2.label': 'Getriebeöl Alter 2',
  'shredderAttr.HOR_ENERGY.label': 'Horizontalband Energieverbrauch',
  'shredderAttr.HORCONVEYOR_ENERGY.label': 'Horizontalband Energieverbrauch',
  'shredderAttr.CNT_REVERSING.label': 'Reversierungen',
  'shredderAttr.OIL_LIFETIME_GEAR1.label': 'Getriebeöl Restlebensdauer 1',
  'shredderAttr.OIL_LIFETIME_GEAR2.label': 'Getriebeöl Restlebensdauer 2',
  'shredderAttr.FUNNEL_LEVEL.label': 'Trichterfüllstand',
  'shredderAttr.HYDR_ENERGY.label': 'Hydraulik Energieverbrauch',
  'shredderAttr.HYDR_ENERGY_COSTS.label': 'Hydraulik Energiekosten',
  'shredderAttr.COOLING_TEMPOUT.label': 'Wasserkühlung Vorlauftemperatur',
  'shredderAttr.OIL_HUMIDITY_HYDR.label': 'Hydrauliköl relative Feuchtigkeit',
  'shredderAttr.UEFF_L12.label': 'Leiterspannung U12',
  'shredderAttr.MAGN_POWER_APPARENT.label': 'Magnetabscheider Scheinleistung',
  'shredderAttr.MAGCONVEYOR_POWER_APPARENT.label': 'Magnetabscheider Scheinleistung',
  'shredderAttr.CHILLER_ENERGY.label': 'Wasserkühlung Energieverbrauch',
  'shredderAttr.POWER_APPARENT.label': 'Scheinleistung',
  'shredderAttr.HYDR_POWER_ACTIVE.label': 'Hydraulik Wirkleistung',
  'shredderAttr.ENGINE_MECH_POWER2.label': 'Mechanische Leistung Motor 2',
  'shredderAttr.CNT_SERVICEFLAP.label': 'Serviceklappe offen',
  'shredderAttr.ENGINE_MECH_POWER1.label': 'Mechanische Leistung Motor 1',
  'shredderAttr.OPERATING_HOURS.label': 'Betriebsstunden',
  'shredderAttr.MOTORTEMP_1_SENS.label': 'Motortemperatur 1',
  'shredderAttr.PUSHER_FORCE.label': 'Druckkraft Schieber',
  'shredderAttr.ROTOR_MECH_POWER.label': 'Mechanische Leistung Rotor',
  'shredderAttr.HOR_POWER_APPARENT.label': 'Horizontalband Scheinleistung',
  'shredderAttr.HORCONVEYOR_POWER_APPARENT.label': 'Horizontalband Scheinleistung',
  'shredderAttr.OIL_HUMIDITY_GEAR2.label': 'Getriebeöl Relative Feuchtigkeit 2',
  'shredderAttr.CNT_CLUTCHSLIP1.label': 'Zähler Kupplungsrutschen 1',
  'shredderAttr.OIL_HUMIDITY_GEAR1.label': 'Getriebeöl Relative Feuchtigkeit 1',
  'shredderAttr.CNT_CLUTCHSLIP2.label': 'Zähler Kupplungsrutschen 2',
  'shredderAttr.RISE_POWER_APPARENT.label': 'Steigband Scheinleistung',
  'shredderAttr.RAISECONVEYOR_POWER_APPARENT.label': 'Steigband Scheinleistung',
  'shredderAttr.OIL_AGE_HYDR.label': 'Hydrauliköl Alter',
  'shredderAttr.DC_CURRENT2.label': 'Zwischenkreisspannung 2',
  'shredderAttr.DC_CURRENT1.label': 'Zwischenkreisspannung 1',
  'shredderAttr.ACC_EFF_BEARING.label': 'Radiale Schwingung Effektivwert',
  'shredderAttr.HYDCOOL_ENERGY.label': 'Hydraulikkühlung Energieverbrauch',
  'shredderAttr.HYDCOOL_ENERGY_COSTS.label': 'Hydraulikkühlung Energiekosten',
  'shredderAttr.HYDCOOL_ENERGY_ACTIVE.label': 'Hydraulikkühlung Wirkleistung',
  'shredderAttr.HYDCOOL_ENERGY_ACTIVE_COSTS.label': 'Hydraulikkühlung Energieverbrauch',
  'shredderAttr.HYDCOOL_POWER_APPARENT.label': 'Hydraulikkühlung Scheinleistung',
  'shredderAttr.ACTIVE_CURRENT1.label': 'Stromstärke 1',
  'shredderAttr.ACTIVE_CURRENT2.label': 'Stromstärke 2',
  'shredderAttr.GEARTEMP_1_SENS.label': 'Getriebetemperatur 1',
  'shredderAttr.GEARTEMP_2_SENS.label': 'Getriebetemperatur 2',
  'shredderAttr.VOLTAGE_U12.label': 'Leiterspannung U12',
  'shredderAttr.VOLTAGE_U23.label': 'Leiterspannung U23',
  'shredderAttr.VOLTAGE_U31.label': 'Leiterspannung U31',
  'shredderAttr.GENERATOR_FUELLEVEL.label': 'Stromaggregat Füllstand',
  'shredderAttr.GENERATOR_POWER.label': 'Stromaggregat Wirkleistung',
  'shredderAttr.GENERATOR_VOLTAGE.label': 'Stromaggregat Spannung',
  'shredderAttr.GENERATOR_FREQUENCY.label': 'Stromaggregat Frequenz',

  // shredder attribute clusters
  'shredderAttrCluster.engineTotalEnergyConsumption': 'Energieverbrauch',
  'shredderAttrCluster.engineTotalPowerActive': 'Gesamtleistung Maschine',
  'shredderAttrCluster.cntGearSkidding': 'Zähler Reversierungen',
  'shredderAttrCluster.cntServiceFlap': 'Zähler Serviceklappe offen',
  'shredderAttrCluster.rotorSpeed': 'Rotordrehzahl',
  'shredderAttrCluster.rotorTorque': 'Rotormoment',
  'shredderAttrCluster.rotorMechPower': 'Mechanische Leistung Rotor',
  'shredderAttrCluster.conductorVoltages': 'Leiterspannungen',
  'shredderAttrCluster.netQuality': 'Netzqualität THD',
  'shredderAttrCluster.engineTorque': 'Drehmoment Motoren',
  'shredderAttrCluster.engineSpeed': 'Drehzahl Motoren',
  'shredderAttrCluster.engineTemp': 'Motortemperatur',
  'shredderAttrCluster.enginePowerActive': 'Wirkleistung Motoren',
  'shredderAttrCluster.cntClutchSkidding': 'Zähler Kupplungsrutschen',
  'shredderAttrCluster.gearSpeed': 'Drehzahl Getriebe',
  'shredderAttrCluster.outputVoltage': 'Ausgangsspannung',
  'shredderAttrCluster.inverterTemp': 'Invertertemperatur',
  'shredderAttrCluster.heatSinkTemp': 'Kühlkörpertemperatur',
  'shredderAttrCluster.intCircuitVoltage': 'Zwischenkreisspannung',
  'shredderAttrCluster.conveyorsEnergyConsumption': 'Energieverbrauch Fördertechnik',
  'shredderAttrCluster.additionalEnergyConsumption': 'Energieverbrauch Nebenaggregate',
  'shredderAttrCluster.conveyorsPowerActive': 'Leistung Fördertechnik',
  'shredderAttrCluster.additionalPowerActive': 'Leistung Nebenaggregate',
  'shredderAttrCluster.waterCoolingTemp': 'Temperaturen Wasserkühlung',
  'shredderAttrCluster.oilLevel': 'Ölstände',
  'shredderAttrCluster.oilTemp': 'Öltemperaturen',
  'shredderAttrCluster.radialVibration': 'Radiale Schwingung Effektivwert',
  'shredderAttrCluster.funnelLevel': 'Trichterfüllstand',
  'shredderAttrCluster.pusherForce': 'Zylinderkraft Schieber',
  'shredderAttrCluster.activePower': 'Wirkstrom',
  'shredderAttrCluster.gearTemp': 'Getriebetemperaturen',
  'shredderAttrCluster.gearOil1': 'Getriebeöl 1',
  'shredderAttrCluster.gearOil2': 'Getriebeöl 2',
  'shredderAttrCluster.counter': 'Zähler',
  'shredderAttrCluster.hydrOil': 'Hydrauliköl',
  'shredderAttrCluster.generatorFuellevel':'Stromaggregat Füllstand',
  'shredderAttrCluster.generatorEffectivePower':'Stromaggregat Wirkleistung',
  'shredderAttrCluster.generatorVoltage':'Stromaggregat Spannung',
  'shredderAttrCluster.generatorFrequency':'Stromaggregat Frequenz',

  // shredder attribute topics
  'shredderAttrTopic.engineTotal': 'Maschine gesamt',
  'shredderAttrTopic.cuttingSystem': 'Schneidsystem',
  'shredderAttrTopic.energySupply': 'Energieeinspeisung',
  'shredderAttrTopic.engine': 'Motor',
  'shredderAttrTopic.gear': 'Getriebe',
  'shredderAttrTopic.frequencyConverter': 'Frequenzumrichter',
  'shredderAttrTopic.transportSystem': 'Fördertechnik',
  'shredderAttrTopic.additional': 'Nebenaggregate',
  'shredderAttrTopic.operatingSupplies': 'Betriebsmittel',
  'shredderAttrTopic.other': 'Sonstiges',
  'shredderAttrTopic.hydraulic': 'Hydraulik',
  'shredderAttrTopic.driveTrain': 'Antriebsstrang',
  'shredderAttrTopic.powerTrain': 'Antriebsstrang',
  'shredderAttrTopic.waterCooling': 'Wasserkühlung',
  'shredderAttrTopic.extras': 'Zubehör',
  'shredderAttrTopic.operatingData': 'Betriebsdaten',
  'shredderAttrTopic.pusher': 'Schieber',
  'shredderAttrTopic.rotor': 'Rotor',
  'shredderAttrTopic.clutch': 'Kupplung',
  'shredderAttrTopic.coolingCircuit': 'Kühlkreislauf',
  'shredderAttrTopic.horizontalConveyor': 'Horizontalband',
  'shredderAttrTopic.elevatingConveyor': 'Steigband',
  'shredderAttrTopic.magneticSeparator': 'Magnetabscheider',
  'shredderAttrTopic.rotorShaft': 'Rotorwelle',
  'shredderAttrTopic.slideCylinder': 'Schieberzylinder',
  'shredderAttrTopic.oilPumpDrive': 'Ölpumpe Antrieb',
  'shredderAttrTopic.oilPan': 'Ölwanne',
  'shredderAttrTopic.pusherForce': 'Druckkraft Schieber',
  'shredderAttrTopic.chiller': 'Wasserkühlung',
  'shredderAttrTopic.powerUnit': 'Stromaggregat',

  // ShredderStatus
  'shredderStatus.title': 'Status',
  'shredderStatus.since': 'seit',
  'shredderStatus.status.active.label': '<strong>Produktion</strong>',
  'shredderStatus.status.idle.label': '<strong>Leerlauf</strong>',
  'shredderStatus.status.rest.label': '<strong>Stillstand</strong>',
  'shredderStatus.status.overload.label': '<strong>Reversierung</strong> Überlastung',
  'shredderStatus.status.cleaning.label': '<strong>Reversierung</strong> Rotorreinigung',
  'shredderStatus.status.defect.label': '<strong>Störung</strong> Maschine',
  'shredderStatus.status.defectExternal.label': '<strong>Störung</strong> Extern',
  'shredderStatus.status.maintenance.label': '<strong>Wartung</strong>',
  'shredderStatus.status.standby.label': '<strong>Standby</strong>',
  'shredderStatus.status.offline.label': '<strong>Offline</strong>',
  'shredderStatus.status.eco.label': '<strong>ECO-Modus</strong>',

  // StateOneMeasurement
  'stateOneMeasurement.preset.last24Hours': 'Letzte 24 Stunden',
  'stateOneMeasurement.preset.prev7Days': 'Letzten 7 Tage',
  'stateOneMeasurement.preset.prev30Days': 'Letzten 30 Tage',
  'stateOneMeasurement.state.active.label': 'Produktion',
  'stateOneMeasurement.state.idle.label': 'Leerlauf',
  'stateOneMeasurement.state.standby.label': 'Standby',
  'stateOneMeasurement.state.rest.label': 'Stillstand',
  'stateOneMeasurement.state.overload.label': 'Reversierung Überlastung',
  'stateOneMeasurement.state.cleaning.label': 'Reversierung Rotorreinigung',
  'stateOneMeasurement.state.defect.label': 'Störung Maschine',
  'stateOneMeasurement.state.defectExternal.label': 'Störung Extern',
  'stateOneMeasurement.state.maintenance.label': 'Wartung',
  'stateOneMeasurement.state.offline.label': 'Offline',
  'stateOneMeasurement.state.eco.label': 'ECO-Modus',

  // notification severity
  'notificationSeverity.0': 'Warnung',
  'notificationSeverity.1': 'Weniger Wichtig',
  'notificationSeverity.2': 'Wichtig',
  'notificationSeverity.3': 'Kritisch',

  // notification acknowledged alarms
  'notificationSeverity.acknowledgedAlarms': 'Quittierte Alarme',

  // UiSettings
  'uiSettings.update.success': 'Benutzeroberfläche erfolgreich aktualisiert',

  // NotificationTable
  'notificationTable.search.input.placeholder': 'Suche Alarm...',

  // Copyright
  'copyright.label': '© {year} by UNTHA shredding technology GmbH',

  // NotificationSettings
  'notificationSettings.headline': 'Benachrichtigungen',
  'notificationSettings.text': 'Lassen Sie sich über diesen Alarm benachrichtigen, wenn er erneut eintritt.',
  'notificationSettings.type.email': 'E-Mail Benachrichtigungen',
  'notificationSettings.type.sms': 'SMS Benachrichtigungen',
  'notificationSettings.template.subject': '{priority}: Alarm bei Shredder {shredder}',
  'notificationSettings.template.text': 'Folgender Alarm wurde soeben am Shredder {shredder} ausgelöst: "{title}" | {priority}',
  'notificationSettings.toast.sms.update.success': 'SMS Benachrichtigung aktualisiert',
  'notificationSettings.toast.email.update.success': 'E-Mail Benachrichtigung aktualisiert',
  'notificationSettings.toast.sms.delete.success': 'SMS Benachrichtigung gelöscht',
  'notificationSettings.toast.email.delete.success': 'E-Mail Benachrichtigung gelöscht',
  'notificationSettings.toast.sms.create.success': 'SMS Benachrichtigung erstellt',
  'notificationSettings.toast.email.create.success': 'E-Mail Benachrichtigung erstellt',
  'notificationSettings.action.recipient.add': 'Empfänger hinzufügen',
  'notificationSettings.action.recipient.remove': 'Empfänger löschen',

  // common form errors
  'formValidation.error.email.required': 'E-Mail-Adresse darf nicht leer sein.',
  'formValidation.error.email.email': 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
  'formValidation.error.sms.sms': 'Bitte geben Sie eine gültige Telefonnummer ein. Die Telefonnummer beginnt mit "+" oder "00" und darf außer dem "+" keine Sonderzeichen enthalten.',
  'formValidation.error.password.matches': 'Das Passwort muss mindestens 8 Zeichen lang sein, sowie einen Großbuchstaben und ein Sonderzeichen enthalten.',
  'formValidation.error.password.required': 'Passwort darf nicht leer sein.',
  'formValidation.error.companyId.required': 'Kundennummer darf nicht leer sein.',
  'formValidation.error.terms.required': 'Bitte akzeptieren Sie die Datenschutzbestimmungen.',
  'formValidation.error.energyCosts.required': 'Energiekosten darf nicht leer sein.',

  // common form fields
  'form.field.email': 'E-Mail-Adresse',
  'form.field.firstName': 'Vorname',
  'form.field.title': 'Titel',
  'form.field.lastName': 'Nachname',
  'form.field.password': 'Passwort',
  'form.field.oldPassword': 'Aktuelles Passwort',
  'form.field.newPassword': 'Neues Passwort',
  'form.field.companyId': 'Kundennummer',
  'form.field.salutation': 'Anrede',
  'form.field.mobileNumber': 'Telefonnummer (mobil)',
  'form.field.phoneNumber': 'Telefonnummer',
  'form.field.street': 'Straße',
  'form.field.addressPostalCode': 'Postleitzahl',
  'form.field.addressCity': 'Ort',
  'form.field.country': 'Land',
  'form.field.language': 'Sprache',
  'form.field.homepage': 'Website',
  'form.field.faxNumber': 'Fax',
  'form.field.shredderId': 'Seriennummer',
  'form.field.companyName': 'Firmenname',
  'form.field.warranty': 'Garantie',
  'form.field.unitSystem': 'Einheitssystem',
  'form.field.terms': 'Ich (Kunde bzw. Ansprechpartner des Kunden) erkläre mich im Zuge der Nutzung von MyUNTHA mit der <a href="https://www.untha.com/de/datenschutz#aws" target="_blank"> Verarbeitung meiner Daten</a> und den gültigen <a href="https://www.untha.com/de/datenschutz" target="_blank">Datenschutzbestimmungen</a> einverstanden.',
  'form.field.timeFormat': 'Zeitformat',

  // UseShredderSub
  'useShredderSub.handshake.error': 'Verbindung zu Live-Daten konnte nicht hergestellt werden',
  'useShredderSub.subscription.error': 'Fehler bei Verbindung zu Live-Daten',

  // ShredderDataTile
  'shredderDataTile.body.count': `{count, plural,
    =0 {Keine Datensätze}
    one {1 Datensatz}
    other {{count} Datensätze}
  }`,

  // PasswordField
  'passwordField.hint.body': 'Das Passwort muss mindestens 8 Zeichen lang sein, sowie einen Großbuchstaben und ein Sonderzeichen enthalten.',
  'passwordField.action.password.show': 'Passwort anzeigen',
  'passwordField.action.password.hide': 'Passwort ausblenden',

  // SignUp
  'signUp.form.field.companyId.hint.body': 'Sie können die Kundennummer nicht finden? Bitte wenden Sie sich an +43 6244 7016 0 oder info@untha.com – wir helfen Ihnen gerne bei der Registrierung.',

  // SignIn
  'signIn.toast.spam': 'Anmeldung bereits im Gange.',

  // NotificationCard
  'notificationCard.title': `{count, plural,
    =0 {Keine Mitteilungen}
    one {1 Mitteilung}
    other {{count} Mitteilungen}
  }`,
  'notificationCard.body': 'für diesen Shredder',
  'notificationCard.action.show': 'Mitteilungen anzeigen',

  // PasswordSettings
  'passwordSettings.action.update': 'Passwort aktualisieren',
  'passwordSettings.update.success': 'Passwort aktualisiert',
  'passwordSettings.update.error': 'Falsches Passwort',

  // Announcer
  'announcer.action.read': 'Nicht mehr anzeigen',

  // Report Announcement
  'reportAnnouncement.title': 'Performance-Reports Tutorial',
  'reportAnnouncement.body': 'Mit Performance-Reports können Sie auf einfache Art und Weise eine Übersicht von Maschinendaten erstellen. Zusammengefasste Daten werden als PDF an eine angegebene E-Mail-Adresse versendet – entweder einmalig oder im wöchentlichen Intervall.',
  'reportAnnouncement.videoSrc.webm': '/videos/tutorials/untha_tutorial_report_de.webm',
  'reportAnnouncement.videoSrc.mp4': '/videos/tutorials/untha_tutorial_report_de.mp4',

  // Explorer Announcement
  'explorerAnnouncement.title': 'Data Explorer Tutorial',
  'explorerAnnouncement.body': 'Der Daten Explorer bietet ihnen die Möglichkeit verschiedene Betriebs- und Prozessdaten gegenüberzustellen und miteinander zu vergleichen.',
  'explorerAnnouncement.videoSrc.webm': '/videos/tutorials/untha_tutorial_data_explorer_de.webm',
  'explorerAnnouncement.videoSrc.mp4': '/videos/tutorials/untha_tutorial_data_explorer_de.mp4',

  // HistoryChart
  'historyChart.empty': 'Für diese Auswahl sind keine Daten verfügbar.',
  'historyChart.action.reselect': 'Neue Auswahl',
  'historyChart.error': 'Bei der Verarbeitung der Historiendaten ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder wenden Sie sich an den UNTHA-Support.',

  // Service Notifications
  'service.admin.createSubUser.success': 'Der Benutzer wurde erfolgreich angelegt.',
  'service.admin.createSubUser.error.duplicate': 'Der Benutzer exisitiert bereits!',
  'service.admin.createSubUser.error': 'Fehler beim Anlegen des Benutzers!',
  'service.admin.deleteSubUser.success': 'Der Benutzer wurde erfolgreich gelöscht.',
  'service.admin.deleteSubUser.error.permission': 'Admin-Benutzer darf nicht gelöscht werden!',
  'service.admin.deleteSubUser.error': 'Fehler beim Löschen des Benutzers!',
  'service.profile.update.success': 'Profil aktualisiert',
  'service.auth.resetPassword.success': 'Passwort erfolgreich zurückgesetzt.',
  'service.auth.resetPassword.error': 'Passwort zurücksetzen fehlgeschlagen!',
  'service.auth.newPassword.success': 'Passwort erfolgreich aktualisiert.',
  'service.auth.newPassword.error': 'Passwort aktualisieren fehlgeschlagen!',
  'service.notification.general.error': 'Beim Verarbeiten der Benachrichtigungen ist ein Fehler aufgetreten.',

  // Downloads
  'downloads.headline.fallback': 'Downloads',

  // aggregation types
  'aggregation.minutely': 'minütlich',
  'aggregation.secondly': 'sekündlich',
  'aggregation.hourly': 'stündlich',

  // Energy costs
  'energyCosts.currency.title': 'Währung',
  'energyCosts.pricePerkWh.title': 'Preis pro kWh',
  'energyCosts.action.update.success': 'Einstellungen für Energiekosten erfolgreich aktualisiert',

  // currencies
  'currencies.eur': 'Euro',
  'currencies.usd': 'US-Dollar',
  'currencies.gbp': 'Pfund Sterling',
  'currencies.jpy': 'Yen',

  // AdditionalEnergyMeasurement
  'additionalEnergyMeasurement.title.front': 'Energieverbrauch Zusatzaggregate',
  'additionalEnergyMeasurement.title.back': 'Energiekosten Zusatzaggregate',
  'additionalEnergyMeasurement.entries.HOR_ENERGY.label': 'Horizontalband',
  'additionalEnergyMeasurement.entries.HORCONVEYOR_ENERGY.label': 'Horizontalband',
  'additionalEnergyMeasurement.entries.HYDR_ENERGY.label': 'Hydraulik',
  'additionalEnergyMeasurement.entries.CHILLER_ENERGY.label': 'Wasserkühlung',
  'additionalEnergyMeasurement.entries.RISE_ENERGY.label': 'Steigband',
  'additionalEnergyMeasurement.entries.RAISECONVEYOR_ENERGY.label': 'Steigband',
  'additionalEnergyMeasurement.entries.MAGN_ENERGY.label': 'Magnetabscheider',
  'additionalEnergyMeasurement.entries.MAGCONVEYOR_ENERGY.label': 'Magnetabscheider',
  'additionalEnergyMeasurement.entries.HYDCOOL_ENERGY_ACTIVE.label': 'Hydraulikkühlung',

  // Animated Brand
  'animatedBrand.cta.label': 'zur Website',

  // EmptySearch
  'emptySearch.headline': 'Keine Suchergebnisse',

  // Tag
  'tag.new.text': 'jetzt neu',
  'tag.newAbbr.text': 'neu',
  'tag.customerView.text': 'Kundenansicht',

  // ShredderHero
  'shredderHero.cta.live.label': 'Live-Daten anzeigen',
  'shredderHero.cta.manual.label': 'Betriebsanleitung',

  // 404 Page
  '404.card.headline': '404 - NOT FOUND OR SHREDDED',
  '404.card.text': 'Leider wurde die gewünschte Seite von einem unserer Shredder erfasst und kann daher nicht gefunden werden.',

  // salutations
  'salutation.mr': 'Herr',
  'salutation.mrs': 'Frau',

  // User Details
  'userDetails.fallback.text': 'Lade Profil...',
  'userDetails.action.update': 'Daten aktualisieren',

  // User Table
  'userTable.dialog.delete.title': 'Benutzer Löschen',
  'userTable.dialog.delete.headline': 'Achtung',
  'userTable.dialog.delete.text': 'Der Benutzer "{firstName} {lastName}" wird unwiderruflich gelöscht.',

  // Contact Callout
  'contactCallout.headline': 'Haben Sie eine Frage?',
  'contactCallout.text': 'Gerne kümmere ich mich um ihr Anliegen.',
  'contactCallout.job': 'Kundenservice',

  // Notification Details
  'notificationDetails.title': 'Alarm-Details',
  'notificationDetails.reason': 'Mögliche Ursache',
  'notificationDetails.stepByStep': 'Schritt-für-Schritt Anleitung',

  // Create Report
  'createReport.headline': 'Neuer Performance-Report',
  'createReport.action.create': 'Neuer Report',

  // Main Menu
  'mainMenu.headline.slice.1': 'MyUNTHA',
  'mainMenu.headline.slice.2': 'Kundenportal',
  'mainMenu.salutation': 'Herzlich Willkommen',
  'mainMenu.action.pirate.logout': 'Kundenansicht verlassen',

  // Nav Items
  'navItem.admin.title': 'Administration',
  'navItem.admin.description': 'Verwaltung, Import, Export',
  'navItem.dashboard.title': 'MySHREDDER',
  'navItem.dashboard.description': 'Betriebs- & Prozessdaten in Echtzeit',
  'navItem.notifications.title': 'Mitteilungen',
  'navItem.notifications.description': 'Alarme & Ereignisse',
  'navItem.report.title': 'Report',
  'navItem.report.description': 'Erstellung und Verwaltung von Performance Reports',
  'navItem.explorer.title': 'Data Explorer',
  'navItem.explorer.description': 'Detaillierte Analyse aller Betriebs- und Prozessdaten',
  'navItem.profile.title': 'MyPROFIL',
  'navItem.profile.description': 'Benutzerdaten & Einstellungen',
  'navItem.faq.title': 'FAQ',
  'navItem.faq.description': 'Häufig gestellte Fragen',
  'navItem.dev.title': 'Debug Tools',
  'navItem.dev.description': 'Admin Debug Status Infos',

  // date stuff
  'date.day': 'Tag',
  'date.days': 'Tage',
  'date.week': 'Woche',
  'date.weeks': 'Wochen',
  'date.month': 'Monat',
  'date.months': 'Monate',

  // Units
  'units.label.celsius': 'Celsius',
  'units.label.fahrenheit': 'Fahrenheit',
  'units.label.voltage': 'Volt',
  'units.label.rpm': 'Umdrehungen pro Minute',
  'units.label.watt': 'Watt',
  'units.label.kilowatt': 'Kilowatt',
  'units.label.voltampere': 'Volt Ampere',
  'units.label.kva': 'Kilovolt Ampere',
  'units.label.microsiemensPerMeter': 'Mikrosiemens pro Meter',
  'units.label.fillLevel': 'Füllstand',
  'units.label.hours': 'Stunden',
  'units.label.newton': 'Newton',
  'units.label.torque': 'Drehmoment',
  'units.label.permittivity': 'Permittivität',
  'units.label.percentage': 'Prozent',
  'units.label.bar': 'Bar',
  'units.label.ampere': 'Ampere',
  'units.label.psi': 'Pfund pro Quadratzoll',
  'units.label.acceleration': 'Beschleunigung',
  'units.label.frequency': 'Hertz',
  'units.label.times': 'Mal',

  'units.symbol.celsius': '°C',
  'units.symbol.fahrenheit': '°F',
  'units.symbol.voltage': 'V',
  'units.symbol.rpm': 'rpm',
  'units.symbol.watt': 'W',
  'units.symbol.kilowatt': 'kW',
  'units.symbol.voltampere': 'VA',
  'units.symbol.kva': 'kVA',
  'units.symbol.kwh': 'kWh',
  'units.symbol.microsiemensPerMeter': 'µS/m',
  'units.symbol.percentage': '%',
  'units.symbol.hours': 'h',
  'units.symbol.newton': 'N',
  'units.symbol.torque': 'Nm',
  'units.symbol.bar': 'bar',
  'units.symbol.psi': 'psi',
  'units.symbol.count': 'x',
  'units.symbol.ampere': 'A',
  'units.symbol.acceleration': 'm/s^2',
  'units.symbol.frequency': 'Hz',
  'units.symbol.costsPerWeight': '€/t',

  // unitSystem
  'unitSystem.metricUnits': 'metrisch',
  'unitSystem.imperialUnits': 'angloamerikanisch',

  // datePicker
  'datePicker.presets.today': 'Heute',
  'datePicker.presets.yesterday': 'Gestern',
  'datePicker.presets.past3Days': 'Letzte 3 Tage',
  'datePicker.presets.past7Days': 'Letzte Woche',

  // timeFormat
  'timeFormat.24h': '24 Stunden',
  'timeFormat.12h': '12 Stunden',

  // ExplorerSupport
  'explorerSupportCard.headline': 'Achtung!',
  'explorerSupportCard.body': 'Leider ist diese Funktion auf diesem Gerät nicht verfügbar. Bitte wechseln Sie auf ein Gerät mit einer Größe von mindestens 768 Pixel.',

  // TimestampIndicator
  'timestampIndicator.live': 'Live-Daten',
  'timestampIndicator.recent': 'letzte Aktualisierung vor {timestamp}',

  'emptyData': 'Keine Daten vorhanden.',

  'pdfReport.header.label': 'Performance-Report',
  'pdfReport.header.machine': 'Maschine',
  'pdfReport.header.timerange': 'Zeitraum',
  'pdfReport.fieldSets.parameter': 'Parameter',
  'pdfReport.fieldSets.metric': 'Kennzahlen',
  'pdfReport.fieldSets.event': 'Events',
  'pdfReport.logBook.header': 'Logbuch',
  'pdfReport.stateOne.entryHeader': 'Status',
  'pdfReport.stateOne.states.active': 'Produktion',
  'pdfReport.stateOne.states.idle': 'Leerlauf',
  'pdfReport.stateOne.states.standby': 'Standby',
  'pdfReport.stateOne.states.rest': 'Stillstand',
  'pdfReport.stateOne.states.overload': 'Reversierung Überlastung',
  'pdfReport.stateOne.states.cleaning': 'Reversierung Rotorreinigung',
  'pdfReport.stateOne.states.defect': 'Störung Maschine',
  'pdfReport.stateOne.states.defectExternal': 'Störung Extern',
  'pdfReport.stateOne.states.maintenance': 'Wartung',
  'pdfReport.stateOne.states.offline': 'Offline',
  'pdfReport.stateOne.states.eco': 'ECO-Modus',
  'pdfReport.globalParams.materialDensity': 'Materialdichte (optional)',
  'pdfReport.globalParams.materialWeight': 'Verarbeitete Masse im angegebenen Zeitraum (optional)',
  'pdfReport.globalParams.energyCost': 'Energiekosten',
  'pdfReport.globalParams.throughput': 'Durchsatz',
  'pdfReport.globalParams.throughputPerDensity': 'Durchsatz',
  'pdfReport.globalParams.energyConsumption': 'Energieverbrauch',
  'pdfReport.globalParams.energyCosts': 'Energiekosten',
  'pdfReport.globalParams.energyPerWeight': 'Energie pro Tonne',
  'pdfReport.globalParams.energyCostsPerWeight': 'Kosten pro Tonne',
  'pdfReport.globalParams.revUnplanned': 'Reversierungen durch Überlastung',
  'pdfReport.globalParams.clutchSlip1': 'Kupplungsrutschen 1',
  'pdfReport.globalParams.clutchSlip2': 'Kupplungsrutschen 2',
  'pdfReport.globalParams.operatingHours': 'Betriebsstunden (am Ende des Betrachtungszeitraums)',
  'pdfReport.globalParams.disruptiveOffActive': 'Störstoffabschaltungen',
  'pdfReport.measurements.averageHeader': 'Mittelwert',
  'pdfReport.measurements.avg': 'Mittelwert (absolut)',
  'pdfReport.measurements.max': 'Maximum',
  'pdfReport.measurements.min': 'Minimum',
  'pdfReport.measurements.consumption': 'Verbrauch',
  'pdfReport.measurements.noData': 'Für Ihren ausgewählten Zeitraum sind keine Daten verfügbar.',
  'pdfReport.measurements.locked': "Dieser Wert ist ab unserem Paket “GENIUS Advanced” verfügbar'.'",
  'pdfReport.unitsMetric.microsiemensPerMeter': 'µS/m',
  'pdfReport.unitsMetric.pressure': 'bar',
  'pdfReport.unitsMetric.torque': 'Nm',
  'pdfReport.unitsMetric.currency': 'EUR',
  'pdfReport.unitsMetric.density': 'kg/m³',
  'pdfReport.unitsMetric.weightTons': 't',
  'pdfReport.unitsMetric.energyCosts': '€/kWh',
  'pdfReport.unitsMetric.tonsPerHour': 't/h',
  'pdfReport.unitsMetric.cubicMeterPerHour': 'm³/h',
  'pdfReport.unitsMetric.energyPerWeight': 'kWh/t',
  'pdfReport.unitsMetric.costsPerWeight': '€/t',
  'pdfReport.unitsImperial.pressure': 'psi',
  'pdfReport.unitsImperial.currency': 'USD',
  'pdfReport.unitsImperial.energyCosts': '$/kWh',
  'pdfReport.unitsImperial.costsPerWeight': '$/t',
  'pdfReport.measurements.MOTORTEMP_SENS.label': 'Motortemperatur',
};

export default messages;
