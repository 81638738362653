import React from 'react';
import { Icon } from '@untha/ui';
import type { IconProps } from '@untha/ui';
import { ReactComponent as CircleNotchIcon } from 'src/icons/circle-notch.svg';
import { css, keyframes } from '@emotion/react';
import { useTransition, a } from 'react-spring';

type SpinnerProps = {
  /**
   * Show/Hide the spinner
   */
  isLoading: boolean;
  /**
   * Threshold in milliseconds until spinner becomes visible.
   * Prevents unnecessary content flashes.
   */
  busyDelayMs: number;
  /**
   * The duriation
   */
  busyMinDurationMs: number;
  /**
   * Optional class
   */
  className?: string;
};

const spin = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const defaultProps = {
  busyDelayMs: 0,
  busyMinDurationMs: 0,
};

export const Spinner = ({
  busyDelayMs,
  busyMinDurationMs,
  isLoading,
  className,
  ...rest
}: SpinnerProps & IconProps & typeof defaultProps): React.ReactElement => {
  const revealTimeMsRef = React.useRef<number>();
  const [show, setShow] = React.useState(false);
  const transition = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  React.useEffect(() => {
    let t: number;

    if (isLoading) {
      if (busyDelayMs === 0) {
        revealTimeMsRef.current = Date.now();
        setShow(true);
        return;
      }

      t = window.setTimeout(() => {
        revealTimeMsRef.current = Date.now();
        setShow(true);
      }, busyDelayMs);
    } else {
      const revealTimeMs = revealTimeMsRef.current;
      if (!revealTimeMs || busyMinDurationMs === 0) {
        setShow(false);
        return;
      }

      const timeSpent = Date.now() - revealTimeMs;

      t = window.setTimeout(() => {
        setShow(false);
      }, Math.max(0, busyMinDurationMs - timeSpent));
    }

    return () => {
      t && window.clearTimeout(t);
    };
  }, [isLoading, busyDelayMs, busyMinDurationMs]);

  return transition((style, item) => {
    return (
      item && (
        <a.span
          className={className}
          style={{ opacity: style.opacity.to((o) => o) as any }}
        >
          <Icon
            icon={CircleNotchIcon}
            css={css`
              animation: ${spin} 0.8s linear infinite;
            `}
            size="standard"
            {...rest}
          />
        </a.span>
      )
    );
  });
};

Spinner.defaultProps = defaultProps;
