export const amplifyAuthError = 'No current user';

export const portalAuthToken = 'PORTAL_AUTH_TOKEN';

export enum authLevel {
  BASIC = 0,
  ADMIN = 1,
  PIRATE = 2,
}

export enum accessRole {
  BASIC = 1,
  GENIUS = 2,
  ADMIN = 3,
}

export enum notificationSeverity {
  WARNING,
  MINOR,
  MAJOR,
  CRITICAL,
}

/**
 * Query map with query ids used for the react-query hook to proper
 * cache requests and also to prefetch data and write to the query-cache.
 */
export const queryKeys = {
  confirmSignUp: 'auth/confirmSignUp',
  // pirate
  getPirateCompanies: '/pirate/getCompanies',
  getPirateUserProfile: '/pirate/getUserProfile',
  // companies
  getCompanies: '/companies/getCompanies',
  // shredder
  getShredder: '/shredder/getShredder',
  getShredders: '/shredder/getShredders',
  getMeasurement: '/shredder/getMeasurement',
  getMeasurements: '/shredder/getMeasurements',
  getInitialMeasurements: '/shredder/getInitialMeasurements',
  getAggregation: '/shredder/getAggregation',
  // user
  getAccessToken: '/user/getAccessToken',
  getAuthToken: '/user/getAuthToken',
  getIotToken: '/user/getIotToken',
  getUser: '/user/getUser',
  getRoles: '/user/getRoles',
  getAuthId: '/user/getAuthId',
  // reports
  getReportHistory: '/reports/getHistory',
  getScheduledReports: '/reports/getReports',
  // events
  getEvents: '/events/getEvents',
  // alarms
  getAlarms: '/alarms/getAlarms',
  // admin
  getSubUsers: '/admin/getSubUsers',
  // explorer
  getPresets: '/explorer/getPresets',
  getPreset: '/explorer/getPreset',
  getStateOneAggregation: '/explorer/measurement/stateOne',
  // notifications
  getNotifications: '/notifications/getNotifications',
  getNotification: '/notifications/getNotification',
  getUnreadNotifications: '/notifications/getUnreadNotifications',
  // smartrules
  getSmartrules: '/smartrules/getSmartrules',
  getSmartrule: '/smartrules/getSmartrule',
  // content
  getBasicPage: '/content/getBasicPage',
  getNavigation: '/content/getNavigation',
  // announcements (idb)
  getAnnouncement: '/announcements/getAnnouncement',
  getAnnouncementReadOnly: '/announcements/getAnnouncementReadOnly',
  // pdf report
  getPdfReport: '/pdf-report',
};

export const defaultExplorePresetId = 'explore';

export const authChallenges = {
  resetPassword: 'NEW_PASSWORD_REQUIRED',
} as const;

export const DEFAULT_TIMELINE_PAGE_SIZE = 10;

export const DEFAULT_LOCALE = 'en';

export const MAX_SCHEDULED_REPORTS = 15;
