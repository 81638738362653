import React from 'react';
import { createContainer } from 'unstated-next';
import { isBrowser, setGlobalCssVariable } from '@untha/utils';

const lockedBaseStyles: React.CSSProperties = {
  position: 'fixed',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
};

const useBodyLock = () => {
  const [rootEl] = React.useState(() => isBrowser && document.documentElement);
  const [scrollBarWidth] = React.useState(
    () => isBrowser && rootEl && window.innerWidth - rootEl.clientWidth,
  );
  const [scrollY, setScrollY] = React.useState(0);
  const [locked, setLocked] = React.useState<null | boolean>(null);

  const lock = (): void => {
    setScrollY(window.pageYOffset);
    setLocked(true);
  };

  const unlock = (): void => {
    setLocked(false);
  };

  React.useEffect(() => {
    if (!rootEl) return;

    if (locked === true) {
      Object.entries(lockedBaseStyles).forEach((css) => {
        rootEl.style.setProperty(...css);
      });
      // we need to set the scroll offset as top style
      // to persist the scroll position
      rootEl.style.top = `${scrollY * -1}px`;
    }

    if (locked === false) {
      // clear lock props
      Object.keys(lockedBaseStyles).forEach((cssAttr) => {
        rootEl.style.setProperty(cssAttr, null);
      });

      // restore scroll position
      rootEl.style.setProperty('top', null);
      window.scrollTo(0, scrollY);
    }
  }, [locked, rootEl, scrollY]);

  // set simulated scrollbar-width
  React.useEffect(() => {
    if (locked) {
      setGlobalCssVariable('--scrollbar-simulated-width', `${scrollBarWidth}px`);
    } else {
      setGlobalCssVariable('--scrollbar-simulated-width', String(0));
    }
  }, [rootEl, locked, scrollBarWidth]);

  return [locked, { lock, unlock }] as const;
};

export const BodyLock = createContainer(useBodyLock);
