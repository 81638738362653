// prettier-ignore
const messages = {
  // project meta
  'project.title': 'MyUNTHA',
  'project.brand': 'UNTHA',
  'project.brandPhrase': 'shredding technology',
  'project.brandSub': 'Your personal customer portal',

  // locales
  'locale.en': 'English',
  'locale.de': 'German',
  'locale.es': 'Spanish',
  'locale.fr': 'French',
  'locale.it': 'Italian',

  // pages
  'page.dashboard.breadcrumb': 'Dashboard',
  'page.report.breadcrumb': 'Report',
  'page.report.title': 'Performance Reports',
  'page.report.history': 'Recent Performance Reports',
  'page.report.scheduled': 'Automated Performance-Reports',
  'page.admin.breadcrumb': 'Administration',
  'page.admin.section.management': 'Administration',
  'page.admin.section.data': 'database',
  'page.admin.companyTable.headline': 'Customer',
  'page.admin.csvImport.headline': 'Import',
  'page.admin.csvExport.headline': 'Export',
  'page.shredder.section.overview': 'Overview',
  'page.shredder.section.records': 'records',
  'page.shredder.downloads': 'Downloads',
  'page.explorer.presets.placeholder': 'No favorites created yet.',
  'page.explorer.presets.action.load': 'Load favorite',
  'page.explorer.presets.title': 'Favorites',
  'page.explorer.presets.input.placeholder': 'Favorite title',
  'page.explorer.manage.breadcrumb': 'Data points',
  'page.explorer.manage.search.placeholder': 'Search data points...',
  'page.explorer.manage.search.empty': 'No results found',
  'page.explorer.manage.limit': 'You reached the maximum number of data points.',
  'page.explorer.intro.section.favorites': 'Favorites',
  'page.notifications.title': 'Notifications',
  'page.notifications.breadcrumb': 'Notifications',
  'page.notifications.section.notifications': 'Recent Alarms',
  'page.notifications.section.notifications.alarms': 'Alarms',
  'page.notifications.section.notifications.events': 'Events',
  'page.notifications.section.settings': 'Settings',
  'page.notifications.section.settings.notifications': 'Notifications',
  'page.registrationConfirmation.section.success.headline': 'Registration completed',
  'page.registrationConfirmation.section.success.body': 'Your registration process is complete. You can now log in.',
  'page.registrationConfirmation.section.error.headline': 'Registration failed',
  'page.registrationConfirmation.section.error.body': 'Your registration process failed. Please contact your company admin.',
  'page.login.headline': 'Register',
  'page.login.body': 'No account yet?',
  'page.login.forgotPassword': 'Forgot your password?',
  'page.register.headline': 'Register',
  'page.register.body': 'You are a UNTHA customer and do not have access to the MyUNTHA portal yet? Register here.',
  'page.register.back': 'Already registered?',
  'page.register.toast.success': 'Registration successful',
  'page.register.toast.error': 'An error occurred during registration. Please try again later or contact UNTHA support.',
  'page.forgotPassword.headline': 'Reset password',
  'page.forgotPassword.body': 'Please enter your email and customer number and we will send you a temporary password. When you log in for the first time, you can then enter a new password.',
  'page.forgotPassword.back': 'Back to login',
  'page.newPassword.headline': 'Update password',
  'page.newPassword.body': 'Choose a new password for your login.',
  'page.profile.breadcrumb': 'MyPROFILE',
  'page.profile.section.user': 'Personal Data',
  'page.profile.section.user.profile': 'Your data',
  'page.profile.section.user.company': 'Your company',
  'page.profile.section.settings': 'Settings',
  'page.profile.section.settings.ui': 'User interface',
  'page.profile.section.settings.password': 'Password',
  'page.profile.section.settings.energyCosts': 'Energy costs',
  'page.profile.section.admin': 'Administration',
  'page.profile.section.admin.subUser': 'User accounts',
  'page.profile.section.admin.subUser.info': 'In this area you can manage existing users or create additional users. To create an additional user, the email address, first name and last name must be entered. After the account has been created, an email is sent to the user, who can then register and log in.',
  'page.shredders.empty': 'No shredders available yet',
  'page.shredders.section.shredder': `{count, plural,
    one {Your Shredder}
    other {Your Shredders}
  }`,

  // Table
  'table.th.period': 'Time period',
  'table.th.reportType': 'Type',
  'table.th.file': 'File',
  'table.th.shredder': 'Shredder',
  'table.th.email': 'Email',
  'table.th.id': 'ID',
  'table.th.company': 'Company name',
  'table.th.status': 'Status',
  'table.th.number': 'Nr.',
  'table.th.alarm': 'Alarm',
  'table.th.priority': 'Priority',
  'table.th.sms': 'SMS',
  'table.th.firstName': 'First name',
  'table.th.lastName': 'Last name',

  // ReportHistory
  'reportHistory.type.scheduled': 'automatically',
  'reportHistory.type.single': 'unique',
  'reportHistory.status.error': 'Error',
  'reportHistory.status.pending': 'in progress',
  'reportHistory.noResults': 'No past reports',
  'reportHistory.modal.title': 'New performance report',

  // ReportForm
  'reportForm.type.single.title': 'One-time report',
  'reportForm.type.single.description': 'You will receive the result of the performance report once by email. Please note that generating the report can take up to 10 minutes.',
  'reportForm.type.scheduled.title': 'Automated report.',
  'reportForm.type.scheduled.description': 'Each selected day or period is noted in the performance report. The report is sent on the day after the last selected time.',
  'reportForm.shredder.title': 'Shredder',
  'reportForm.shredder.select.placeholder': 'Select shredder',
  'reportForm.shredder.description': 'Select the shredder for which the performance report is to be created.',
  'reportForm.period.title': 'Time period',
  'reportForm.period.description': 'Performance reports can cover a period of up to 8 hours. The reports are possible 4 months into the past.',
  'reportForm.period.error.period': 'Period must be smaller than 8 hours',
  'reportForm.period.error.periodInvalid': 'Please select a valid period.',
  'reportForm.apiError': 'There was an error creating the report.',
  'reportForm.materialDensity.title': 'Material density',
  'reportForm.materialDensity.description': 'The material density is used to calculate the throughput.',
  'reportForm.materialWeight.title': 'Weight',
  'reportForm.materialWeight.description': 'The weight is used to calculate the basic key figures.',
  'reportForm.energyCosts.title': 'Energy costs',
  'reportForm.energyCosts.description': 'Energy costs are used to calculate total costs and costs per tonne.',
  'reportForm.technical.title': 'Display',
  'reportForm.technical.description': 'Characteristic lines are only shown if the selected period is less than 8 hours.',
  'reportForm.technical.checkbox.label': 'Show characteristic lines',

  // ScheduledReports
  'scheduledReports.modal.title': 'Edit Performance Report',
  'scheduledReports.noResults': 'No automated reports.',
  'scheduledReports.limit': '{count} from {limit} automated reports',

  // CompanyTable
  'companyTable.action.pirate': 'Customer view',
  'companyTable.deleteDialog.title': 'Deactivate customer',
  'companyTable.deleteDialog.headline': 'Caution',
  'companyTable.deleteDialog.body': 'By deactivating a client, all users assigned to it can no longer log in to the Portal.',
  'companyTable.deleteDialog.confirmation': 'Do you really want to continue?',
  'companyTable.deleteDialog.notify': 'Send email about deactivation to all users of the client',
  'companyTable.search.input.placeholder': 'Search customer...',
  'companyTable.toast.impersonate.active.success': 'Customer view activated',
  'companyTable.toast.impersonate.active.error': 'Error with customer view activation',
  'companyTable.toast.impersonate.disabled.success': 'Customer view deactivated',

  // CsvImport
  'csvImport.step.companies': 'Companys',
  'csvImport.step.users': 'User',
  'csvImport.step.shredders': 'Shredder',
  'csvImport.step.shredderAttributes': 'Features',
  'csvImport.step.salesPersons': 'Seller',
  'csvImport.dropArea.headline': 'Drag CSV file here',
  'csvImport.dropArea.body': 'or',
  'csvImport.dropArea.cta': 'Select CSV file',
  'csvImport.warning': 'Warning: importing a new file may replace data in the database.',
  'csvImport.action.upload.success': '{fileName} successfully uploaded.',
  'csvImport.action.upload.error': 'An error occurred during the data update. Please try again at a later time or contact UNTHA support.',
  'csvImport.action.complete.success': 'All files are successfully uploaded',

  // CsvExport
  'csvExport.data.companies': 'Companys',
  'csvExport.data.users': 'User',
  'csvExport.data.shredders': 'Shredder',
  'csvExport.data.shredderAttributes': 'Features',
  'csvExport.data.salesPersons': 'Seller',

  // ExplorerKickoffCard
  'explorerKickoffCard.headline': 'Data points',
  'explorerKickoffCard.body': 'The Data Explorer can be used to compare different data points or process values of a shredder. First select the data points you want to compare.',
  'explorerKickoffCard.action.choose': 'Data points',

  // ExplorerPreset
  'explorerPreset.body': `{count, plural,
    =0 {No data points}
    one {1 data point}
    other {{count} data points}
  }`,

  // ExplorerLayout
  'explorerLayout.breadcrumb': 'Data Explorer',
  'explorerLayout.aggregation.title': 'Aggregation',
  'explorerLayout.action.data': 'Data points',
  'explorerLayout.action.favorites': 'Favorites',
  'explorerLayout.action.realtime': 'Realtime',

  // ExplorerEntry
  'explorerEntry.controls.filter.switch.costs': 'Display energy costs',
  'explorerEntry.controls.filter.switch.activePower': 'Display apparent power',
  'explorerEntry.emptySearch.text': 'No data available for your selected period.',

  // ExplorerZoom
  'explorerZoom.action.reset': 'Reset zoom',

  // CreatePreset
  'createPreset.title.create': 'Save current configuration as favourite',
  'createPreset.title.update': 'Update current favourite',

  // common actions
  'action.edit': 'Edit',
  'action.delete': 'Delete',
  'action.cancel': 'Cancel',
  'action.create': 'Create',
  'action.save': 'Save',
  'action.saveChanges': 'Save changes',
  'action.disable': 'Deactivate',
  'action.enable': 'Activate',
  'action.revert': 'Restore',
  'action.download': 'Download',
  'action.upload': 'Upload',
  'action.update': 'Update',
  'action.close': 'Close',
  'action.login': 'Login',
  'action.register': 'Register',
  'action.reset': 'Reset',
  'action.add': 'Add',
  'action.logout': 'Sign Out',

  // common states
  'state.enabled': 'active',
  'state.disabled': 'inactive',

  // ShredderStatus
  'shredderStatus.title': 'Status',
  'shredderStatus.since': 'since',
  'shredderStatus.status.active.label': '<strong>Production</strong>',
  'shredderStatus.status.idle.label': '<strong>Idle mode</strong>',
  'shredderStatus.status.rest.label': '<strong>Standstill</strong>',
  'shredderStatus.status.overload.label': '<strong>Reversing</strong> overload',
  'shredderStatus.status.cleaning.label': '<strong>Reversing</strong> Rotor cleaning',
  'shredderStatus.status.defect.label': '<strong>Malfunction</strong> machine',
  'shredderStatus.status.defectExternal.label': '<strong>Malfunction</strong> extern',
  'shredderStatus.status.maintenance.label': '<strong>Maintenance</strong>',
  'shredderStatus.status.standby.label': '<strong>Standby</strong>',
  'shredderStatus.status.offline.label': '<strong>Offline</strong>',
  'shredderStatus.status.eco.label': '<strong>ECO mode</strong>',

  // StateOneMeasurement
  'stateOneMeasurement.preset.last24Hours': 'Last 24 hours',
  'stateOneMeasurement.preset.prev7Days': 'Last 7 days',
  'stateOneMeasurement.preset.prev30Days': 'Last 30 days',
  'stateOneMeasurement.state.active.label': 'Active',
  'stateOneMeasurement.state.idle.label': 'Idle mode',
  'stateOneMeasurement.state.standby.label': 'Standby',
  'stateOneMeasurement.state.rest.label': 'Standstill',
  'stateOneMeasurement.state.overload.label': 'Reversal overload',
  'stateOneMeasurement.state.cleaning.label': 'Reversing rotor cleaning',
  'stateOneMeasurement.state.defect.label': 'Machine malfunction',
  'stateOneMeasurement.state.defectExternal.label': 'Malfunction external',
  'stateOneMeasurement.state.maintenance.label': 'Maintenance',
  'stateOneMeasurement.state.offline.label': 'Offline',
  'stateOneMeasurement.state.eco.label': 'ECO mode',
  'stateOneMeasurement.error': 'An error occurred while processing the status data. Please try again later or contact UNTHA support.',

  // notification severity
  'notificationSeverity.0': 'Warning',
  'notificationSeverity.1': 'Minor',
  'notificationSeverity.2': 'Major',
  'notificationSeverity.3': 'Critical',

  // notification acknowledged alarms
  'notificationSeverity.acknowledgedAlarms': 'Acknowledged alarms',

  // UiSettings
  'uiSettings.update.success': 'User interface successfully updated',

  // NotificationTable
  'notificationTable.search.input.placeholder': 'Search alarm...',

  // Copyright
  'copyright.label': '© {year} by UNTHA shredding technology GmbH',

  // NotificationSettings
  'notificationSettings.headline': 'Notifications',
  'notificationSettings.text': 'Get notified of this alarm when it occurs again.',
  'notificationSettings.type.email': 'Email notifications',
  'notificationSettings.type.sms': 'SMS notifications',
  'notificationSettings.template.subject': '{priority}: Alarm at Shredder {shredder}',
  'notificationSettings.template.text': 'The following alarm has just been triggered at the {shredder}: "{title}" | {priority}',
  'notificationSettings.toast.sms.update.success': 'SMS notification updated',
  'notificationSettings.toast.email.update.success': 'Email notification updated',
  'notificationSettings.toast.sms.delete.success': 'SMS notification deleted',
  'notificationSettings.toast.email.delete.success': 'Email notification deleted',
  'notificationSettings.toast.sms.create.success': 'SMS notification created',
  'notificationSettings.toast.email.create.success': 'Email notification created',
  'notificationSettings.action.recipient.add': 'Add recipient',
  'notificationSettings.action.recipient.remove': 'Remove recipient',

  // common form errors
  'formValidation.error.email.required': 'Email must not be empty.',
  'formValidation.error.email.email': 'Please enter a valid email.',
  'formValidation.error.sms.sms': 'Please enter a valid telephone number. The telephone number begins with "+" or "00" and must not contain any special characters other than the "+".',
  'formValidation.error.password.matches': 'Please enter a password with atleast 8 characters and an uppercase letter, a number and a special character.',
  'formValidation.error.password.required': 'Password can not be empty.',
  'formValidation.error.companyId.required': 'Customer number can not be empty.',
  'formValidation.error.terms.required': 'Please accept the privacy policy.',
  'formValidation.error.energyCosts.required': 'Energy costs can not be empty.',

  // common form fields
  'form.field.email': 'Email',
  'form.field.password': 'Password',
  'form.field.oldPassword': 'Current password',
  'form.field.newPassword': 'New password',
  'form.field.companyId': 'Customer number',
  'form.field.salutation': 'Salutation',
  'form.field.terms': 'I (customer or customer contact person) agree to the <a href="https://www.untha.com/en/privacy-statement#aws" target="_blank" rel="noopener">processing of my data</a> and the <a href="https://www.untha.com/en/privacy-statement" target="_blank" rel="noopener">data protection regulations</a>.',
  'form.field.mobileNumber': 'Phone (mobile)',
  'form.field.phoneNumber': 'Phone',
  'form.field.street': 'Street',
  'form.field.addressPostalCode': 'Postal Code',
  'form.field.addressCity': 'City',
  'form.field.country': 'Country',
  'form.field.language': 'Language',
  'form.field.homepage': 'Website',
  'form.field.faxNumber': 'Fax',
  'form.field.companyName': 'Company',
  'form.field.firstName': 'First name',
  'form.field.title': 'Title',
  'form.field.lastName': 'Last name',
  'form.field.shredderId': 'Serial number',
  'form.field.warranty': 'Warranty',
  'form.field.unitSystem': 'Unit System',
  'form.field.timeFormat': 'Time format',

  // UseShredderSub
  'useShredderSub.handshake.error': 'Could not connect to live data',
  'useShredderSub.subscription.error': 'Error when connecting to live data',

  // ShredderDataTile
  'shredderDataTile.body.count': `{count, plural,
    =0 {No data points}
    one {1 data point}
    other {{count} data points}
  }`,

  // PasswordField
  'passwordField.hint.body': 'Please enter a password with atleast 8 characters and an uppercase letter, a number and a special character.',
  'passwordField.action.password.show': 'Show password',
  'passwordField.action.password.hide': 'Hide password',

  // SignUp
  'signUp.form.field.companyId.hint.body': 'You can not find your customer number? Please contact +43 6244 7016 0 or info@untha.com - we will be more than happy to help you with the registration.',

  // SignIn
  'signIn.toast.spam': 'Registration in prograss',

  // NotificationCard
  'notificationCard.title': `{count, plural,
    =0 {No messages}
    one {1 message}
    other {{count} messages}
  }`,
  'notificationCard.body': 'for this shredder',
  'notificationCard.action.show': 'Display messages',

  // PasswordSettings
  'passwordSettings.action.update': 'Update password',
  'passwordSettings.update.success': 'Password is updated',
  'passwordSettings.update.error': 'Wrong password',

  // Announcer
  'announcer.action.read': 'Don\'t show again',

  // Report Announcement
  'reportAnnouncement.title': 'Performance-reports Tutorial',
  'reportAnnouncement.body': 'With Performance Reports, you can easily create an overview of your machine data. Summarised data is sent as a PDF to a specified email address - either once or at weekly intervals.',
  'reportAnnouncement.videoSrc.webm': '/videos/tutorials/untha_tutorial_report_en.webm',
  'reportAnnouncement.videoSrc.mp4': '/videos/tutorials/untha_tutorial_report_en.mp4',

  // Explorer Announcement
  'explorerAnnouncement.title': 'Data Explorer Tutorial',
  'explorerAnnouncement.body': 'The Data Explorer offers the possibility of comparing and contrasting different operating and process data.',
  'explorerAnnouncement.videoSrc.webm': '/videos/tutorials/untha_tutorial_data_explorer_en.webm',
  'explorerAnnouncement.videoSrc.mp4': '/videos/tutorials/untha_tutorial_data_explorer_en.mp4',

  // HistoryChart
  'historyChart.empty': 'No data is available for this selection.',
  'historyChart.action.reselect': 'New selection',
  'historyChart.error': 'An error occurred while processing the history data. Please try again later or contact UNTHA support.',

  // Service Notifications
  'service.admin.createSubUser.success': 'The user was successfully created.',
  'service.admin.createSubUser.error.duplicate': 'The user already exists!',
  'service.admin.createSubUser.error': 'Error when creating the user!',
  'service.admin.deleteSubUser.success': 'The user was successfully deleted.',
  'service.admin.deleteSubUser.error.permission': 'Admin user can not be deleted!',
  'service.admin.deleteSubUser.error': 'Error deleting the user!',
  'service.profile.update.success': 'Profile updated successfully',
  'service.auth.resetPassword.success': 'Password reset successfully',
  'service.auth.resetPassword.error': 'Password reset failed!',
  'service.auth.newPassword.success': 'Password updated successfully.',
  'service.auth.newPassword.error': 'Password update failed!',
  'service.notification.general.error': 'Error processing the notifications',

  // Downloads
  'downloads.headline.fallback': 'Downloads',

  // shredder attributes
  'shredderAttr.ENERGY_SUPPLY.label': 'Energy supply',
  'shredderAttr.HOR_POWER_ACTIVE.label': 'Horizontal conveyor active power',
  'shredderAttr.HORCONVEYOR_POWER_ACTIVE.label': 'Horizontal conveyor active power',
  'shredderAttr.ENERGY_SUPPLY_COSTS.label': 'Energy Costs',
  'shredderAttr.CHILLER_POWER_APPARENT.label': 'Water cooling apparent power',
  'shredderAttr.RISE_POWER_ACTIVE.label': 'Elevating conveyor active power',
  'shredderAttr.RAISECONVEYOR_POWER_ACTIVE.label': 'Elevating conveyor active power',
  'shredderAttr.RISE_ENERGY.label': 'Elevating conveyor power consumption',
  'shredderAttr.RAISECONVEYOR_ENERGY.label': 'Elevating conveyor power consumption',
  'shredderAttr.OIL_LIFETIME_HYDR.label': 'Hydraulic oil remaining life',
  'shredderAttr.MAGN_ENERGY_COSTS.label': 'Overbelt magnet energy costs',
  'shredderAttr.MAGCONVEYOR_ENERGY_COSTS.label': 'Overbelt magnet energy costs',
  'shredderAttr.HEATPLATE_TEMP2.label': 'Heat sink temperature 2',
  'shredderAttr.HEATPLATE_TEMP1.label': 'Heat sink temperature 1',
  'shredderAttr.MAGN_POWER_ACTIVE.label': 'Overbelt magnet active power',
  'shredderAttr.MAGCONVEYOR_POWER_ACTIVE.label': 'Overbelt magnet active power',
  'shredderAttr.OIL_CONDUCTIVITY_GEAR2.label': 'Gear oil conductivity 2',
  'shredderAttr.OIL_CONDUCTIVITY_GEAR1.label': 'Gear oil conductivity 1',
  'shredderAttr.GEAR_REVOLUTION1.label': 'Gear 1',
  'shredderAttr.GEAR_REVOLUTION2.label': 'Gear 2',
  'shredderAttr.RISE_ENERGY_COSTS.label': 'Elevating conveyor energy costs',
  'shredderAttr.RAISECONVEYOR_ENERGY_COSTS.label': 'Elevating conveyor energy costs',
  'shredderAttr.OIL_PERMITIVITY_GEAR1.label': 'Hydraulic oil permittivity 1',
  'shredderAttr.OIL_PERMITIVITY_GEAR2.label': 'Hydraulic oil permittivity 2',
  'shredderAttr.OIL_CONDUCTIVITY_HYDR.label': 'Hydraulic oil conductivity',
  'shredderAttr.POWER_APPARENT_ACT2.label': 'Apparent power motor 2',
  'shredderAttr.ROTOR_REVOLUTION.label': 'Rotor speed',
  'shredderAttr.ROTOR_REVOLUTION1.label': 'Rotor speed 1',
  'shredderAttr.ROTOR_REVOLUTION2.label': 'Rotor speed 2',
  'shredderAttr.OIL_TEMPERATURE_HYDR.label': 'Hydraulic oil temperature',
  'shredderAttr.POWER_APPARENT_ACT1.label': 'Apparent power motor 1',
  'shredderAttr.COOLING_TEMPIN.label': 'Water cooling return temperature',
  'shredderAttr.TORQUE_ROT.label': 'Rotor torque',
  'shredderAttr.TORQUE_ROT1.label': 'Rotor torque 1',
  'shredderAttr.TORQUE_ROT2.label': 'Rotor torque 2',
  'shredderAttr.MOTORTEMP_2_SENS.label': 'Motor temperature 2',
  'shredderAttr.OIL_PERMITIVITY_HYDR.label': 'Hydraulic oil permittivity',
  'shredderAttr.CHILLER_PB4.label': 'Room temperature',
  'shredderAttr.CHILLER_POWER_ACTIVE.label': 'Water cooling active power',
  'shredderAttr.CHILLER_PB3.label': 'Condenser pressure',
  'shredderAttr.CHILLER_PB2.label': 'Cooling flow temperature',
  'shredderAttr.UEFF_L23.label': 'Phase voltage U23',
  'shredderAttr.VOLTAGE_U23.label': 'Phase voltage U23',
  'shredderAttr.CHILLER_PB1.label': 'Evaporator temperature',
  'shredderAttr.OIL_TEMPERATURE_GEAR1.label': 'Gear oil temperature 1',
  'shredderAttr.OIL_TEMPERATURE_GEAR2.label': 'Gear oil temperature 2',
  'shredderAttr.CHILLER_ENERGY_COSTS.label': 'Water cooling energy costs',
  'shredderAttr.HOR_ENERGY_COSTS.label': 'Horizontal conveyor energy costs',
  'shredderAttr.HORCONVEYOR_ENERGY_COSTS.label': 'Horizontal conveyor energy costs',
  'shredderAttr.REVOLUTION_ACT1.label': 'Motor speed 1',
  'shredderAttr.REVOLUTION_ACT2.label': 'Motor speed 2',
  'shredderAttr.HYDR_POWER_APPARENT.label': 'Hydraulic apparent power',
  'shredderAttr.TORQUE1.label': 'Torque 1',
  'shredderAttr.DEVICE_TEMP2.label': 'Inverter temperature 2',
  'shredderAttr.DEVICE_TEMP1.label': 'Inverter temperature 1',
  'shredderAttr.TORQUE2.label': 'Torque 2',
  'shredderAttr.MAGN_ENERGY.label': 'Overbelt magnet energy consumption',
  'shredderAttr.MAGCONVEYOR_ENERGY.label': 'Overbelt magnet energy consumption',
  'shredderAttr.OIL_LEVEL_HYDR.label': 'Hydraulic oil level',
  'shredderAttr.UEFF_L31.label': 'Phase voltage U31',
  'shredderAttr.VOLTAGE_U31.label': 'Phase voltage U31',
  'shredderAttr.POWER_ACTIVE.label': 'Power Active',
  'shredderAttr.GRID_THD.label': 'Grid THD',
  'shredderAttr.GRID_THD.description': 'Total Harmonic Distortion (THD) is a measurement for the quality of a power grid. According to EN50160, it may not exceed the limit value of 8 %. In detail, this figure describes the ratio of the harmonic components (up to the 40th harmonic) to the actual supply frequency.',
  'shredderAttr.OIL_LEVEL_GEAR1.label': 'Gear oil level 1',
  'shredderAttr.OIL_LEVEL_GEAR2.label': 'Gear oil level 2',
  'shredderAttr.VOLTAGE_OUT2.label': 'Output voltage 2',
  'shredderAttr.VOLTAGE_OUT1.label': 'Output voltage 1',
  'shredderAttr.POWER_ACTIVE_ACT2.label': 'Active power motor 2',
  'shredderAttr.POWER_ACTIVE_ACT1.label': 'Active power motor 1',
  'shredderAttr.OIL_AGE_GEAR1.label': 'Gear oil age 1',
  'shredderAttr.OIL_AGE_GEAR2.label': 'Gear oil age 2',
  'shredderAttr.HOR_ENERGY.label': 'Horizontal conveyor power consumption',
  'shredderAttr.HORCONVEYOR_ENERGY.label': 'Horizontal conveyor power consumption',
  'shredderAttr.CNT_REVERSING.label': 'Reversals counter',
  'shredderAttr.OIL_LIFETIME_GEAR1.label': 'Gear oil remaining life 1',
  'shredderAttr.OIL_LIFETIME_GEAR2.label': 'Gear oil remaining life 2',
  'shredderAttr.FUNNEL_LEVEL.label': 'Hopper Level',
  'shredderAttr.HYDR_ENERGY.label': 'Hydraulic power consumption',
  'shredderAttr.HYDR_ENERGY_COSTS.label': 'Hydraulic energy costs',
  'shredderAttr.COOLING_TEMPOUT.label': 'Water cooling flow temperature',
  'shredderAttr.OIL_HUMIDITY_HYDR.label': 'Hydraulic oil relative humidity',
  'shredderAttr.UEFF_L12.label': 'Phase voltage U12',
  'shredderAttr.VOLTAGE_U12.label': 'Phase voltage U12',
  'shredderAttr.MAGN_POWER_APPARENT.label': 'Overbelt magnet apparent power',
  'shredderAttr.MAGCONVEYOR_POWER_APPARENT.label': 'Overbelt magnet apparent power',
  'shredderAttr.CHILLER_ENERGY.label': 'Water cooling energy consumption',
  'shredderAttr.POWER_APPARENT.label': 'Apparent power',
  'shredderAttr.HYDR_POWER_ACTIVE.label': 'Active hydraulic power',
  'shredderAttr.CNT_SERVICEFLAP.label': 'Service hatch open',
  'shredderAttr.OPERATING_HOURS.label': 'Operating hours',
  'shredderAttr.MOTORTEMP_1_SENS.label': 'Motor temperature 1',
  'shredderAttr.PUSHER_FORCE.label': 'Cylinder force pusher',
  'shredderAttr.ROTOR_MECH_POWER.label': 'Mechanical power rotor',
  'shredderAttr.HOR_POWER_APPARENT.label': 'Horizontal conveyor apparent power',
  'shredderAttr.HORCONVEYOR_POWER_APPARENT.label': 'Horizontal conveyor apparent power',
  'shredderAttr.OIL_HUMIDITY_GEAR2.label': 'Gear oil relative humidity 2',
  'shredderAttr.OIL_HUMIDITY_GEAR1.label': 'Gear oil relative humidity 1',
  'shredderAttr.CNT_CLUTCHSLIP1.label': 'Clutch slip counter 1',
  'shredderAttr.CNT_CLUTCHSLIP2.label': 'Clutch slip counter 2',
  'shredderAttr.RISE_POWER_APPARENT.label': 'Elevating conveyor apparent power',
  'shredderAttr.RAISECONVEYOR_POWER_APPARENT.label': 'Elevating conveyor apparent power',
  'shredderAttr.OIL_AGE_HYDR.label': 'Hydraulic oil age',
  'shredderAttr.DC_CURRENT2.label': 'Intermediate circuit voltage 2',
  'shredderAttr.DC_CURRENT1.label': 'Intermediate circuit voltage 1',
  'shredderAttr.ACC_EFF_BEARING.label': 'Radiale Schwingung Effektivwert',
  'shredderAttr.HYDCOOL_ENERGY.label': 'Hydraulic cooling power consumption',
  'shredderAttr.HYDCOOL_ENERGY_COSTS.label': 'Hydraulic cooling energy costs',
  'shredderAttr.HYDCOOL_ENERGY_ACTIVE.label': 'Hydraulik Kühlung Wirkleistung',
  'shredderAttr.HYDCOOL_ENERGY_ACTIVE_COSTS.label': 'Hydraulik Kühlung Energieverbrauch',
  'shredderAttr.HYDCOOL_POWER_APPARENT.label': 'Hydraulik Kühlung Scheinleistung',
  'shredderAttr.ACTIVE_CURRENT1.label': 'Current 1',
  'shredderAttr.ACTIVE_CURRENT2.label': 'Current 2',
  'shredderAttr.GEARTEMP_1_SENS.label': 'Gear Temperature 1',
  'shredderAttr.GEARTEMP_2_SENS.label': 'Gear Temperature 2',
  'shredderAttr.ENGINE_MECH_POWER2.label': 'Mechanical power motor 2',
  'shredderAttr.ENGINE_MECH_POWER1.label': 'Mechanical power motor 1',
  'shredderAttr.GENERATOR_FUELLEVEL.label': 'Power unit fill level',
  'shredderAttr.GENERATOR_POWER.label': 'Power unit effective power',
  'shredderAttr.GENERATOR_VOLTAGE.label': 'Power unit voltage',
  'shredderAttr.GENERATOR_FREQUENCY.label': 'Power unit frequency',

  // shredder attribute clusters
  'shredderAttrCluster.engineTotalEnergyConsumption': 'Power consumption',
  'shredderAttrCluster.engineTotalPowerActive': 'Total machine power',
  'shredderAttrCluster.cntGearSkidding': 'Reversions counter',
  'shredderAttrCluster.cntServiceFlap': 'Open-service flap counter',
  'shredderAttrCluster.rotorSpeed': 'Rotor speed',
  'shredderAttrCluster.rotorTorque': 'Rotor torque',
  'shredderAttrCluster.rotorMechPower': 'Mechanical power rotor',
  'shredderAttrCluster.conductorVoltages': 'Phase voltages',
  'shredderAttrCluster.netQuality': 'Network quality THD',
  'shredderAttrCluster.engineTorque': 'Motor torques',
  'shredderAttrCluster.engineSpeed': 'Motor speeds',
  'shredderAttrCluster.engineTemp': 'Motor temperature',
  'shredderAttrCluster.cntClutchSkidding': 'Clutch slip counter',
  'shredderAttrCluster.gearSpeed': 'Gear speed',
  'shredderAttrCluster.outputVoltage': 'Output voltage',
  'shredderAttrCluster.inverterTemp': 'Invert temperature',
  'shredderAttrCluster.heatSinkTemp': 'Heat sink temperature',
  'shredderAttrCluster.intCircuitVoltage': 'Intermediate circuit voltage',
  'shredderAttrCluster.conveyorsEnergyConsumption': 'Power consumption of conveying equipment',
  'shredderAttrCluster.additionalEnergyConsumption': 'Power consumption of ancillary units',
  'shredderAttrCluster.conveyorsPowerActive': 'Performance of conveying equipment',
  'shredderAttrCluster.additionalPowerActive': 'Performance of ancillary units',
  'shredderAttrCluster.waterCoolingTemp': 'Water cooling temperatures',
  'shredderAttrCluster.oilLevel': 'Oil levels',
  'shredderAttrCluster.oilTemp': 'Oil temperatures',
  'shredderAttrCluster.radialVibration': 'Radial vibration rms value',
  'shredderAttrCluster.funnelLevel': 'Hopper level',
  'shredderAttrCluster.pusherForce': 'Cylinder force pusher',
  'shredderAttrCluster.activePower': 'Active current',
  'shredderAttrCluster.gearTemp': 'Gear temperatures',
  'shredderAttrCluster.gearOil1': 'Gear oil 1',
  'shredderAttrCluster.gearOil2': 'Gear oil 2',
  'shredderAttrCluster.counter': 'Counter',
  'shredderAttrCluster.hydrOil': 'Hydraulic oil',
  'shredderAttrCluster.enginePowerActive': 'Effective power of motors',
  'shredderAttrCluster.generatorFuellevel':'Power unit fill level',
  'shredderAttrCluster.generatorEffectivePower':'Power unit effective power',
  'shredderAttrCluster.generatorVoltage':'Power unit voltage',
  'shredderAttrCluster.generatorFrequency':'Power unit frequency',

  // shredder attribute topics
  'shredderAttrTopic.engineTotal': 'Total machine',
  'shredderAttrTopic.cuttingSystem': 'Cutting system',
  'shredderAttrTopic.energySupply': 'Energy supply',
  'shredderAttrTopic.engine': 'Motor',
  'shredderAttrTopic.gear': 'Gear unit',
  'shredderAttrTopic.frequencyConverter': 'Frequency converter',
  'shredderAttrTopic.transportSystem': 'Conveyor',
  'shredderAttrTopic.additional': 'Ancillary units',
  'shredderAttrTopic.operatingSupplies': 'Operating resources',
  'shredderAttrTopic.other': 'Other',
  'shredderAttrTopic.driveTrain': 'Drive Train',
  'shredderAttrTopic.powerTrain': 'Power Train',
  'shredderAttrTopic.hydraulic': 'Hydraulics',
  'shredderAttrTopic.waterCooling': 'Water cooling',
  'shredderAttrTopic.extras': 'Extras',
  'shredderAttrTopic.operatingData': 'Operating data',
  'shredderAttrTopic.pusher': 'Pusher',
  'shredderAttrTopic.rotor': 'Rotor',
  'shredderAttrTopic.clutch': 'Clutch',
  'shredderAttrTopic.coolingCircuit': 'Cooling circuit',
  'shredderAttrTopic.horizontalConveyor': 'Horizontal conveyor',
  'shredderAttrTopic.elevatingConveyor': 'Elevating conveyor',
  'shredderAttrTopic.magneticSeparator': 'Overbelt magnet',
  'shredderAttrTopic.rotorShaft': 'Rotor shaft',
  'shredderAttrTopic.slideCylinder': 'Slide cylinder',
  'shredderAttrTopic.oilPumpDrive': 'Oil pump drive',
  'shredderAttrTopic.oilPan': 'Oil pan',
  'shredderAttrTopic.pusherForce': 'Cylinder force pusher',
  'shredderAttrTopic.chiller': 'Chiller',
  'shredderAttrTopic.powerUnit': 'Power unit',

  // aggregation types
  'aggregation.minutely': 'minutely',
  'aggregation.secondly': 'every second',
  'aggregation.hourly': 'hourly',

  // Energy costs
  'energyCosts.currency.title': 'Currency',
  'energyCosts.pricePerkWh.title': 'Price pro kWh',
  'energyCosts.action.update.success': 'Settings for energy costs successfully updated.',

  // currencies
  'currencies.eur': 'Euro',
  'currencies.usd': 'US-Dollar',
  'currencies.gbp': 'Pound Sterling',
  'currencies.jpy': 'Yen',

  // AdditionalEnergyMeasurement
  'additionalEnergyMeasurement.title.front': 'Consumption additional unit',
  'additionalEnergyMeasurement.title.back': 'Energy costs additional unit',
  'additionalEnergyMeasurement.entries.HOR_ENERGY.label': 'Horizontal conveyor',
  'additionalEnergyMeasurement.entries.HORCONVEYOR_ENERGY.label': 'Horizontal conveyor',
  'additionalEnergyMeasurement.entries.HYDR_ENERGY.label': 'Hydraulic',
  'additionalEnergyMeasurement.entries.CHILLER_ENERGY.label': 'Water cooling',
  'additionalEnergyMeasurement.entries.RISE_ENERGY.label': 'Steigband',
  'additionalEnergyMeasurement.entries.RAISECONVEYOR_ENERGY.label': 'Steigband',
  'additionalEnergyMeasurement.entries.MAGN_ENERGY.label': 'Overbelt magnet',
  'additionalEnergyMeasurement.entries.MAGCONVEYOR_ENERGY.label': 'Overbelt magnet',
  'additionalEnergyMeasurement.entries.HYDCOOL_ENERGY_ACTIVE.label': 'Hydraulics cooling',

  // Animated Brand
  'animatedBrand.cta.label': 'go to website',

  // EmptySearch
  'emptySearch.headline': 'No search results',

  // Tag
  'tag.new.text': 'now new',
  'tag.newAbbr.text': 'new',
  'tag.customerView.text': 'Customer view',

  // ShredderHero
  'shredderHero.cta.live.label': 'Show Live Data',
  'shredderHero.cta.manual.label': 'Manual',

  // 404 Page
  '404.card.headline': '404 - NOT FOUND OR SHREDDED',
  '404.card.text': 'Sadly this page was caught by one of our shredders.',

  // salutations
  'salutation.mr': 'Mr.',
  'salutation.mrs': 'Mrs.',

  // User Details
  'userDetails.fallback.text': 'Loading Profile...',
  'userDetails.action.update': 'Update Profile',

  // User Table
  'userTable.dialog.delete.title': 'Delete user',
  'userTable.dialog.delete.headline': 'Warning',
  'userTable.dialog.delete.text': 'The user "{firstName} {lastName}" will be deleted irrevocably.',

  // Contact Callout
  'contactCallout.headline': 'Do you have any questions?',
  'contactCallout.text': 'I am happy to take care of your concerns.',
  'contactCallout.job': 'Customer Service',

  // Notification Details
  'notificationDetails.title': 'Alarm Details',
  'notificationDetails.reason': 'Possible Cause',
  'notificationDetails.stepByStep': 'Step by Step Instructions',

  // Create Report
  'createReport.headline': 'Create new Performance-Report',
  'createReport.action.create': 'Add Report',

  // Main Menu
  'mainMenu.headline.slice.1': 'MyUNTHA',
  'mainMenu.headline.slice.2': 'Customer portal',
  'mainMenu.salutation': 'Welcome',
  'mainMenu.action.pirate.logout': 'Exit customer view',

  // Nav Items
  'navItem.admin.title': 'Administration',
  'navItem.admin.description': 'Administration, Import, Export',
  'navItem.dashboard.title': 'MySHREDDER',
  'navItem.dashboard.description': 'Operating and process data in real time',
  'navItem.notifications.title': 'Notifications',
  'navItem.notifications.description': 'Alarms & Events',
  'navItem.report.title': 'Report',
  'navItem.report.description': 'Creation and management of performance reports',
  'navItem.explorer.title': 'Data Explorer',
  'navItem.explorer.description': 'In-depth analysis of all operating and process data',
  'navItem.profile.title': 'MyPROFIL',
  'navItem.profile.description': 'Userdata & Settings',
  'navItem.faq.title': 'FAQ',
  'navItem.faq.description': 'Frequently Asked Questions',
  'navItem.dev.title': 'Debug Tools',
  'navItem.dev.description': 'Admin Debug Status Infos',

  // date stuff
  'date.day': 'Day',
  'date.days': 'Days',
  'date.week': 'Week',
  'date.weeks': 'Weeks',
  'date.month': 'Month',
  'date.months': 'Months',

  // unitSystem
  'unitSystem.metricUnits': 'metric',
  'unitSystem.imperialUnits': 'anglo-american',

  // units
  'units.label.celsius': 'Celsius',
  'units.label.fahrenheit': 'Fahrenheit',
  'units.label.voltage': 'Volt',
  'units.label.rpm': 'Rotations per minute',
  'units.label.watt': 'Watt',
  'units.label.kilowatt': 'Kilowatt',
  'units.label.voltampere': 'Volt Ampere',
  'units.label.kva': 'Kilovolt Ampere',
  'units.label.microsiemensPerMeter': 'Microsiemens per meter',
  'units.label.fillLevel': 'Fill level',
  'units.label.hours': 'Hours',
  'units.label.newton': 'Newton',
  'units.label.torque': 'Torque',
  'units.label.permittivity': 'Permittivity',
  'units.label.percentage': 'Percent',
  'units.label.bar': 'Bar',
  'units.label.ampere': 'Ampere',
  'units.label.psi': 'Pounds per square inch',
  'units.label.acceleration': 'Acceleration',
  'units.label.frequency': 'Hertz',
  'units.label.times': 'times',

  'units.symbol.celsius': '\u00B0C',
  'units.symbol.fahrenheit': '\u00B0F',
  'units.symbol.voltage': 'V',
  'units.symbol.rpm': 'rpm',
  'units.symbol.watt': 'W',
  'units.symbol.kilowatt': 'kW',
  'units.symbol.voltampere': 'VA',
  'units.symbol.kva': 'kVA',
  'units.symbol.kwh': 'kWh',
  'units.symbol.microsiemensPerMeter': '\u00B5S/m',
  'units.symbol.percentage': '%',
  'units.symbol.hours': 'h',
  'units.symbol.newton': 'N',
  'units.symbol.torque': 'Nm',
  'units.symbol.bar': 'bar',
  'units.symbol.psi': 'psi',
  'units.symbol.count': 'x',
  'units.symbol.ampere': 'A',
  'units.symbol.acceleration': 'm/s^2',
  'units.symbol.frequency': 'Hz',

  // ExplorerSupport
  'explorerSupportCard.headline': 'Attention!',
  'explorerSupportCard.body': 'Unfortunately, this function is not available on the device you are using. Please change to a device with a size of at least 768 pixel.',

  // datePicker
  'datePicker.presets.today': 'Today',
  'datePicker.presets.yesterday': 'Yesterday',
  'datePicker.presets.past3Days': 'Past 3 Days',
  'datePicker.presets.past7Days': 'Past Week',

  // time formats
  'timeFormat.24h': '24 hours',
  'timeFormat.12h': '12 hours',

  // TimestampIndicator
  'timestampIndicator.live': 'Live-Data',
  'timestampIndicator.recent': 'last update {timestamp} ago',

  'emptyData': 'No data available.',

  'pdfReport.header.label': 'Performance Report',
  'pdfReport.header.machine': 'Machine',
  'pdfReport.header.timerange': 'Time period',
  'pdfReport.fieldSets.parameter': 'Parameter',
  'pdfReport.fieldSets.metric': 'Metric',
  'pdfReport.fieldSets.event': 'Events',
  'pdfReport.logBook.header': 'Logbook',
  'pdfReport.stateOne.entryHeader': 'Status',
  'pdfReport.stateOne.states.active': 'Active',
  'pdfReport.stateOne.states.idle': 'Idle mode',
  'pdfReport.stateOne.states.standby': 'Standby',
  'pdfReport.stateOne.states.rest': 'Standstill',
  'pdfReport.stateOne.states.overload': 'Reversal overload',
  'pdfReport.stateOne.states.cleaning': 'Reversing rotor cleaning',
  'pdfReport.stateOne.states.defect': 'Machine malfunction',
  'pdfReport.stateOne.states.defectExternal': 'Malfunction external',
  'pdfReport.stateOne.states.maintenance': 'Maintenance',
  'pdfReport.stateOne.states.offline': 'Offline',
  'pdfReport.stateOne.states.eco': 'ECO mode',
  'pdfReport.globalParams.materialDensity': 'Material density',
  'pdfReport.globalParams.materialWeight': 'Weight',
  'pdfReport.globalParams.energyCost': 'Energy costs',
  'pdfReport.globalParams.throughput': 'Throughput',
  'pdfReport.globalParams.throughputPerDensity': 'Throughput',
  'pdfReport.globalParams.energyConsumption': 'Energy consumption',
  'pdfReport.globalParams.energyCosts': 'Energy costs',
  'pdfReport.globalParams.energyPerWeight': 'Energy per ton',
  'pdfReport.globalParams.energyCostsPerWeight': 'Costs per ton',
  'pdfReport.globalParams.revUnplanned': 'Reversings',
  'pdfReport.globalParams.clutchSlip1': 'Clutch slip 1',
  'pdfReport.globalParams.clutchSlip2': 'Clutch slip 2',
  'pdfReport.globalParams.operatingHours': 'Operating hours (at the end of the period under consideration)',
  'pdfReport.globalParams.disruptiveOffActive': 'Interfering material shutdowns',
  'pdfReport.measurements.averageHeader': 'Average',
  'pdfReport.measurements.avg': 'Average (absolute)',
  'pdfReport.measurements.max': 'Maximum',
  'pdfReport.measurements.min': 'Minimum',
  'pdfReport.measurements.consumption': 'Consumption',
  'pdfReport.measurements.noData': 'There is no data for the selected time span.',
  'pdfReport.measurements.locked': 'This value is available with our package “GENIUS Advanced”.',
  'pdfReport.unitsMetric.microsiemensPerMeter': 'µS/m',
  'pdfReport.unitsMetric.pressure': 'bar',
  'pdfReport.unitsMetric.torque': 'Nm',
  'pdfReport.unitsMetric.currency': 'EUR',
  'pdfReport.unitsMetric.density': 'kg/m³',
  'pdfReport.unitsMetric.weightTons': 't',
  'pdfReport.unitsMetric.energyCosts': '€/kWh',
  'pdfReport.unitsMetric.tonsPerHour': 't/h',
  'pdfReport.unitsMetric.cubicMeterPerHour': 'm³/h',
  'pdfReport.unitsMetric.energyPerWeight': 'kWh/t',
  'pdfReport.unitsMetric.costsPerWeight': '€/t',
  'pdfReport.unitsImperial.pressure': 'psi',
  'pdfReport.unitsImperial.currency': 'USD',
  'pdfReport.unitsImperial.energyCosts': '$/kWh',
  'pdfReport.unitsImperial.costsPerWeight': '$/t',
  'pdfReport.measurements.MOTORTEMP_SENS.label': 'Motor temperature',
};

export default messages;
