import clsx from 'clsx';
import {
  Container,
  Grid,
  Headline,
  Card,
  Icon,
  Text,
  Button,
  primaryLinkHoverStyle,
  Tag,
} from '@untha/ui';
import React from 'react';
import { css } from '@emotion/react';
import { useUser } from 'src/hooks/useUser';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as ExitIcon } from 'src/icons/exit.svg';
import { ProfileIcon } from 'src/components/ProfileIcon';
import { useMainMenu } from 'src/hooks/useMainMenu';
import { useSpring, a } from 'react-spring';
import { useAuth } from 'src/hooks/useAuth';
import { PreloadNavLink } from 'src/components/PreloadNavLink';
import { screens } from '@untha/theme';
import { NotificationCount } from 'src/components/NotificationCount';
import { useMenuItems } from 'src/hooks/useMenuItem';
import { useResetRecoilState } from 'recoil';
import { pirateIdState } from 'src/states/pirate';
import { usePirate } from 'src/hooks/usePirate';
import { useAnnouncementReadOnly } from 'src/hooks/useAnnouncementReadOnly';
import minimatch from 'minimatch';
import { useIntl } from 'react-intl';

const activeMenuItemStyle = css`
  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 6px;
    background-color: var(--color-primary);
    content: '';
  }
`;

function MainMenuItem({
  isActive,
  icon,
  title,
  description,
  className,
  to,
  disabled,
}: {
  isActive: boolean;
  icon: React.FC<any>;
  title: string;
  description: string;
  className?: string;
  to: string;
  disabled?: boolean;
}): React.ReactElement {
  const { formatMessage } = useIntl();
  const { data: announcement } = useAnnouncementReadOnly(to);

  return (
    <Card
      css={[
        css`
          text-align: center;
        `,
        isActive && activeMenuItemStyle,
        !disabled &&
          css`
            &:hover {
              ${activeMenuItemStyle}
            }

            &:hover .MainMenuItem_Icon {
              color: var(--color-primary);
            }
          `,
      ]}
      className={clsx(
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
        'relative',
        'sm:py-8',
        'md:py-9',
        'overflow-hidden',
        disabled && 'opacity-25',
        className,
      )}
    >
      {['/', '/shredders/*'].some((path) => minimatch(to, path)) && (
        <Icon
          // @ts-expect-error: todo: type icons
          iconName="genius"
          className="absolute right-0 bottom-0 opacity-50 transform translate-x-12 translate-y-10"
          css={css`
            width: 240px;
            height: 240px;
          `}
        />
      )}

      {announcement?.read === false && (
        <Tag
          variant="info"
          className="absolute"
          css={css`
            top: var(--space-3);
            right: var(--space-3);
          `}
        >
          <Headline variant="tinyBold">{formatMessage({ id: 'tag.new.text' })}</Headline>
        </Tag>
      )}

      <div className={clsx('relative', 'inline-flex', 'mb-4', 'sm:mb-6')}>
        <Icon
          css={css`
            color: ${isActive ? 'var(--color-primary)' : 'var(--color-gray-light)'};
          `}
          className="MainMenuItem_Icon inline-block"
          icon={icon}
          size="huge"
        />
        {to === '/notifications' && (
          <NotificationCount
            className="absolute"
            css={css`
              top: -8px;
              right: -8px; ;
            `}
          />
        )}
      </div>

      <Headline
        css={css`
          @media (max-width: ${(screens.get('sm') as number) - 1}px) {
            font-size: var(--font-size-h4);
          }
        `}
        className="relative mb-1"
        variant="h2"
      >
        {title}
      </Headline>
      <Text
        css={css`
          @media (max-width: ${(screens.get('sm') as number) - 1}px) {
            font-size: var(--font-size-small);
            line-height: var(--line-height-small);
          }
        `}
        variant="body"
        className="relative text-gray"
      >
        {description}
      </Text>
    </Card>
  );
}

const AnimatedMainMenu = a.div;

const AnimatedInner = a(Container);

export const MainMenu = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { data: { profile } = {} } = useUser();
  const { data: { company: pirateCompany } = {} } = usePirate();
  const resetPirateId = useResetRecoilState(pirateIdState);
  const {
    isAuthorized,
    signOut: { mutate: signOut },
  } = useAuth();
  const { close, isOpen } = useMainMenu();
  const spring = useSpring({
    from: { x: '-100%', opacity: 0 },
    to: { x: isOpen ? '0%' : '-100%', opacity: isOpen ? 1 : 0 },
  });
  const location = useLocation();
  const menuItems = useMenuItems();

  const getActiveState = (p: string, isExact?: boolean): boolean => {
    if (isExact) return p === location.pathname;
    return location.pathname.includes(p);
  };

  const handleClickSignOut = () => signOut();

  const handleClickLeavePirate = () => {
    history.replace('/admin');
    resetPirateId();
    close();
  };

  return (
    <AnimatedMainMenu
      style={{ transform: spring.x.to((x) => `translate3d(${x}, 0, 0) `) }}
      css={css`
        z-index: var(--z-index-main-menu);
        background-color: var(--color-gray-lighter);
      `}
      className={clsx(
        'fixed',
        'flex',
        'top-0',
        'left-0',
        'h-full',
        'w-full',
        'pt-13',
        'pb-7',
        'sm:pl-12',
        'sm:py-12',
        'overflow-y-auto',
        'overflow-x-hidden',
      )}
    >
      <AnimatedInner
        variant="default"
        style={{
          transform: spring.x.to((x) => `translate3d(calc(${x} * -1), 0,0) `),
          opacity: spring.opacity,
        }}
      >
        {/* branding */}
        <Headline variant="h1" className="mb-7 sm:mb-11">
          {formatMessage({ id: 'mainMenu.headline.slice.1' })}{' '}
          <span
            css={css`
              font-weight: normal;
            `}
          >
            {formatMessage({ id: 'mainMenu.headline.slice.2' })}
          </span>
        </Headline>

        {/* salutation */}
        <div className="mb-6 sm:mb-9">
          {pirateCompany ? (
            <>
              {profile && (
                <Headline className="mr-3" as="span" variant="h2">
                  {pirateCompany.companyName}
                </Headline>
              )}

              <Tag variant="error" className="mr-3">
                <Headline variant="tinyBold">
                  {formatMessage({ id: 'tag.customerView.text' })}
                </Headline>
              </Tag>

              {/* sign-out */}
              <Button
                as="button"
                css={primaryLinkHoverStyle}
                variant="tertiary"
                onClick={() => handleClickLeavePirate()}
              >
                <Text variant="tiny" as="span">
                  {formatMessage({ id: 'mainMenu.action.pirate.logout' })}
                </Text>
                <Icon icon={ExitIcon} size="large" />
              </Button>
            </>
          ) : (
            <>
              <Headline
                css={css`
                  font-weight: normal;
                `}
                variant="h2"
                as="span"
                className="mr-2"
              >
                {formatMessage({ id: 'mainMenu.salutation' })},
              </Headline>
              {profile && (
                <Headline className="mr-3" as="span" variant="h2">
                  {profile.firstName} {profile.lastName}!
                </Headline>
              )}

              {/* sign-out */}
              <Button
                as="button"
                css={[
                  primaryLinkHoverStyle,
                  css`
                    font-size: var(--font-size-tiny);
                  `,
                ]}
                variant="tertiary"
                onClick={handleClickSignOut}
              >
                {formatMessage({ id: 'action.logout' })}
                <Icon icon={ExitIcon} size="large" />
              </Button>
            </>
          )}
        </div>

        {/* menu items */}
        <Grid
          css={css`
            /* responsive gap workaround */
            --main-menu-items-gap: var(--space-2);
            --main-menu-items-threshold: 150px;

            @media (min-width: ${screens.get('sm')}px) {
              --main-menu-items-gap: var(--space-6);
              --main-menu-items-threshold: 280px;
            }
          `}
          gap="var(--main-menu-items-gap)"
          threshold="var(--main-menu-items-threshold)"
          fillMode="auto-fill"
        >
          {/* static menu items */}
          {menuItems.map(
            ({
              to,
              icon,
              exact,
              title,
              description,
              rule,
              debugOnly: _debugOnly,
              ...rest
            }) =>
              isAuthorized(rule) ? (
                <PreloadNavLink key={to} onClick={close} to={to} exact={exact} {...rest}>
                  <MainMenuItem
                    icon={icon}
                    title={formatMessage({ id: title })}
                    isActive={getActiveState(to, exact)}
                    description={formatMessage({ id: description })}
                    to={to}
                  />
                </PreloadNavLink>
              ) : null,
          )}

          {/* profile menu item */}
          {profile && (
            <PreloadNavLink to="/profile" onClick={close}>
              <MainMenuItem
                to="/profile"
                icon={(props) => (
                  <ProfileIcon
                    {...props}
                    firstName={profile.firstName}
                    lastName={profile.lastName}
                  />
                )}
                title={formatMessage({ id: 'navItem.profile.title' })}
                description={formatMessage({ id: 'navItem.profile.description' })}
                isActive={getActiveState('/profile')}
              />
            </PreloadNavLink>
          )}
        </Grid>
      </AnimatedInner>
    </AnimatedMainMenu>
  );
};
