import React from 'react';
import { useRecoilState } from 'recoil';
import { mainMenuOpenState } from 'src/states/layout';
import { BodyLock } from 'src/hooks/useBodyLock';

export const useMainMenu = () => {
  const [isOpen, setIsOpen] = useRecoilState(mainMenuOpenState);
  const [, { lock, unlock }] = BodyLock.useContainer();

  const open = React.useCallback(() => setIsOpen(true), [setIsOpen]);

  const close = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  React.useEffect(() => {
    if (isOpen) {
      lock();
    } else {
      unlock();
    }
  }, [isOpen, lock, unlock]);

  // release body lock on unmount
  React.useEffect(
    () => () => {
      unlock();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    isOpen,
    open,
    close,
  };
};
