import React from 'react';
import clsx from 'clsx';
import { useHistory, useRouteMatch } from 'react-router-dom';
import type { NavLinkProps } from 'react-router-dom';
import { routes } from 'src/routes';

type PreloadNavLinkProps = {
  to: string;
};

export const PreloadNavLink = ({
  to,
  onMouseEnter,
  onClick,
  children,
  className,
  activeClassName,
  activeStyle,
  exact,
  strict,
  style,
  isActive,
  ...rest
}: NavLinkProps & PreloadNavLinkProps): React.ReactElement => {
  const history = useHistory();
  const match = useRouteMatch({ path: to, exact, strict });
  const isFinallyActive = isActive ?? !!match;
  const [startTransition] = React.unstable_useTransition({
    timeoutMs: 4000,
    busyDelayMs: 400,
    busyMinDurationMs: 1400,
  });

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    startTransition(() => {
      typeof to === 'string' && history.push(to);
      onClick && onClick(event);
    });
  };

  // wrap mouseEnter with additional enhancements (e.g. preloading)
  const handleMouseEnter = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // look up for the preload target route
    const route = routes.find((route) => {
      if (Array.isArray(route.path)) {
        // todo: support LocationDescription objects @joh
        return route.path.some((p) => p === to);
      }
      return route.path === to;
    });

    // kick off things by preloading the route
    route?.component.preload();

    // finish things with the optional passed event handler
    onMouseEnter && onMouseEnter(event);
  };

  return (
    <a
      className={clsx(
        'relative', // keep spinner in place
        'cursor-pointer',
        className,
        isFinallyActive && activeClassName,
      )}
      style={{ ...style, ...((isFinallyActive && activeStyle) || {}) }}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      {...rest}
    >
      {children}
    </a>
  );
};
