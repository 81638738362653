import React from 'react';
import clsx from 'clsx';
import { css } from '@emotion/react';
import { Brand, Icon, tertiaryLinkHoverStyle } from '@untha/ui';
import { useMainMenu } from 'src/hooks/useMainMenu';
import { ReactComponent as MenuIcon } from 'src/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'src/icons/close.svg';
import { AnimatedBrand } from './AnimatedBrand';

/**
 * Mobile top navigation bar
 */
export const NavigationBar = (): React.ReactElement => {
  const { close, open, isOpen } = useMainMenu();

  return (
    <div
      css={css`
        z-index: var(--z-index-navbar);
        background-color: var(--color-white);
        box-shadow: var(--box-shadow-box);
      `}
      className={clsx(
        'sm:hidden',
        'h-10',
        'sticky',
        'top-0',
        'left-0',
        'w-full',
        'px-4',
        'flex',
        'items-center',
        'justify-between',
      )}
    >
      <AnimatedBrand size={isOpen ? 'default' : 'small'} />

      {/* menu toggle */}
      {isOpen ? (
        <button onClick={close} css={tertiaryLinkHoverStyle}>
          <Icon icon={CloseIcon} size="large" />
        </button>
      ) : (
        <button onClick={open} css={tertiaryLinkHoverStyle}>
          <Icon icon={MenuIcon} size="large" />
        </button>
      )}
    </div>
  );
};
