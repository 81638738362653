import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import { routes } from 'src/routes';
import type { RouteType } from 'src/routes';
import { CssBaseline } from '@untha/ui';
import { css, Global } from '@emotion/react';
import { ToastContainer } from 'react-toastify';
import { AppShell } from 'src/components/AppShell';
import { ProtectedRoute } from 'src/components/ProtectedRoute';
import { AuthProvider } from 'src/hooks/useAuth';
import { RouteWithSuspense } from 'src/components/Route';
import { PirateProvider } from 'src/hooks/usePirate';
import { BodyLock } from 'src/hooks/useBodyLock';
import { AppSpinner } from './components/AppSpinner';
import { ErrorBoundary } from 'react-error-boundary';
import { AppErrorFallback } from 'src/components/AppErrorFallback';
import { Intl } from 'src/components/Intl';
import { screens } from '@untha/theme';
import { logError } from 'src/services/meta';

// base styles
import 'src/styles/tailwind.css';
import 'src/styles/variables.css';
import 'src/styles/base.css';

// vendor styles
// @ts-expect-error: unnecessary type-checking
import('react-toastify/dist/ReactToastify.min.css');
// @ts-expect-error: unnecessary type-checking
import('src/styles/react-toastify.css');
// @ts-expect-error: unnecessary type-checking
import('swiper/swiper.min.css');
// @ts-expect-error: unnecessary type-checking
import('swiper/components/navigation/navigation.min.css');

// todo: PERF: add pre-caching via ServiceWorker

const cssBaselineOverrides = css`
  :root {
    @media (min-width: ${screens.get('md')}px) {
      --max-width-container: 1280px;
    }

    @media (min-width: ${screens.get('lg')}px) {
      /* padding-overrides */
      --px-container: var(--space-12);
    }
  }
`;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      retry: false,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
    },
  },
});

const renderRoute = (route: RouteType): React.ReactElement => {
  const key = String(route.path);

  return route.protected ? (
    <ProtectedRoute key={key} {...route} />
  ) : (
    <RouteWithSuspense key={key} {...route} />
  );
};

const pages = routes.map((route) => renderRoute(route));

const handleAppError = async (
  error: Error,
  info: {
    componentStack: string;
  },
) => {
  await logError({
    errorCode: error.message,
    isPublic: true,
    message: info.componentStack,
  });
};

const App = (): React.ReactElement => {
  return (
    <>
      <CssBaseline />
      <Global styles={cssBaselineOverrides} />

      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <BodyLock.Provider>
            <ToastContainer position="bottom-center" />

            <Router>
              <ErrorBoundary
                onError={handleAppError}
                FallbackComponent={AppErrorFallback}
              >
                <React.Suspense fallback={<AppSpinner />}>
                  <PirateProvider>
                    <AuthProvider>
                      <Intl>
                        <AppShell>
                          <Switch>{pages}</Switch>
                        </AppShell>
                      </Intl>
                    </AuthProvider>
                  </PirateProvider>
                </React.Suspense>
              </ErrorBoundary>
            </Router>
          </BodyLock.Provider>
        </RecoilRoot>
      </QueryClientProvider>
    </>
  );
};

export default App;
