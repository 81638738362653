import { css } from '@emotion/react';
import clsx from 'clsx';
import React from 'react';
import { useUnreadNotificationCount } from 'src/hooks/useUnreadNotificationCount';

type NotificationCountProps = {
  className?: string;
};

export function NotificationCount({
  className,
}: NotificationCountProps): React.ReactElement {
  const { count } = useUnreadNotificationCount();

  return (
    <>
      {count ? (
        <div
          className={clsx(
            className,
            'inline-flex',
            'justify-center',
            'items-center',
            'rounded-full',
            'text-center',
            'align-middle',
            'flex-shrink-0',
            'flex-grow-0',
            'text-white',
            'font-bold',
            'border-2',
            'border-white',
            count > 9 && 'px-1',
          )}
          css={css`
            width: ${count < 10 ? '20px' : 'auto'};
            transform: ${count > 9 ? 'translate(8px, 0)' : undefined};
            height: 20px;
            line-height: 1;
            font-size: 10px;
            font-weight: bold;
            background-color: var(--color-error);
          `}
        >
          {count > 99 ? '99+' : count}
        </div>
      ) : null}
    </>
  );
}
