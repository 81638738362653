import React from 'react';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { queryKeys } from 'src/constants';
import { getAuthToken } from 'src/services/auth';
import { getPirateUserProfile } from 'src/services/pirate';
import { pirateIdState } from 'src/states/pirate';
import type { PirateUser } from 'src/types';
import { toast } from 'react-toastify';

// todo: BUG: resolve `onSuccess` call issues in combination with preloading

type PirateProviderProps = {
  children?: React.ReactNode;
};

const pirateCtx = React.createContext(undefined as unknown as UseQueryResult<PirateUser>);

export function PirateProvider({ children }: PirateProviderProps): React.ReactElement {
  const pirateId = useRecoilValue(pirateIdState);
  const resetPirateId = useResetRecoilState(pirateIdState);
  const { data: authToken } = useQuery(queryKeys.getAuthToken, () => getAuthToken());
  const query = useQuery(
    [queryKeys.getPirateUserProfile, pirateId],
    // @ts-expect-error: fetcher wont run, without necessary deps
    () => getPirateUserProfile(pirateId, true),
    {
      enabled: !!pirateId && !!authToken,
      staleTime: 1, // tmp workaround (see bug above)
      onError: () => {
        resetPirateId();
        toast('Error receiving customer data!', { toastId: 'pirate.error' });
      },
      useErrorBoundary: false,
      retry: false,
      retryDelay: 2000,
    },
  );

  // clear interceptor on un-mount or auth change
  React.useEffect(
    () => () => {
      const interceptorId = query.data?.auth.interceptorId;
      if (interceptorId) {
        axios.interceptors.request.eject(interceptorId);
      }
    },
    [query.data?.auth.interceptorId],
  );

  // there might be edge cases where the user authentication has expired and
  // the signOut action has been skipped. Therefore we need to provide
  // a manual clean up effect
  React.useEffect(() => {
    if (authToken === null) {
      resetPirateId();
    }
  }, [authToken]);

  return <pirateCtx.Provider value={query}>{children}</pirateCtx.Provider>;
}

export const usePirate = () => React.useContext(pirateCtx);
