import { openDB } from 'idb';

export const db = openDB('untha-portal', 1, {
  upgrade: async (upgradeDb) => {
    // notifications
    const notificationOS = upgradeDb.createObjectStore('notifications', {
      keyPath: 'id',
    });
    notificationOS.createIndex('read', 'read');
    notificationOS.createIndex('read, source', ['read', 'source']);

    // announcements
    const announcementOS = upgradeDb.createObjectStore('announcements', {
      keyPath: 'id',
    });

    // register announcements
    try {
      await announcementOS.add({ id: '/explorer', read: 0 });
      await announcementOS.add({ id: '/reports', read: 0 });
    } catch (e) {
      console.log('Error initializing announcementOS entries', e);
    }
  },
});
