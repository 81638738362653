// Units are necessary to proper support i18n features
// e.g. currency converting EUR --> USD

// to get converter formulas see:
// https://www.unitconverters.net

import { IntlFormatters } from '@formatjs/intl';
import type { UnitId } from 'src/types';
import type { UserProfile } from 'src/types';

// todo: add type overload to narrow down .to fn

/**
 * Convert base value to target unit
 */
type MeasurementConvertFn = (val: number) => number;

export type MeasurementUnit = {
  id: UnitId;
  /**
   * Base measurement label
   */
  label: string;
  /**
   * Base unit label
   */
  symbol: string;
  /**
   * How much fraction digits  are relevant? Omits unnecessary zeros by default.
   */
  maxFractions?: number;
  /**
   * The least amount of fraction digits. Keeps unnecessary zeros.
   */
  minFractions?: number;
  /**
   * Additional to interpolators (used for i18n features)
   */
  imperial?: {
    label: string;
    symbol: string;
    convertFn: MeasurementConvertFn;
  };
  profileDerivationFn?: (
    profile: UserProfile,
    formatMessage: IntlFormatters['formatMessage'],
  ) => { symbol: string; label: string };
};

// Units by name
export const measurementUnits: Record<UnitId, MeasurementUnit> = {
  temperature: {
    id: 'temperature',
    label: 'units.label.celsius',
    symbol: 'units.symbol.celsius',
    maxFractions: 0,
    imperial: {
      label: 'units.label.fahrenheit',
      symbol: 'units.symbol.fahrenheit',
      convertFn: (val: number): number => val * (9 / 5) + 32,
    },
  },
  currency: {
    id: 'currency',
    label: '', // retrieved from user and applied in `useFormatWithUnit.tsx`
    symbol: '', // retrieved from user and applied in `useFormatWithUnit.tsx`
    maxFractions: 2,
    minFractions: 2,
    profileDerivationFn: (profile, formatMessage) => ({
      symbol: currencies[profile.additionalAttributes.currency],
      label: formatMessage({
        id: `currencies.${profile.additionalAttributes.currency}`,
      }),
    }),
  },
  voltage: {
    id: 'voltage',
    label: 'units.label.voltage',
    symbol: 'units.symbol.voltage',
    maxFractions: 1,
  },
  revolution: {
    id: 'revolution',
    label: 'units.label.rpm',
    symbol: 'units.symbol.rpm',
    maxFractions: 0,
  },
  activePower: {
    id: 'activePower',
    label: 'units.label.watt',
    symbol: 'units.symbol.watt',
    maxFractions: 1,
  },
  activePowerK: {
    id: 'activePowerK',
    label: 'units.label.kilowatt',
    symbol: 'units.symbol.kilowatt',
    maxFractions: 1,
  },
  apparentPower: {
    id: 'apparentPower',
    label: 'units.label.voltampere',
    symbol: 'units.symbol.voltampere',
    maxFractions: 1,
  },
  apparentPowerK: {
    id: 'apparentPowerK',
    label: 'units.label.kva',
    symbol: 'units.symbol.kva',
    maxFractions: 1,
  },
  energyConsumption: {
    id: 'energyConsumption',
    label: 'units.symbol.kwh',
    symbol: 'units.symbol.kwh',
    maxFractions: 0,
  },
  conductivity: {
    id: 'conductivity',
    label: 'units.label.microsiemensPerMeter',
    symbol: 'units.symbol.microsiemensPerMeter',
    maxFractions: 2,
  },
  fillLevel: {
    id: 'fillLevel',
    label: 'units.label.fillLevel',
    symbol: 'units.symbol.percentage',
    maxFractions: 0,
  },
  humidity: {
    id: 'humidity',
    label: '',
    symbol: 'units.symbol.percentage',
    maxFractions: 1,
  },
  // todo: merge with `age`? @joh
  time: {
    id: 'time',
    label: 'units.label.hours', // almost every time related attr is measured in hours
    symbol: 'units.symbol.hours',
    maxFractions: 0,
  },
  force: {
    id: 'force',
    label: 'units.label.newton',
    symbol: 'units.symbol.newton',
    maxFractions: 0,
  },
  torque: {
    id: 'torque',
    label: 'units.label.torque',
    symbol: 'units.symbol.torque',
    maxFractions: 0,
  },
  permittivity: {
    id: 'permittivity',
    label: 'units.label.permittivity',
    symbol: '',
    maxFractions: 3,
  },
  percentage: {
    id: 'percentage',
    label: 'units.label.percentage',
    symbol: 'units.symbol.percentage',
    maxFractions: 2,
  },
  pressure: {
    id: 'pressure',
    label: 'units.label.bar',
    symbol: 'units.symbol.bar',
    maxFractions: 1,
    imperial: {
      label: 'units.label.psi',
      symbol: 'units.symbol.psi',
      convertFn: (val: number): number => val * 14.503773773,
    },
  },
  count: {
    id: 'count',
    label: '',
    symbol: 'units.symbol.count',
    maxFractions: 0,
  },
  electricCurrent: {
    id: 'electricCurrent',
    label: 'units.label.ampere',
    symbol: 'units.symbol.ampere',
    maxFractions: 1,
  },
  acceleration: {
    id: 'acceleration',
    label: 'units.label.acceleration',
    symbol: 'units.symbol.acceleration',
    maxFractions: 3,
  },
  frequency: {
    id: 'acceleration',
    label: 'units.label.frequency',
    symbol: 'units.symbol.frequency',
    maxFractions: 1,
  },
};

export const currencies: Record<string, string> = {
  eur: '€',
  usd: '$',
  gbp: '£',
  jpy: '¥',
};
