// prettier-ignore
const messages = {
  // project meta
  'project.title': 'MyUNTHA',
  'project.brand': 'UNTHA',
  'project.brandPhrase': 'shredding technology',
  'project.brandSub': 'Su portal del cliente personal',

  // pages
  'page.dashboard.breadcrumb': 'Dashboard',
  'page.admin.breadcrumb': 'Administración',
  'page.admin.csvImport.headline': 'Import',
  'page.admin.csvExport.headline': 'Export',
  'page.shredder.section.overview': 'Visión general',
  'page.shredder.section.records': 'Conjuntos de datos',
  'page.shredder.downloads': 'Descargas',
  'page.notifications.title': 'Mensajes',
  'page.notifications.breadcrumb': 'Mensajes',
  'page.notifications.section.notifications': 'Mensajes actuales',
  'page.notifications.section.notifications.alarms': 'Alarmas',
  'page.notifications.section.notifications.events': 'Incidencias',
  'page.notifications.section.settings': 'Ajustes',
  'page.notifications.section.settings.notifications': 'Notificaciones',
  'page.login.headline': 'Iniciar sesión',
  'page.login.body': '¿Todavía no tiene cuenta?',
  'page.login.forgotPassword': '¿Olvidó su contraseña?',
  'page.register.headline': 'Registrarse',
  'page.register.body': '¿Ya es cliente de UNTHA, pero todavía no tiene acceso al portal MyUNTHA? Regístrese aquí.',
  'page.register.back': '¿Ya está registrado??',
  'page.register.toast.error': 'Se ha producido un error durante el registro. Vuelva a intentarlo en otro momento o póngase en contacto con el servicio de atención al cliente de UNTHA.',
  'page.forgotPassword.headline': 'Restablecer la contraseña',
  'page.forgotPassword.body': 'Introduzca su dirección de correo electrónico y su número de cliente y le enviaremos una contraseña temporal. Tras realizar el primer inicio se sesión, podrá establecer una nueva contraseña.',
  'page.forgotPassword.back': 'Volver al inicio de sesión',
  'page.newPassword.headline': 'Actualizar contraseña',
  'page.newPassword.body': 'Elija una nueva contraseña para su próximo inicio de sesión.',
  'page.profile.breadcrumb': 'MyPROFIL',
  'page.profile.section.user': 'Datos personales',
  'page.profile.section.user.profile': 'Sus datos',
  'page.profile.section.user.company': 'Su empresa',
  'page.profile.section.settings': 'Ajustes',
  'page.profile.section.settings.ui': 'Interfaz de usuario',
  'page.profile.section.settings.password': 'contraseña',
  'page.profile.section.settings.energyCosts': 'Costes energéticos',
  'page.profile.section.admin': 'Administration',
  'page.profile.section.admin.subUser': 'Cuentas de usuario',
  'page.profile.section.admin.subUser.info': 'En esta sección puede administrar los usuarios existentes o crear usuarios adicionales.<br />\nPara crear un usuario adicional, debe introducir la dirección de correo electrónico y el nombre completo.<br />\nTras crear la cuenta, se enviará un correo electrónico con el que el usuario podrá registrarse e iniciar sesión.',
  'page.shredders.empty': 'Aún no hay ninguna trituradora disponible.',
  'page.shredders.section.shredder': `{count, plural,
    one {Su trituradora}
    other {Sus trituradoras}
  }`,
  'page.report.breadcrumb': 'Informe',
  'page.report.title': 'Informes de rendimiento',
  'page.report.history': 'Últimos informes de rendimiento',
  'page.report.scheduled': 'Informes de rendimiento automáticos',
  'page.explorer.manage.breadcrumb': 'Puntos de datos',
  'page.explorer.manage.search.placeholder': 'Buscar punto de datos...',
  'page.explorer.manage.search.empty': 'No se han encontrado resultados.',
  'page.explorer.manage.limit': 'Se ha alcanzado el número máximo de puntos de datos.',
  'page.explorer.intro.section.favorites': 'Favoritos',
  'page.explorer.presets.action.load': 'Cargar favorito',
  'page.explorer.presets.placeholder': 'No hay ningún favorito disponible',
  'page.explorer.presets.title': 'Favoritos',
  'page.registrationConfirmation.section.success.headline': 'Registro concluido',
  'page.registrationConfirmation.section.success.body': 'Tu proceso de registro ha concluido. Ya puedes iniciar sesión.',
  'page.registrationConfirmation.section.error.headline': 'Registro fallido',
  'page.registrationConfirmation.section.error.body': 'Tu proceso de registro ha fallado. Ponte en contacto con el administrador de tu empresa.',
  'page.register.toast.success': 'Registro correcto',

  // Table
  'table.th.id': 'ID',
  'table.th.number': 'Número.',
  'table.th.alarm': 'Alarma',
  'table.th.priority': 'Prioridad',
  'table.th.sms': 'SMS',
  'table.th.firstName': 'Nombre',
  'table.th.lastName': 'Apellidos',
  'table.th.period': 'Período de tiempo',
  'table.th.reportType': 'Archivo',
  'table.th.shredder': 'Trituradora',
  'table.th.status': 'Estado',

  // ReportHistory
  'reportHistory.type.scheduled': 'automático',
  'reportHistory.type.single': 'único',
  'reportHistory.status.error': 'Error',
  'reportHistory.status.pending': 'en curso',
  'reportHistory.createReport': 'Nuevo informe',
  'reportHistory.modal.title': 'Nuevo informe de rendimiento',
  'reportHistory.noResults': 'Ningún informe anterior.',

  // CompanyTable
  'companyTable.action.pirate': 'Vista de cliente',
  'companyTable.deleteDialog.title': 'Salir de la vista de cliente',
  'companyTable.toast.impersonate.active.success': 'Vista de cliente activada',
  'companyTable.toast.impersonate.disabled.success': 'Vista de cliente desactivada',
  'companyTable.deleteDialog.headline': 'Precaución',
  'companyTable.deleteDialog.body': 'Al desactivar un cliente, ninguno de los usuarios asignados a él podrán ya iniciar sesión en el portal del cliente.',
  'companyTable.deleteDialog.confirmation': '¿Está seguro de que desea continuar?',
  'companyTable.deleteDialog.notify': 'Enviar correo electrónico sobre desactivación a todos los usuarios del cliente',
  'companyTable.search.input.placeholder': 'Buscar clientes...',
  'companyTable.toast.impersonate.active.error': 'Error al activar vista de clientes',

  // common actions
  'action.saveChanges': 'Guardar cambios',
  'action.disable': 'Desactivar',
  'action.enable': 'Aktivieren',
  'action.login': 'Iniciar sesión',
  'action.logout': 'Cerrar la sesión',
  'action.register': 'Registrarse',
  'action.edit': 'Editar',
  'action.delete': 'Eliminar',
  'action.cancel': 'Cancelar',
  'action.create': 'Generar',
  'action.save': 'Guardar',
  'action.revert': 'Restablecer',
  'action.download': 'Descargar',
  'action.upload': 'Cargar',
  'action.update': 'Actualizar',
  'action.close': 'Cerrar',
  'action.reset': 'Restablecer',
  'action.add': 'Añadir',

  // common states
  'state.enabled': 'activo',
  'state.disabled': 'inactivo',

  // shredder attributes
  'shredderAttr.ENERGY_SUPPLY.label': 'Costes energéticos',
  'shredderAttr.HOR_POWER_ACTIVE.label': 'Potencia eléctrica de la cinta horizontal',
  'shredderAttr.ENERGY_SUPPLY_COSTS.label': 'desde la puesta en servicio',
  'shredderAttr.CHILLER_POWER_APPARENT.label': 'Potencia aparente de la refrigeración por agua',
  'shredderAttr.RISE_POWER_ACTIVE.label': 'Potencia eléctrica de la cinta ascendente',
  'shredderAttr.RISE_ENERGY.label': 'Consumo energético de la cinta ascendente',
  'shredderAttr.OIL_LIFETIME_HYDR.label': 'Vida útil restante del aceite hidráulico',
  'shredderAttr.MAGN_ENERGY_COSTS.label': 'Costes energéticos del separador magnético',
  'shredderAttr.HEATPLATE_TEMP2.label': 'Temperatura del disipador 1',
  'shredderAttr.HEATPLATE_TEMP1.label': 'Temperatura del disipador 2',
  'shredderAttr.MAGN_POWER_ACTIVE.label': 'Potencia eléctrica del separador magnético',
  'shredderAttr.OIL_CONDUCTIVITY_GEAR2.label': 'Conductividad del aceite de transmisión 2',
  'shredderAttr.OIL_CONDUCTIVITY_GEAR1.label': 'Conductividad del aceite de transmisión 1',
  'shredderAttr.RISE_ENERGY_COSTS.label': 'Costes energéticos de la cinta ascendente',
  'shredderAttr.OIL_PERMITIVITY_GEAR1.label': 'Permitividad del aceite de transmisión 1',
  'shredderAttr.OIL_PERMITIVITY_GEAR2.label': 'Permitividad del aceite de transmisión 2',
  'shredderAttr.OIL_CONDUCTIVITY_HYDR.label': 'Conductividad del aceite hidráulico',
  'shredderAttr.POWER_APPARENT_ACT2.label': 'Potencia aparente del motor 2',
  'shredderAttr.ROTOR_REVOLUTION.label': 'N.º de revoluciones del rotor',
  'shredderAttr.ROTOR_REVOLUTION1.label': 'N.º de revoluciones del rotor 1',
  'shredderAttr.ROTOR_REVOLUTION2.label': 'N.º de revoluciones del rotor 2',
  'shredderAttr.OIL_TEMPERATURE_HYDR.label': 'Temperatura del aceite hidráulico',
  'shredderAttr.POWER_APPARENT_ACT1.label': 'Potencia aparente del motor 1',
  'shredderAttr.COOLING_TEMPIN.label': 'Temperatura de retorno de la refrigeración por agua',
  'shredderAttr.TORQUE_ROT.label': 'Par rotor',
  'shredderAttr.TORQUE_ROT1.label': 'Par rotor 1',
  'shredderAttr.TORQUE_ROT2.label': 'Par rotor 2',
  'shredderAttr.MOTORTEMP_2_SENS.label': 'Temperatura del motor 2',
  'shredderAttr.OIL_PERMITIVITY_HYDR.label': 'Permitividad del aceite de transmisión',
  'shredderAttr.CHILLER_PB4.label': 'Temperatura ambiente',
  'shredderAttr.CHILLER_POWER_ACTIVE.label': 'Potencia eléctrica de la refrigeración por agua',
  'shredderAttr.CHILLER_PB3.label': 'Presión del condensador',
  'shredderAttr.CHILLER_PB2.label': 'Temperatura de flujo de la refrigeración',
  'shredderAttr.UEFF_L23.label': 'Tensión del conductor U23',
  'shredderAttr.CHILLER_PB1.label': 'Temperatura del evaporador',
  'shredderAttr.OIL_TEMPERATURE_GEAR1.label': 'Temperatura del aceite de transmisión 1',
  'shredderAttr.OIL_TEMPERATURE_GEAR2.label': 'Temperatura del aceite de transmisión 2',
  'shredderAttr.CHILLER_ENERGY_COSTS.label': 'Costes energéticos de la refrigeración por aguan',
  'shredderAttr.HOR_ENERGY_COSTS.label': 'Costes energéticos de la cinta horizontal',
  'shredderAttr.REVOLUTION_ACT2.label': 'N.º de revoluciones del motor 2',
  'shredderAttr.HYDR_POWER_APPARENT.label': 'Potencia aparente del sistema hidráulico',
  'shredderAttr.REVOLUTION_ACT1.label': 'N.º de revoluciones del motor 1',
  'shredderAttr.TORQUE1.label': 'Par de giro 1',
  'shredderAttr.DEVICE_TEMP2.label': 'Temperatura del disipador 2',
  'shredderAttr.DEVICE_TEMP1.label': 'Temperatura del disipador 1',
  'shredderAttr.TORQUE2.label': 'Par de giro 2',
  'shredderAttr.MAGN_ENERGY.label': 'Consumo energético del separador magnético',
  'shredderAttr.OIL_LEVEL_HYDR.label': 'Nivel del aceite hidráulico',
  'shredderAttr.UEFF_L31.label': 'Tensión del conductor U31',
  'shredderAttr.POWER_ACTIVE.label': 'Potencia eléctrica',
  'shredderAttr.OIL_LEVEL_GEAR1.label': 'Nivel del aceite de transmisión 1',
  'shredderAttr.OIL_LEVEL_GEAR2.label': 'Nivel del aceite de transmisión 2',
  'shredderAttr.VOLTAGE_OUT2.label': 'Tensión de salida 2',
  'shredderAttr.VOLTAGE_OUT1.label': 'Tensión de salida 1',
  'shredderAttr.POWER_ACTIVE_ACT2.label': 'Potencia eléctrica del motor 2',
  'shredderAttr.POWER_ACTIVE_ACT1.label': 'Potencia eléctrica del motor 1',
  'shredderAttr.OIL_AGE_GEAR1.label': 'Antigüedad del aceite de transmisión 1',
  'shredderAttr.OIL_AGE_GEAR2.label': 'Antigüedad del aceite de transmisión 2',
  'shredderAttr.HOR_ENERGY.label': 'Costes energéticos de la cinta horizontal',
  'shredderAttr.CNT_REVERSING.label': 'Contador de inversiones',
  'shredderAttr.OIL_LIFETIME_GEAR1.label': 'GVida útil restante del acmotor 1',
  'shredderAttr.OIL_LIFETIME_GEAR2.label': 'GVida útil restante del acmotorr 2',
  'shredderAttr.FUNNEL_LEVEL.label': 'Nivel de llenado de la tolva',
  'shredderAttr.HYDR_ENERGY.label': 'Consumo energético del sistema hidráulico',
  'shredderAttr.HYDR_ENERGY_COSTS.label': 'Costes energéticos del sistema hidráulico',
  'shredderAttr.COOLING_TEMPOUT.label': 'Temperatura de flujo de la refrigeración por agua',
  'shredderAttr.OIL_HUMIDITY_HYDR.label': 'Humedad relativa del aceite hidráulico',
  'shredderAttr.UEFF_L12.label': 'Tensión del conductor U12',
  'shredderAttr.MAGN_POWER_APPARENT.label': 'Potencia aparente del separador magnético',
  'shredderAttr.CHILLER_ENERGY.label': 'Consumo energético de la refrigeración por agua',
  'shredderAttr.POWER_APPARENT.label': 'Potencia eléctrica',
  'shredderAttr.HYDR_POWER_ACTIVE.label': 'Potencia eléctrica del sistema hidráulico',
  'shredderAttr.CNT_SERVICEFLAP.label': 'Contador de aperturas de la compuerta de mantenimiento',
  'shredderAttr.OPERATING_HOURS.label': 'Horas de servicio',
  'shredderAttr.MOTORTEMP_1_SENS.label': 'Temperatura del motor 1',
  'shredderAttr.PUSHER_FORCE.label': 'Fuerza del cilindro del pasador',
  'shredderAttr.ROTOR_MECH_POWER.label': 'Potencia mecánica del rotor',
  'shredderAttr.HOR_POWER_APPARENT.label': 'Potencia aparente de la cinta horizontal',
  'shredderAttr.CNT_CLUTCHSLIP1.label': 'Contador de deslizamientos del acoplamiento 1',
  'shredderAttr.CNT_CLUTCHSLIP2.label': 'Contador de deslizamientos del acoplamiento 2',
  'shredderAttr.RISE_POWER_APPARENT.label': 'Potencia aparente de la cinta ascendente',
  'shredderAttr.OIL_AGE_HYDR.label': 'Antigüedad del aceite hidráulico',
  'shredderAttr.DC_CURRENT2.label': 'Tensión del cirpowerActiveActcuito intermedio 2',
  'shredderAttr.DC_CURRENT1.label': 'Tensión del cirpowerActiveActcuito intermedio 1',
  'shredderAttr.HYDCOOL_ENERGY.label': 'Costes energéticos de la refrigeración del sistema hidráulico',
  'shredderAttr.HYDCOOL_ENERGY_COSTS.label': 'Costes energéticos de la refrigeración del sistema hidráulico',
  'shredderAttr.HYDCOOL_ENERGY_ACTIVE.label': 'Potencia eléctrica de la refrigeración del sistema hidráulico',
  'shredderAttr.HYDCOOL_ENERGY_ACTIVE_COSTS.label': 'Costes energéticos de la refrigeración del sistema hidráulico',
  'shredderAttr.VOLTAGE_U12.label': 'Tensión del conductor U12',
  'shredderAttr.VOLTAGE_U23.label': 'Tensión del conductor U23',
  'shredderAttr.VOLTAGE_U31.label': 'Tensión del conductor U31',
  'shredderAttr.HORCONVEYOR_POWER_ACTIVE.label': 'Potencia eléctrica de la cinta horizontal',
  'shredderAttr.RAISECONVEYOR_POWER_ACTIVE.label': 'Potencia eléctrica de la cinta ascendente',
  'shredderAttr.RAISECONVEYOR_ENERGY.label': 'Consumo energético de la cinta ascendente',
  'shredderAttr.MAGCONVEYOR_ENERGY_COSTS.label': 'Costes energéticos del separador magnético',
  'shredderAttr.MAGCONVEYOR_POWER_ACTIVE.label': 'Potencia eléctrica del separador magnético',
  'shredderAttr.GEAR_REVOLUTION1.label': 'GEAR_REVOLUTION1',
  'shredderAttr.GEAR_REVOLUTION2.label': 'GEAR_REVOLUTION2',
  'shredderAttr.RAISECONVEYOR_ENERGY_COSTS.label': 'Costes energéticos de la cinta ascendente',
  'shredderAttr.HORCONVEYOR_ENERGY_COSTS.label': 'Costes energéticos de la cinta horizontal',
  'shredderAttr.MAGCONVEYOR_ENERGY.label': 'Consumo energético del separador magnético',
  'shredderAttr.GRID_THD.label': 'Calidad de red THD',
  'shredderAttr.HORCONVEYOR_ENERGY.label': 'Consumo energético de la cinta horizontal',
  'shredderAttr.MAGCONVEYOR_POWER_APPARENT.label': 'Potencia aparente del separador magnético',
  'shredderAttr.ENGINE_MECH_POWER2.label': 'Potencia mecánica del motor 2',
  'shredderAttr.ENGINE_MECH_POWER1.label': 'Potencia mecánica del motor 1',
  'shredderAttr.HORCONVEYOR_POWER_APPARENT.label': 'Potencia aparente de la cinta horizontal',
  'shredderAttr.OIL_HUMIDITY_GEAR2.label': 'Humedad relativa del aceite de transmisión 2',
  'shredderAttr.OIL_HUMIDITY_GEAR1.label': 'Humedad relativa del aceite de transmisión 1',
  'shredderAttr.RAISECONVEYOR_POWER_APPARENT.label': 'Potencia aparente de la cinta ascendente',
  'shredderAttr.ACC_EFF_BEARING.label': 'Valor efectivo de oscilación radial',
  'shredderAttr.ACTIVE_CURRENT1.label': 'Amperaje 1',
  'shredderAttr.ACTIVE_CURRENT2.label': 'Amperaje 2',
  'shredderAttr.GEARTEMP_1_SENS.label': 'Temperatura de transmisión 1',
  'shredderAttr.GEARTEMP_2_SENS.label': 'Temperatura de transmisión 2',
  'shredderAttr.GENERATOR_FUELLEVEL.label': 'Nivel de llenado del grupo electrógeno',
  'shredderAttr.GENERATOR_POWER.label': 'Potencia eléctrica del grupo electrógeno',
  'shredderAttr.GENERATOR_VOLTAGE.label': 'Tensión del grupo electrógeno',
  'shredderAttr.GENERATOR_FREQUENCY.label': 'Frecuencia del grupo electrógeno',


  // shredder attribute clusters
  'shredderAttrCluster.gearOil1': 'Aceite de transmisión 1',
  'shredderAttrCluster.gearOil2': 'Aceite de transmisión 2',
  'shredderAttrCluster.counter': 'Contador',
  'shredderAttrCluster.hydrOil': 'Aceite hidráulico',
  'shredderAttrCluster.engineTotalEnergyConsumption': 'Consumo energético',
  'shredderAttrCluster.engineTotalPowerActive': 'Potencia total de la máquina',
  'shredderAttrCluster.cntGearSkidding': 'Contador de inversiones',
  'shredderAttrCluster.cntServiceFlap': 'Contador de aperturas de la compuerta de mantenimiento',
  'shredderAttrCluster.rotorSpeed': 'N.º de revoluciones del rotor',
  'shredderAttrCluster.rotorTorque': 'Par rotor',
  'shredderAttrCluster.rotorMechPower': 'Potencia mecánica del rotor',
  'shredderAttrCluster.conductorVoltages': 'Tensiones del conductor',
  'shredderAttrCluster.netQuality': 'Calidad de red THD',
  'shredderAttrCluster.engineTorque': 'Par motores',
  'shredderAttrCluster.engineSpeed': 'N.º revoluciones motores',
  'shredderAttrCluster.engineTemp': 'Temperatura del motor',
  'shredderAttrCluster.enginePowerActive': 'Potencia eléctrica motores',
  'shredderAttrCluster.cntClutchSkidding': 'Contador de deslizamientos del acoplamiento',
  'shredderAttrCluster.gearSpeed': 'N.º revoluciones transmisión',
  'shredderAttrCluster.outputVoltage': 'Tensión de salida',
  'shredderAttrCluster.inverterTemp': 'Temperatura del inversor',
  'shredderAttrCluster.heatSinkTemp': 'Temperatura del disipador',
  'shredderAttrCluster.intCircuitVoltage': 'Tensión del circuito intermedio',
  'shredderAttrCluster.conveyorsEnergyConsumption': 'Consumo energético tecnología de transporte',
  'shredderAttrCluster.additionalEnergyConsumption': 'Consumo energético grupos auxiliares',
  'shredderAttrCluster.conveyorsPowerActive': 'Potencia tecnología de transporte',
  'shredderAttrCluster.additionalPowerActive': 'Potencia grupos auxiliares',
  'shredderAttrCluster.waterCoolingTemp': 'Temperaturas refrigeración por agua',
  'shredderAttrCluster.oilLevel': 'Niveles de aceite',
  'shredderAttrCluster.oilTemp': 'Temperaturas de aceite',
  'shredderAttrCluster.radialVibration': 'Valor efectivo de oscilación radial',
  'shredderAttrCluster.funnelLevel': 'Nivel de llenado de la tolva',
  'shredderAttrCluster.pusherForce': 'Fuerza del cilindro del pasador',
  'shredderAttrCluster.activePower': 'Corriente activa',
  'shredderAttrCluster.gearTemp': 'Temperaturas de transmisión',
  'shredderAttrCluster.generatorFuellevel':'Nivel de llenado del grupo electrógeno',
  'shredderAttrCluster.generatorEffectivePower':'Potencia eléctrica del grupo electrógeno',
  'shredderAttrCluster.generatorVoltage':'Tensión del grupo electrógeno',
  'shredderAttrCluster.generatorFrequency':'Frecuencia del grupo electrógeno',

  // shredder attribute topics
  'shredderAttrTopic.hydraulic': 'Sistema hidráulico',
  'shredderAttrTopic.driveTrain': 'Cadena cinemática',
  'shredderAttrTopic.powerTrain': 'Cadena cinemática',
  'shredderAttrTopic.waterCooling': 'Sistema de Enfriamiento',
  'shredderAttrTopic.extras': 'Accesorios',
  'shredderAttrTopic.operatingData': 'Bandas transportadoras',
  'shredderAttrTopic.pusher': 'Pasador',
  'shredderAttrTopic.rotor': 'Rotor',
  'shredderAttrTopic.clutch': 'Acoplamiento',
  'shredderAttrTopic.coolingCircuit': 'Circuito de refrigeración',
  'shredderAttrTopic.horizontalConveyor': 'Horizontalband',
  'shredderAttrTopic.elevatingConveyor': 'Cinta horizontal',
  'shredderAttrTopic.magneticSeparator': 'Separador magnético',
  'shredderAttrTopic.rotorShaft': 'Eje del rotor',
  'shredderAttrTopic.slideCylinder': 'Cilindro del pasador',
  'shredderAttrTopic.oilPumpDrive': 'Accionamiento de la bomba de aceite',
  'shredderAttrTopic.oilPan': 'Cárter de aceite',
  'shredderAttrTopic.pusherForce': 'Fuerza de compresión del pasador',
  'shredderAttrTopic.chiller': 'Sistema de Enfriamiento',
  'shredderAttrTopic.engineTotal': 'Conjunto de la máquina',
  'shredderAttrTopic.cuttingSystem': 'Sistema de corte',
  'shredderAttrTopic.energySupply': 'Acumulación de energía',
  'shredderAttrTopic.engine': 'Motor',
  'shredderAttrTopic.gear': 'Transmisión',
  'shredderAttrTopic.frequencyConverter': 'Convertidor de frecuencia',
  'shredderAttrTopic.transportSystem': 'Tecnología de transporte',
  'shredderAttrTopic.additional': 'Grupos auxiliares',
  'shredderAttrTopic.operatingSupplies': 'Medios de servicio',
  'shredderAttrTopic.other': 'Otros',
  'shredderAttrTopic.powerUnit': 'Grupo electrógeno',

  // notification severity
  'notificationSeverity.0': 'Advertencia',
  'notificationSeverity.1': 'Poco importante',
  'notificationSeverity.2': 'Importante',
  'notificationSeverity.3': 'Crítico',

  // notification acknowledged alarms
  'notificationSeverity.acknowledgedAlarms': 'Alarmas reconocidas',

  // Copyright
  'copyright.label': '© {year} by UNTHA shredding technology GmbH',

  // NotificationSettings
  'notificationSettings.headline': 'Notificaciones',
  'notificationSettings.text': 'Haga que se le notifique sobre esta alarma en caso de que vuelva a producirse.',
  'notificationSettings.type.email': 'Notificaciones por correo electrónico',
  'notificationSettings.type.sms': 'Notificaciones por SMS',
  'notificationSettings.template.subject': '{priority}: Alarma por trituradora {shredder}',
  'notificationSettings.template.text': 'Acaba de dispararse la siguiente alarma en la trituradora {shredder}: "{title}" | {priority}',
  'notificationSettings.toast.sms.update.success': 'Notificación por SMS actualizada',
  'notificationSettings.toast.email.update.success': 'Notificación por correo electrónico actualizada',
  'notificationSettings.toast.sms.delete.success': 'Notificación por SMS borrada',
  'notificationSettings.toast.email.delete.success': 'Notificación por correo electrónico borrada',
  'notificationSettings.toast.sms.create.success': 'Notificación por SMS creada',
  'notificationSettings.toast.email.create.success': 'Notificación por correo electrónico creada',
  'notificationSettings.action.recipient.add': 'Añadir destinatario',
  'notificationSettings.action.recipient.remove': 'Borrar destinatario',

  // common form errors
  'formValidation.error.email.email': 'Introduzca una dirección de correo electrónico válida.',
  'formValidation.error.sms.sms': 'BIntroduzca un número de teléfono válido. El número de teléfono comienza por "+" o "00" y no puede contener más caracteres especiales aparte del "+"',
  'formValidation.error.password.matches': 'La contraseña debe contener al menos 8 caracteres, así como una letra mayúscula y un carácter especial.',
  'formValidation.error.password.required': 'La contraseña nueva no puede estar vacía..',
  'formValidation.error.companyId.required': 'El número de cliente no puede estar vacío.',
  'formValidation.error.terms.required': 'Acepte la política de privacidad.',
  'formValidation.error.email.required': 'La dirección de correo electrónico no puede estar vacía.',
  'formValidation.error.energyCosts.required': 'Los costes energéticos no pueden estar vacíos.',

  // common form fields
  'form.field.email': 'Correo electrónico',
  'form.field.firstName': 'Nombre',
  'form.field.title': 'Título',
  'form.field.lastName': 'Apellidos',
  'form.field.password': 'Contraseña',
  'form.field.oldPassword': 'Contraseña actual',
  'form.field.newPassword': 'Nueva contraseña',
  'form.field.companyId': 'Número de cliente',
  'form.field.salutation': 'Saludo',
  'form.field.mobileNumber': 'Número de teléfono (móvil)',
  'form.field.phoneNumber': 'Número de teléfono',
  'form.field.street': 'Código postal',
  'form.field.addressPostalCode': 'Población',
  'form.field.addressCity': 'Ubicación',
  'form.field.country': 'País',
  'form.field.language': 'Lengua',
  'form.field.homepage': 'Página web',
  'form.field.faxNumber': 'Fax',
  'form.field.shredderId': 'Número de serie',
  'form.field.companyName': 'Nombre de la empresa',
  'form.field.warranty': 'Garantía',
  'form.field.unitSystem': 'Sistema de unidades',
  'form.field.terms': 'Yo (cliente o persona de contacto del cliente) estoy de acuerdo con el <a href="https://www.untha.com/de/datenschutz#aws" target="_blank">tratamiento de mis datos</a> y con la <a href="https://www.untha.com/de/datenschutz" target="_blank">política de privacidad</a> vigente en el curso de la utilización de MyUNTHA.',
  'form.field.timeFormat': 'Formato de hora',


  // ShredderDataTile
  'shredderDataTile.body.count': `{count, plural,
    =0 {Sin conjuntos de datos}
    one {1 conjunto de datos}
    other {{count} conjuntos de datos}
  }`,

  // PasswordField
  'passwordField.hint.body': 'La contraseña debe contener al menos 8 caracteres, así como una letra mayúscula y un carácter especial.',
  'passwordField.action.password.show': 'Mostrar contraseña',
  'passwordField.action.password.hide': 'Ocultar contraseña',

  // SignUp
  'signUp.form.field.companyId.hint.body': '¿No encuentra el número de cliente? Póngase en contacto con nosotros en el +43 6244 7016 0 o info@untha.com, estaremos encantados de ayudarle con el registro.',

  // SignIn
  'signIn.toast.spam': 'La inscripción ya está en marcha.',

  // NotificationCard
  'notificationCard.action.show': 'Ver mensajes',
  'notificationCard.title': `{count, plural,
    =0 {Ningún mensaje}
    one {1 mensaje}
    other {{count} mensajes}
  }`,
  'notificationCard.body': 'para esta trituradora',


  // PasswordSettings
  'passwordSettings.action.update': 'Actualizar contraseña',
  'passwordSettings.update.success': 'Contraseña actualizada',
  'passwordSettings.update.error': 'Contraseña incorrecta',

  // Announcer
  'announcer.action.read': 'No mostrar más',

  // HistoryChart
  'historyChart.empty': 'No hay datos disponibles para esta selección.',
  'historyChart.action.reselect': 'Nueva selección',
  'historyChart.error': 'Se ha producido un error al procesar los datos históricos. Vuelva a intentarlo más tarde o póngase en contacto con el servicio de atención al cliente de UNTHA.',

  // Service Notifications
  'service.admin.createSubUser.success': 'El usuario se ha creado correctamente.',
  'service.admin.createSubUser.error.duplicate': 'El usuario ya existe.',
  'service.admin.deleteSubUser.success': 'El usuario ha sido eliminado con éxito.',
  'service.admin.deleteSubUser.error.permission': 'El usuario administrador no debe ser eliminado.',
  'service.admin.createSubUser.error': '¡Error al crear el usuario!',
  'service.admin.deleteSubUser.error': '¡Error al borrar el usuario!',
  'service.profile.update.success': 'Perfil actualizado',
  'service.auth.resetPassword.success': 'La contraseña se ha restablecido correctamente.',
  'service.auth.resetPassword.error': '¡Fallo al restablecer la contraseña!',
  'service.auth.newPassword.success': 'La contraseña se ha actualizado correctamente.',
  'service.auth.newPassword.error': '¡Fallo al actualizar la contraseña!',
  'service.notification.general.error': 'Se ha producido un error al procesar las notificaciones.',

  // Downloads
  'downloads.headline.fallback': 'Descargas',

  // currencies
  'currencies.eur': 'Euro',
  'currencies.usd': 'Dólar EE. UU.',
  'currencies.gbp': 'Libra esterlina',
  'currencies.jpy': 'Yen',

  // AdditionalEnergyMeasurement
  'additionalEnergyMeasurement.entries.HOR_ENERGY.label': 'Cinta horizontal',
  'additionalEnergyMeasurement.entries.HYDR_ENERGY.label': 'Sistema hidráulico',
  'additionalEnergyMeasurement.entries.CHILLER_ENERGY.label': 'Sistema de Enfriamiento',
  'additionalEnergyMeasurement.entries.RISE_ENERGY.label': 'Cinta ascendente',
  'additionalEnergyMeasurement.entries.MAGN_ENERGY.label': 'Separador magnético',
  'additionalEnergyMeasurement.entries.HYDCOOL_ENERGY_ACTIVE.label': 'Refrigeración hidráulica',

  // Animated Brand
  'animatedBrand.cta.label': 'ir a la página web',

  // EmptySearch
  'emptySearch.headline': 'Sin resultados de búsqueda',

  // Tag
  'tag.new.text': 'nuevo ahora',
  'tag.newAbbr.text': 'nuevo',

  // ShredderHero
  'shredderHero.cta.live.label': 'Ver datos en tiempo real',
  'shredderHero.cta.manual.label': 'Manual de servicio',

  // 404 Page
  '404.card.headline': '404 - NOT FOUND OR SHREDDED',
  '404.card.text': 'Lamentablemente, una de nuestras trituradoras se ha tragado la página y por eso no la encontramos.',

  // salutations
  'salutation.mr': 'Sr.',
  'salutation.mrs': 'Sra.',

  // User Details
  'userDetails.action.update': 'Actualizar datos',
  'userDetails.fallback.text': 'Cargando perfil...',

  // User Table
  'userTable.dialog.delete.title': 'Borrar usuario',
  'userTable.dialog.delete.headline': 'Atención',
  'userTable.dialog.delete.text': 'Se eliminará el usuario "{firstName} {lastName}" y no podrá restablecerse.',

  // Contact Callout
  'contactCallout.headline': '¿Tiene alguna pregunta?',
  'contactCallout.text': 'Con mucho gusto atenderé su petición.',

  // Notification Details
  'notificationDetails.title': 'Detalles de alarma',
  'notificationDetails.reason': 'Causa posible',
  'notificationDetails.stepByStep': 'Instrucciones paso a paso',

  // Main Menu
  'mainMenu.headline.slice.1': 'MyUNTHA',
  'mainMenu.headline.slice.2': 'Kundenportal',
  'mainMenu.salutation': 'Vista general de sus trituradoras',
  'mainMenu.action.pirate.logout': 'Vista de cliente desactivada',

  // Nav Items
  'navItem.admin.title': 'Administración',
  'navItem.admin.description': 'Administración, importación, exportación',
  'navItem.dashboard.title': 'MySHREDDER',
  'navItem.dashboard.description': 'Vista general de sus trituradoras',
  'navItem.notifications.title': 'Mensajes',
  'navItem.notifications.description': 'Alarmas e incidencias',
  'navItem.report.title': 'Report',
  'navItem.report.description': 'Creación y gestión de informes de rendimiento',
  'navItem.explorer.title': 'Data Explorer',
  'navItem.explorer.description': 'Análisis detallado de todos los datos operativos y de proceso',
  'navItem.profile.title': 'MyPROFIL',
  'navItem.profile.description': 'Datos de usuario y ajustes',
  'navItem.faq.title': 'FAQ',
  'navItem.faq.description': 'Preguntas frecuentes',

  // date stuff
  'date.days': 'Días',
  'date.weeks': 'Semanas',
  'date.months': 'Meses',
  'date.day': 'Día',
  'date.week': 'Semana',
  'date.month': 'Mes',

  // ShredderStatus
  'shredderStatus.since': 'desde',
  'shredderStatus.status.active.label': '<strong>Producción</strong>',
  'shredderStatus.status.idle.label': '<strong>Marcha en vacío</strong>',
  'shredderStatus.status.rest.label': '<strong>Parada</strong>',
  'shredderStatus.status.overload.label': '<strong>Inversión</strong> Sobrecarga',
  'shredderStatus.status.cleaning.label': '<strong>Inversión</strong> Limpieza del rotor',
  'shredderStatus.status.defect.label': '<strong>Fallo</strong> Máquina',
  'shredderStatus.status.defectExternal.label': '<strong>Fallo</strong> Externo',
  'shredderStatus.status.maintenance.label': '<strong>Mantenimiento</strong>',
  'shredderStatus.status.standby.label': '<strong>Standby</strong>',
  'shredderStatus.status.offline.label': '<strong>Offline</strong>',
  'shredderStatus.status.eco.label': '<strong>Modo ECO</strong>',

  // ReportForm
  'reportForm.type.single.title': 'Informe único',
  'reportForm.type.single.description': 'Recibirá el resultado del informe de rendimiento una única vez por correo electrónico. Tenga en cuenta que generar el informe puede llevar hasta 10 minutos.',
  'reportForm.type.scheduled.title': 'Informe automático',
  'reportForm.type.scheduled.description': 'Todos los días o períodos de tiempo seleccionados se indicarán en el informe de rendimiento. El informe se envía cada semana una hora después del último momento seleccionado (si el último momento es, p. ej., el jueves entre las 16:00 y las 18:00, el informe se enviará el jueves a las 19:00).',
  'reportForm.shredder.title': 'Trituradora',
  'reportForm.shredder.description': 'Seleccione la trituradora para la que deba generarse el informe de rendimiento.',
  'reportForm.period.title': 'Período de tiempo',
  'reportForm.period.description': 'Los informes de rendimiento pueden abarcar un período de hasta 8 horas. Son posibles informes con 4 meses de antigüedad.',
  'reportForm.materialDensity.title': 'Densidad de material (opcional)',
  'reportForm.materialDensity.description': 'La densidad de material se utiliza para calcular el rendimiento.',
  'reportForm.materialWeight.title': 'Masa procesada en el período indicado (opcional)',
  'reportForm.materialWeight.description': 'El peso se utiliza para calcular los indicadores de rendimiento básicos.',
  'reportForm.energyCosts.title': 'Costes energéticos',
  'reportForm.energyCosts.description': 'Los costes energéticos se utilizan para calcular los costes totales y los costes por tonelada.',
  'reportForm.shredder.select.placeholder': 'Seleccione una trituradora',
  'reportForm.technical.title': 'Representación',
  'reportForm.technical.description': 'Solo se emiten curvas características técnicas si el periodo seleccionado es de máximo 8 horas.',
  'reportForm.technical.checkbox.label': 'Mostrar curvas características técnicas',

  // ScheduledReports
  'scheduledReports.modal.title': 'Editar informe de rendimiento',
  'scheduledReports.noResults': 'Ningún informe automático.',
  'scheduledReports.limit': '{count} de {limit} informes automáticos',

  // CreatePreset
  'createPreset.title.create': 'Guardar configuración actual como favorito',
  'createPreset.title.update': 'Actualizar el favorito actual',

  // ExplorerKickoffCard
  'explorerKickoffCard.headline': 'Seleccionar puntos de datos',
  'explorerKickoffCard.body': 'Con el explorador de datos se pueden comparar diferentes puntos de datos o valores de procesos de una trituradora. Seleccione primero los puntos de datos que desea comparar.',
  'explorerKickoffCard.action.choose': 'Seleccionar puntos de datos',

  // ExplorerPreset
  'explorerPreset.body': `{count, plural,
    =0 {Ningún punto de datos}
    one {1 punto de datos}
    other {{count} Puntos de datos}
  }`,

  // ExplorerLayout
  'explorerLayout.breadcrumb': 'Explorador de datos',
  'explorerLayout.action.realtime': 'Tiempo real',
  'explorerLayout.action.data': 'Puntos de datos',
  'explorerLayout.action.favorites': 'Favoritos',
  'explorerLayout.aggregation.title': 'Resolución temporal',

  // ExplorerEntry
  'explorerEntry.controls.filter.switch.costs': 'Ver costes energéticos',
  'explorerEntry.controls.filter.switch.activePower': 'Ver potencia aparente',
  'explorerEntry.emptySearch.text': 'No hay datos disponibles para el periodo que ha seleccionado.',

  'units.symbol.celsius': '°C',
  'units.symbol.fahrenheit': '°F',
  'units.symbol.voltage': 'V',
  'units.symbol.rpm': 'rpm',
  'units.symbol.watt': 'W',
  'units.symbol.kilowatt': 'kW',
  'units.symbol.voltampere': 'VA',
  'units.symbol.kva': 'kVA',
  'units.symbol.kwh': 'kWh',
  'units.symbol.microsiemensPerMeter': 'µS/m',
  'units.symbol.percentage': '%',
  'units.symbol.hours': 'h',
  'units.symbol.newton': 'N',
  'units.symbol.torque': 'Nm',
  'units.symbol.bar': 'bar',
  'units.symbol.psi': 'psi',
  'units.symbol.count': 'x',
  'units.symbol.ampere': 'A',
  'units.symbol.acceleration': 'm/s^2',
  'units.symbol.frequency': 'Hz',

  'units.label.celsius': 'Celsius',
  'units.label.fahrenheit': 'Fahrenheit',
  'units.label.voltage': 'Voltio',
  'units.label.rpm': 'Revoluciones por minuto',
  'units.label.watt': 'Vatio',
  'units.label.kilowatt': 'Kilovatio',
  'units.label.voltampere': 'Voltiamperio',
  'units.label.kva': 'Kilovolt Ampere',
  'units.label.microsiemensPerMeter': 'Microsiemens por metro',
  'units.label.fillLevel': 'Nivel de llenado',
  'units.label.hours': 'Horas',
  'units.label.newton': 'Newton',
  'units.label.torque': 'Par de giro',
  'units.label.permittivity': 'Permitividad',
  'units.label.percentage': 'Porcentaje',
  'units.label.bar': 'Bar',
  'units.label.ampere': 'Amperio',
  'units.label.psi': 'Libra por pulgada cuadrada',
  'units.label.acceleration': 'Aceleración',
  'units.label.frequency': 'Hertz',

  'unitSystem.metricUnits': 'métrico',
  'unitSystem.imperialUnits': 'angloamericano',

  'datePicker.presets.today': 'Hoy',
  'datePicker.presets.yesterday': 'Ayer',
  'datePicker.presets.past3Days': 'Últimos 3 días',
  'datePicker.presets.past7Days': 'Última semana',

  'timeFormat.24h': '24 horas',
  'timeFormat.12h': '12 horas',

  'additionalEnergyMeasurement.title.front': 'Consumo energético de grupos adicionales',
  'additionalEnergyMeasurement.title.back': 'Costes energéticos de grupos adicionales',
  'additionalEnergyMeasurement.entries.HORCONVEYOR_ENERGY.label': 'Cinta horizontal',
  'additionalEnergyMeasurement.entries.RAISECONVEYOR_ENERGY.label': 'Cinta ascendente',
  'additionalEnergyMeasurement.entries.MAGCONVEYOR_ENERGY.label': 'Separador magnético',

  'csvImport.step.companies': 'Empresas',
  'csvImport.step.users': 'Usuarios',
  'csvImport.step.shredders': 'Trituradoras',
  'csvImport.step.shredderAttributes': 'Características',
  'csvImport.step.salesPersons': 'Vendedores',
  'csvImport.dropArea.headline': 'Arrastrar aquí archivo CSV',
  'csvImport.dropArea.body': 'o',
  'csvImport.dropArea.cta': 'Seleccionar archivo CSV',
  'csvImport.warning': 'Advertencia: Al realizar la importación, se sustituye la base de datos actual.',
  'csvImport.action.upload.success': '{fileName} cargado correctamente.',
  'csvImport.action.upload.error': 'Se ha producido un error al actualizar los datos. Vuelva a intentarlo más tarde o póngase en contacto con el servicio de atención al cliente de UNTHA.',
  'csvImport.action.complete.success': 'Todos los archivos se han cargado correctamente',

  'csvExport.data.companies': 'Empresas',
  'csvExport.data.users': 'Usuarios',
  'csvExport.data.shredders': 'Trituradoras',
  'csvExport.data.shredderAttributes': 'Características',
  'csvExport.data.salesPersons': 'Vendedores',

  'stateOneMeasurement.preset.last24Hours': 'Últimas 24 horas',
  'stateOneMeasurement.preset.prev7Days': 'Últimos 7 días',
  'stateOneMeasurement.preset.prev30Days': 'Últimos 30 días',
  'stateOneMeasurement.state.active.label': 'Producción',
  'stateOneMeasurement.state.idle.label': 'Marcha en vacío',
  'stateOneMeasurement.state.standby.label': 'Standby',
  'stateOneMeasurement.state.rest.label': 'Parada',
  'stateOneMeasurement.state.overload.label': 'Inversión sobrecarga',
  'stateOneMeasurement.state.cleaning.label': 'Inversión limpieza del rotor',
  'stateOneMeasurement.state.defect.label': 'Fallo máquina',
  'stateOneMeasurement.state.defectExternal.label': 'Fallo externo',
  'stateOneMeasurement.state.maintenance.label': 'Mantenimiento',
  'stateOneMeasurement.state.offline.label': 'Offline',
  'stateOneMeasurement.state.eco.label': 'Modo ECO',

  'aggregation.minutely': 'por minuto',
  'aggregation.secondly': 'por segundo',
  'aggregation.hourly': 'por hora',

  'energyCosts.currency.title': 'Divisa',
  'energyCosts.pricePerkWh.title': 'Precio por kWh',
  'energyCosts.action.update.success': 'Ajustes para costes energéticos actualizados correctamente',

  'explorerAnnouncement.title': 'Tutorial de explorador de datos',
  'explorerAnnouncement.body': 'El explorador de datos le permite enfrentar diferentes datos de funcionamiento y proceso y compararlos entre sí.',
  'explorerAnnouncement.videoSrc.webm': '/videos/tutorials/untha_tutorial_data_explorer_en.webm',
  'explorerAnnouncement.videoSrc.mp4': '/videos/tutorials/untha_tutorial_data_explorer_en.mp4',

  'explorerSupportCard.headline': '¡Atención!',
  'explorerSupportCard.body': 'Lamentablemente, esta función no está disponible en este equipo. Cambie a un equipo con un tamaño mínimo de 768 píxeles.',

  'useShredderSub.handshake.error': 'No se ha podido establecer la conexión a datos en tiempo real',
  'useShredderSub.subscription.error': 'Error en conexión a datos en tiempo real',

  'reportAnnouncement.title': 'Tutorial de informes de rendimiento',
  'reportAnnouncement.body':
    'Los informes de rendimiento le permiten crear de un modo sencillo una vista general de los datos de la máquina. Los datos reunidos se envían como PDF a una dirección de correo electrónico indicada, bien una sola vez o bien semanalmente.',
  'reportAnnouncement.videoSrc.webm': '/videos/tutorials/untha_tutorial_report_en.webm',
  'reportAnnouncement.videoSrc.mp4': '/videos/tutorials/untha_tutorial_report_en.mp4',

  'explorerZoom.action.reset': 'Restablecer zoom',

  'uiSettings.update.success': 'Interfaz de usuario actualizada correctamente',

  'notificationTable.search.input.placeholder': 'Buscar alarma...',

  // TimestampIndicator
  'timestampIndicator.live': 'Datos actuales',
  'timestampIndicator.recent': 'última actualización hace {timestamp}',

  'pdfReport.header.label': 'Informe de rendimiento',
  'pdfReport.header.machine': 'Máquina',
  'pdfReport.header.timerange': 'Período de tiempo',
  'pdfReport.logBook.header': 'Libro de registro',
  'pdfReport.stateOne.entryHeader': 'Estado',
  'pdfReport.stateOne.states.active': 'Producción',
  'pdfReport.stateOne.states.idle': 'Marcha en vacío',
  'pdfReport.stateOne.states.standby': 'Standby',
  'pdfReport.stateOne.states.rest': 'Parada',
  'pdfReport.stateOne.states.overload': 'Inversión sobrecarga',
  'pdfReport.stateOne.states.cleaning': 'Inversión limpieza del rotor',
  'pdfReport.stateOne.states.defect': 'Fallo máquina',
  'pdfReport.stateOne.states.defectExternal': 'Fallo externo',
  'pdfReport.stateOne.states.maintenance': 'Mantenimiento',
  'pdfReport.stateOne.states.offline': 'Offline',
  'pdfReport.stateOne.states.eco': 'Modo ECO',
  'pdfReport.globalParams.revUnplanned': 'Inversiones por sobrecarga',
  'pdfReport.globalParams.operatingHours': 'Horas de servicio (al final del período de observación)',
  'pdfReport.globalParams.disruptiveOffActive': 'Desconexiones por impurezas',
};

export default messages;
