import { atom } from 'recoil';
import { localStorageEffect } from 'src/states/effects/localStorage';

// todo: pull local storage effect @joh

/**
 * Current pirated companyId also persisted in the localStorage
 * @default undefined
 */
export const pirateIdState = atom<string | undefined>({
  key: 'PIRATE/id',
  default: undefined,
  effects_UNSTABLE: [localStorageEffect('PIRATE/id')],
});
