import axios from 'axios';

type LogError = (payload: {
  errorCode: string;
  message: string;
  isPublic: boolean;
}) => Promise<void>;

export const logError: LogError = (payload) =>
  axios.post(process.env.REACT_APP_LOG_API_URL_V3 + '/errorlog', payload);
