import React from 'react';

type ProfileIconProps = {
  firstName: string;
  lastName?: string;
};

export const ProfileIcon = ({
  firstName,
  lastName,
  ...rest
}: ProfileIconProps): React.ReactElement => {
  return (
    <svg
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      viewBox="0 0 24 24"
      {...rest}
    >
      <circle r={12} fill="currentColor" cx={12} cy={12} />
      <text
        x={12}
        y={12}
        dy={1.5}
        fontSize="11"
        fontWeight="bold"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="var(--color-white)"
      >
        <tspan>{firstName[0].toUpperCase()}</tspan>
        {lastName !== undefined && <tspan>{lastName[0].toUpperCase()}</tspan>}
      </text>
    </svg>
  );
};
