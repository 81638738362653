import { DefaultValue } from 'recoil';
import type { AtomEffect } from 'recoil';

export function localStorageEffect<T extends string>(
  key: string,
): AtomEffect<T | undefined> {
  return ({ onSet, trigger, setSelf }) => {
    // rehydrate from localStorage
    if (trigger === 'get') {
      const savedValue = localStorage.getItem(key);

      if (savedValue !== null) {
        try {
          setSelf(JSON.parse(savedValue));
        } catch (e) {
          console.error('Error executing localStorage effect with value', savedValue, e);
        }
      }
    }

    // sync state updates with localStorage
    onSet((newValue) => {
      if (newValue instanceof DefaultValue || newValue === undefined) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };
}
