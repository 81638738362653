import axios from 'axios';
import { getIANATimeZoneName } from 'src/helpers';
import * as rax from 'retry-axios';
import { getAuthToken } from './auth';

export function configure(): void {
  // add 3 retries by default
  // see: https://www.npmjs.com/package/retry-axios
  rax.attach();

  axios.defaults.raxConfig = {
    httpMethodsToRetry: ['GET', 'POST', 'PUT', 'DELETE', 'OPTIONS', 'HEAD'],
    statusCodesToRetry: [[401, 401]],
  };

  // add timezone header
  axios.interceptors.request.use((config) => {
    config.headers['x-untha-time-zone'] = getIANATimeZoneName();
    return config;
  });

  // add cognito auth token
  axios.interceptors.request.use(async (config) => {
    const token = await getAuthToken();

    if (token && !config.url?.includes('pdf-export')) {
      config.headers.Authorization = token;
    } else {
      delete config.headers.Authorization;
    }

    return config;
  });
}
