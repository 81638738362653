import type { Locale } from 'src/types';
import deepmerge from 'deepmerge';
import de from 'src/translations/de';
import en from 'src/translations/en';
import fr from 'src/translations/fr';
import it from 'src/translations/it';
import es from 'src/translations/es';

export const dict: Record<Locale, any> = {
  en, // default language
  de: deepmerge(en, de),
  fr: deepmerge(en, fr),
  it: deepmerge(en, it),
  es: deepmerge(en, es),
};
