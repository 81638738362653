// prettier-ignore
const messages = {
  // project meta
  'project.title': 'MyUNTHA',
  'project.brand': 'UNTHA',
  'project.brandPhrase': 'shredding technology',
  'project.brandSub': 'Votre portail client personnel',

  'locale.en': 'Anglais',
  'locale.de': 'Allemand',
  'locale.es': 'Espagnol',
  'locale.fr': 'Français',
  'locale.it': 'Italien',

  // pages
  'page.dashboard.breadcrumb': 'Panneau de commande',
  'page.admin.breadcrumb': 'Administration',
  'page.admin.section.management': 'Gestion',
  'page.admin.section.data': 'Base de données',
  'page.admin.companyTable.headline': 'Client',
  'page.admin.csvImport.headline': 'Importation',
  'page.admin.csvExport.headline': 'Exportation',
  'page.shredder.section.overview': 'Vue d’ensemble',
  'page.shredder.section.records': 'Paramètres mesurés',
  'page.shredder.downloads': 'Téléchargements',
  'page.shredders.empty': 'Aucun broyeur disponible pour l’instant.',
  'page.shredders.section.shredder': '{count, plural,\n    one {Votre broyeur}\n    other {Vos broyeurs}\n  }',
  'page.notifications.title': 'Messages',
  'page.notifications.breadcrumb': 'Messages',
  'page.notifications.section.notifications': 'Messages actuels',
  'page.notifications.section.notifications.alarms': 'Alarmes',
  'page.notifications.section.notifications.events': 'Evénements',
  'page.notifications.section.settings': 'Réglages',
  'page.notifications.section.settings.notifications': 'Notifications',
  'page.login.headline': 'Connecter',
  'page.login.body': 'Pas encore de compte?',
  'page.login.forgotPassword': 'Mot de passe oublié?',
  'page.register.headline': 'S’enregistrer',
  'page.register.body': 'Vous êtes client d’UNTHA et vous n’avez pas encore d’accès au portail UNTHA? Veuillez vous enregistrer ici.',
  'page.register.back': 'Déjà enregistré?',
  'page.register.toast.error': 'Une erreur s’est produite pendant l’enregistrement. Veuillez ré-essayer ultérieurement ou vous adresser au service d’assistance UNTHA.',
  'page.register.toast.success': 'Enregistrement réussi',
  'page.forgotPassword.headline': 'Réinitialiser le mot de passe',
  'page.forgotPassword.body': 'Veuillez entrer votre adresse e-mail et votre numéro client, nous vous enverrons un mot de passe temporaire. Lors de votre première connexion, vous pourrez choisir un nouveau mot de passe.',
  'page.forgotPassword.back': 'Retour à la connexion',
  'page.newPassword.headline': 'Actualiser le mot de passe',
  'page.newPassword.body': 'Veuillez choisir le mot de passe pour les connexions suivantes.',
  'page.report.breadcrumb': 'Rapport',
  'page.report.title': 'Rapports de performances',
  'page.report.history': 'Derniers rapports de performances',
  'page.report.scheduled': 'Rapports de performances automatiques',
  'page.explorer.manage.breadcrumb': 'Points de données',
  'page.explorer.manage.search.placeholder': 'Recherche de points de données...',
  'page.explorer.manage.search.empty': 'Aucun résultat.',
  'page.explorer.manage.limit': 'Le nombre maximum de points de données a été atteint.',
  'page.explorer.intro.section.favorites': 'Favoris',
  'page.explorer.presets.action.load': 'Charger le favori',
  'page.explorer.presets.placeholder': 'Aucun favori existant',
  'page.explorer.presets.title': 'Favoris',
  'page.registrationConfirmation.section.success.headline': 'Enregistrement finalisé',
  'page.registrationConfirmation.section.success.body': 'La procédure d’enregistrement est terminée. Vous pouvez vous déconnecter.',
  'page.registrationConfirmation.section.error.headline': 'Échec de l’enregistrement',
  'page.registrationConfirmation.section.error.body': 'Échec de la procédure d’enregistrement. Veuillez contacter l’administrateur de votre entreprise.',

  // Table
  'table.th.email': 'E-Mail',
  'table.th.company': 'Nom de société',
  'table.th.number': 'Numéro',
  'table.th.alarm': 'Alarm',
  'table.th.priority': 'Priorité',
  'table.th.sms': 'SMS',
  'table.th.period': 'Période',
  'table.th.reportType': 'Type',
  'table.th.file': 'Fichier',
  'table.th.shredder': 'Broyeur',
  'table.th.id': 'ID',
  'table.th.status': 'Statut',

  // common actions
  'action.disable': 'Désactiver',
  'action.enable': 'Activer',
  'action.login': 'Connecter',
  'action.register': 'S’enregistrer',
  'action.edit': 'Éditer',
  'action.delete': 'Effacer',
  'action.cancel': 'Annuler',
  'action.create': 'Créer',
  'action.save': 'Enregistrer',
  'action.saveChanges': 'Enregistrer les modifications',
  'action.revert': 'Restaurer',
  'action.download': 'Télécharger',
  'action.upload': 'Charger',
  'action.update': 'Actualiser',
  'action.close': 'Fermer',
  'action.reset': 'Réinitialiser',
  'action.add': 'Ajouter',

  // common states
  'state.enabled': 'active',

  // shredder attributes
  'shredderAttr.ENERGY_SUPPLY.label': 'Consommation d’énergie',
  'shredderAttr.HOR_POWER_ACTIVE.label': 'Convoyeur horizontal puissance active',
  'shredderAttr.ENERGY_SUPPLY_COSTS.label': 'Coûts énergétiques',
  'shredderAttr.CHILLER_POWER_APPARENT.label': 'Refroidissement à eau puissance apparente',
  'shredderAttr.RISE_POWER_ACTIVE.label': 'Convoyeur incliné puissance actichillerPowerApparentve',
  'shredderAttr.RISE_ENERGY.label': 'Convoyeur incliné consommation d’énergie',
  'shredderAttr.OIL_LIFETIME_HYDR.label': 'Durée de vie restante huile hydraulique',
  'shredderAttr.MAGN_ENERGY_COSTS.label': 'Séparateur magnétique coûts énergétiques',
  'shredderAttr.HEATPLATE_TEMP2.label': 'Température du dissipateur thermique 2',
  'shredderAttr.HEATPLATE_TEMP1.label': 'Température du dissipateur thermique 1',
  'shredderAttr.MAGN_POWER_ACTIVE.label': 'Séparateur magnétique puissance active\',sten',
  'shredderAttr.OIL_CONDUCTIVITY_HYDR.label': 'Conductivité huile hydraulique',
  'shredderAttr.ROTOR_REVOLUTION.label': 'Régime du rotor',
  'shredderAttr.ROTOR_REVOLUTION1.label': 'Régime du rotor 1',
  'shredderAttr.ROTOR_REVOLUTION2.label': 'Régime du rotor 2',
  'shredderAttr.OIL_TEMPERATURE_HYDR.label': 'Huile hydraulique température',
  'shredderAttr.POWER_APPARENT_ACT1.label': 'Scheinleistung Motor 1',
  'shredderAttr.COOLING_TEMPIN.label': 'Refroidissement à eau température retour',
  'shredderAttr.TORQUE_ROT.label': 'Couple du moteur',
  'shredderAttr.TORQUE_ROT1.label': 'Couple du moteur 1',
  'shredderAttr.TORQUE_ROT2.label': 'Couple du moteur 2',
  'shredderAttr.OIL_PERMITIVITY_HYDR.label': 'Permittivité huile hydraulique',
  'shredderAttr.CHILLER_PB4.label': 'Température ambiante',
  'shredderAttr.CHILLER_POWER_ACTIVE.label': 'Refroidissement à eau puissance active',
  'shredderAttr.CHILLER_PB3.label': 'Pression du condensateur',
  'shredderAttr.CHILLER_PB2.label': 'Refroidissement température entrée',
  'shredderAttr.UEFF_L23.label': 'Tension conducteur U23',
  'shredderAttr.CHILLER_PB1.label': 'Evaporateur température',
  'shredderAttr.CHILLER_ENERGY_COSTS.label': 'Refroidissement à eau coûts énergétiques',
  'shredderAttr.HOR_ENERGY_COSTS.label': 'Convoyeur horizontal coûts énergétiques',
  'shredderAttr.HYDR_POWER_APPARENT.label': 'Hydraulique puissance apparente',
  'shredderAttr.MAGN_ENERGY.label': 'Séparateur magnétique consommation d’énergie',
  'shredderAttr.OIL_LEVEL_HYDR.label': 'Niveau d‘huile hydraulique',
  'shredderAttr.UEFF_L31.label': 'Tension conducteur U31',
  'shredderAttr.POWER_ACTIVE.label': 'Puissance active',
  'shredderAttr.HOR_ENERGY.label': 'Convoyeur horizontal consommation d’énergie',
  'shredderAttr.CNT_REVERSING.label': 'Compteur des inversions',
  'shredderAttr.FUNNEL_LEVEL.label': 'Niveau de remplissage de la trémie',
  'shredderAttr.HYDR_ENERGY.label': 'Hydraulique consommation d’énergie',
  'shredderAttr.HYDR_ENERGY_COSTS.label': 'Hydraulique coûts énergétiques',
  'shredderAttr.COOLING_TEMPOUT.label': 'Refroidissement à eau température aller',
  'shredderAttr.OIL_HUMIDITY_HYDR.label': 'Huile hydraulique humidité relative',
  'shredderAttr.UEFF_L12.label': 'Tension conducteur U12',
  'shredderAttr.MAGN_POWER_APPARENT.label': 'Séparateur magnétique puissance apparente',
  'shredderAttr.CHILLER_ENERGY.label': 'Refroidissement à eau consommation d’énergie',
  'shredderAttr.POWER_APPARENT.label': 'Puissance apparente',
  'shredderAttr.HYDR_POWER_ACTIVE.label': 'Hydraulique puissance active',
  'shredderAttr.CNT_SERVICEFLAP.label': 'Compteur clapet d’entretien ouvert',
  'shredderAttr.OPERATING_HOURS.label': 'Heures de service',
  'shredderAttr.MOTORTEMP_1_SENS.label': 'Température moteur 1',
  'shredderAttr.PUSHER_FORCE.label': 'Force de pression pousseur',
  'shredderAttr.ROTOR_MECH_POWER.label': 'Puissance mécanique rotor',
  'shredderAttr.HOR_POWER_APPARENT.label': 'Convoyeur horizontal puissance apparente',
  'shredderAttr.CNT_CLUTCHSLIP1.label': 'Compteur glissements d’embrayage 1',
  'shredderAttr.CNT_CLUTCHSLIP2.label': 'Compteur glissements d’embrayage 2',
  'shredderAttr.RISE_POWER_APPARENT.label': 'Convoyeur incliné puissance apparente',
  'shredderAttr.OIL_AGE_HYDR.label': 'Age huile hydraulique',
  'shredderAttr.DC_CURRENT2.label': 'Tension circuit intermédiaire 2',
  'shredderAttr.DC_CURRENT1.label': 'Tension circuit intermédiaire 1',
  'shredderAttr.HYDCOOL_ENERGY.label': 'Hydraulique refroidissement consommation d’énergie',
  'shredderAttr.HYDCOOL_ENERGY_COSTS.label': 'Hydraulique refroidissement coûts énergétiques',
  'shredderAttr.HYDCOOL_ENERGY_ACTIVE.label': 'Hydraulique refroidissement puissance active',
  'shredderAttr.HYDCOOL_ENERGY_ACTIVE_COSTS.label': 'Hydraulique refroidissement consommation d’énergie',
  'shredderAttr.HYDCOOL_POWER_APPARENT.label': 'Hydraulique refroidissement puissance apparente',
  'shredderAttr.HORCONVEYOR_POWER_ACTIVE.label': 'Convoyeur horizontal puissance active',
  'shredderAttr.RAISECONVEYOR_POWER_ACTIVE.label': 'Convoyeur incliné puissance active',
  'shredderAttr.RAISECONVEYOR_ENERGY.label': 'Convoyeur incliné consommation d’énergie',
  'shredderAttr.MAGCONVEYOR_ENERGY_COSTS.label': 'Séparateur magnétique coûts énergétiques',
  'shredderAttr.MAGCONVEYOR_POWER_ACTIVE.label': 'Séparateur magnétique puissance active',
  'shredderAttr.GEAR_REVOLUTION1.label': 'GEAR_REVOLUTION1',
  'shredderAttr.GEAR_REVOLUTION2.label': 'GEAR_REVOLUTION2',
  'shredderAttr.RAISECONVEYOR_ENERGY_COSTS.label': 'Convoyeur incliné coûts énergétiques',
  'shredderAttr.HORCONVEYOR_ENERGY_COSTS.label': 'Convoyeur horizontal coûts énergétiques',
  'shredderAttr.MAGCONVEYOR_ENERGY.label': 'Séparateur magnétique consommation d’énergie',
  'shredderAttr.GRID_THD.label': 'Qualité du réseau THD',
  'shredderAttr.HORCONVEYOR_ENERGY.label': 'Consommation d’énergie Convoyeur horizontal',
  'shredderAttr.MAGCONVEYOR_POWER_APPARENT.label': 'Puissance apparente Séparateur magnétique',
  'shredderAttr.ENGINE_MECH_POWER2.label': 'Puissance mécanique moteur 2',
  'shredderAttr.ENGINE_MECH_POWER1.label': 'Puissance mécanique moteur 1',
  'shredderAttr.HORCONVEYOR_POWER_APPARENT.label': 'Puissance apparente Convoyeur horizontal',
  'shredderAttr.OIL_HUMIDITY_GEAR2.label': 'Humidité relative Huile à engrenage 2',
  'shredderAttr.OIL_HUMIDITY_GEAR1.label': 'Humidité relative Huile à engrenage 1',
  'shredderAttr.RAISECONVEYOR_POWER_APPARENT.label': 'Convoyeur incliné puissance apparente',
  'shredderAttr.ACC_EFF_BEARING.label': 'Valeur efficace oscillation radiale',
  'shredderAttr.ACTIVE_CURRENT1.label': 'Intensité 1',
  'shredderAttr.ACTIVE_CURRENT2.label': 'Intensité 2',
  'shredderAttr.GEARTEMP_1_SENS.label': 'Température d’engrenages 1',
  'shredderAttr.GEARTEMP_2_SENS.label': 'Température d’engrenages 2',
  'shredderAttr.GENERATOR_FUELLEVEL.label': 'Niveau de remplissage du groupe électrogène',
  'shredderAttr.GENERATOR_POWER.label': 'Puissance active du groupe électrogène',
  'shredderAttr.GENERATOR_VOLTAGE.label': 'Tension du groupe électrogène',
  'shredderAttr.GENERATOR_FREQUENCY.label': 'Fréquence du groupe électrogène',


  // shredder attribute clusters
  'shredderAttrCluster.gearOil1': 'Huile de transmission 1',
  'shredderAttrCluster.gearOil2': 'Huile de transmission 2',
  'shredderAttrCluster.counter': 'Compteur',
  'shredderAttrCluster.hydrOil': 'Huile hydraulique',
  'shredderAttrCluster.engineTotalEnergyConsumption': 'Consommation d’énergie',
  'shredderAttrCluster.engineTotalPowerActive': 'Puissance totale machine',
  'shredderAttrCluster.cntGearSkidding': 'Compteur d’inversions',
  'shredderAttrCluster.cntServiceFlap': 'Compteur clapet d’entretien ouvert',
  'shredderAttrCluster.rotorSpeed': 'Régime du rotor',
  'shredderAttrCluster.rotorTorque': 'Couple du rotor',
  'shredderAttrCluster.rotorMechPower': 'Puissance mécanique rotor',
  'shredderAttrCluster.conductorVoltages': 'Tensions de conducteur',
  'shredderAttrCluster.netQuality': 'Qualité du réseau THD',
  'shredderAttrCluster.engineTorque': 'Couple des moteurs',
  'shredderAttrCluster.engineSpeed': 'Régime des moteurs',
  'shredderAttrCluster.engineTemp': 'Température du moteur',
  'shredderAttrCluster.enginePowerActive': 'Puissance active des moteurs',
  'shredderAttrCluster.cntClutchSkidding': 'Compteur glissements d’embrayage',
  'shredderAttrCluster.gearSpeed': 'Régime des engrenages',
  'shredderAttrCluster.outputVoltage': 'Tension de sortie',
  'shredderAttrCluster.inverterTemp': 'Température onduleur',
  'shredderAttrCluster.heatSinkTemp': 'Température dissipateur thermique',
  'shredderAttrCluster.intCircuitVoltage': 'Tension circuit intermédiaire',
  'shredderAttrCluster.conveyorsEnergyConsumption': 'Consommation d’énergie technique de convoyage',
  'shredderAttrCluster.additionalEnergyConsumption': 'Consommation d’énergie organes auxiliaires',
  'shredderAttrCluster.conveyorsPowerActive': 'Puissance technique de convoyage',
  'shredderAttrCluster.additionalPowerActive': 'Puissance organes auxiliaires',
  'shredderAttrCluster.waterCoolingTemp': 'Températures refroidissement à eau',
  'shredderAttrCluster.oilLevel': 'Niveaux d\'huile',
  'shredderAttrCluster.oilTemp': 'Températures d’huile',
  'shredderAttrCluster.radialVibration': 'Valeur efficace oscillation radiale',
  'shredderAttrCluster.funnelLevel': 'Niveau de remplissage de la trémie',
  'shredderAttrCluster.pusherForce': 'Puissance cylindrique pousseur',
  'shredderAttrCluster.activePower': 'Courant actif',
  'shredderAttrCluster.gearTemp': 'Températures d’engrenages',
  'shredderAttrCluster.generatorFuellevel':'Niveau de remplissage du groupe électrogène',
  'shredderAttrCluster.generatorEffectivePower':'Puissance active du groupe électrogène',
  'shredderAttrCluster.generatorVoltage':'Tension du groupe électrogène',
  'shredderAttrCluster.generatorFrequency':'Fréquence du groupe électrogène',

  // shredder attribute topics
  'shredderAttrTopic.hydraulic': 'Hydraulique',
  'shredderAttrTopic.driveTrain': 'Commande',
  'shredderAttrTopic.powerTrain': 'Commande',
  'shredderAttrTopic.waterCooling': 'Refroidissement à eau',
  'shredderAttrTopic.extras': 'Accessoires',
  'shredderAttrTopic.operatingData': 'Données de service',
  'shredderAttrTopic.pusher': 'Pousseur',
  'shredderAttrTopic.rotor': 'Rotor',
  'shredderAttrTopic.clutch': 'Accouplement',
  'shredderAttrTopic.coolingCircuit': 'Circuit de refroidissement',
  'shredderAttrTopic.horizontalConveyor': 'Convoyeur horizontal',
  'shredderAttrTopic.elevatingConveyor': 'Convoyeur incliné',
  'shredderAttrTopic.magneticSeparator': 'Séparateur magnétique',
  'shredderAttrTopic.rotorShaft': 'Arbre du rotor',
  'shredderAttrTopic.slideCylinder': 'Cylindre du poussoir',
  'shredderAttrTopic.oilPumpDrive': 'Entraînement pompe à huile',
  'shredderAttrTopic.oilPan': 'Carter d’huile',
  'shredderAttrTopic.pusherForce': 'Force de pression pousseur',
  'shredderAttrTopic.chiller': 'Refroidisseur',
  'shredderAttrTopic.engineTotal': 'Machine complète',
  'shredderAttrTopic.cuttingSystem': 'Système de coupe',
  'shredderAttrTopic.energySupply': 'Apport en énergie',
  'shredderAttrTopic.engine': 'Moteur',
  'shredderAttrTopic.gear': 'Engrenages',
  'shredderAttrTopic.frequencyConverter': 'Convertisseur de fréquence',
  'shredderAttrTopic.transportSystem': 'Technique de convoyage',
  'shredderAttrTopic.additional': 'Organes auxiliaires',
  'shredderAttrTopic.operatingSupplies': 'Ressources',
  'shredderAttrTopic.other': 'Divers',
  'shredderAttrTopic.powerUnit': 'Groupe électrogène',

  // notification severity
  'notificationSeverity.0': 'Avertissement',
  'notificationSeverity.1': 'Peu important',
  'notificationSeverity.2': 'Important',
  'notificationSeverity.3': 'Critique',

  // notification acknowledged alarms
  'notificationSeverity.acknowledgedAlarms': 'Alarmes acquittées',

  // common form errors
  'formValidation.error.email.email': 'Veuillez entrer une adresse e-mail valide.',
  'formValidation.error.password.required': 'Le mot de passe ne doit pas être vide.',
  'formValidation.error.companyId.required': 'Le numéro de client ne doit pas être vide.',
  'formValidation.error.terms.required': 'Veuillez accepter les règles de protection des données.',

  // common form fields
  'form.field.email': 'E-Mail',
  'form.field.password': 'Mot de passe',
  'form.field.oldPassword': 'Ancien mot de passe',
  'form.field.newPassword': 'Nouveau mot de passe',
  'form.field.companyId': 'Numéro de client',
  'form.field.unitSystem': 'Système d’unités',
  'form.field.terms': 'Je (client ou interlocuteur du client) déclare accepter, à la suite de l’utilisation de MyUNTHA, <a href="https://www.untha.com/de/datenschutz#aws" target="_blank">le traitement de mes données</a> et les <a href="https://www.untha.com/de/datenschutz" target="_blank">dispositions de protection des données</a> en vigueur.',
  'form.field.timeFormat': 'Format de l’heure',


  // PasswordField
  'passwordField.action.password.show': 'Afficher le mot de passe',
  'passwordField.action.password.hide': 'Masquer le mot de passe',
  'passwordField.hint.body': 'Le mot de passe doit comprendre au moins 8 caractères, ainsi qu’une majuscule et un caractère spécial.',

  // SignUp
  'signUp.form.field.companyId.hint.body': 'SVous ne trouvez pas votre numéro de client ? Veuillez vous adresser au +43 6244 7016 0 ou à info@untha.com – nous vous aiderons pour votre enregistrement.',

  // NotificationCard
  'notificationCard.action.show': 'Afficher les messages',
  'notificationCard.title': `{count, plural,
    =0 {Aucun message}
    one {1 message}
    other {{count} messages}
  }`,
  'notificationCard.body': 'pour ce broyeur',

  // PasswordSettings
  'passwordSettings.update.success': 'Mot de passe mis à jour',
  'passwordSettings.update.error': 'Mot de passe incorrect',
  'passwordSettings.action.update': 'Actualiser le mot de passe',

  // Service Notifications
  'service.admin.createSubUser.success': 'L’utilisateur a bien été créé.',
  'service.admin.createSubUser.error.duplicate': 'Cet utilisateur existe déjà!',
  'service.admin.createSubUser.error': 'Erreur lors de la création de l’utilisateur.',
  'service.admin.deleteSubUser.error': 'Erreur lors de la suppression de l’utilisateur.',
  'service.profile.update.success': 'Profil actualisé',
  'service.auth.resetPassword.success': 'Mot de passe réinitialisé.',
  'service.auth.resetPassword.error': 'Échec de la réinitialisation du mot de passe.',
  'service.auth.newPassword.success': 'Mot de passe mis à jour.',
  'service.auth.newPassword.error': 'Échec de la mise à jour du mot de passe.',
  'service.notification.general.error': 'Une erreur s’est produite pendant le traitement des notifications.',

  // AdditionalEnergyMeasurement
  'additionalEnergyMeasurement.entries.HOR_ENERGY.label': 'Convoyeur horizontal',
  'additionalEnergyMeasurement.entries.HYDR_ENERGY.label': 'Système hydraulique',
  'additionalEnergyMeasurement.entries.CHILLER_ENERGY.label': 'Refroidisseur',
  'additionalEnergyMeasurement.entries.RISE_ENERGY.label': 'Convoyeur incliné',
  'additionalEnergyMeasurement.entries.MAGN_ENERGY.label': 'Séparateur magnétique',
  'additionalEnergyMeasurement.title.front': 'Consommation d’énergie Groupes complémentaires',
  'additionalEnergyMeasurement.title.back': 'Coûts énergétiques Groupes complémentaires',
  'additionalEnergyMeasurement.entries.HORCONVEYOR_ENERGY.label': 'Convoyeur horizontal',
  'additionalEnergyMeasurement.entries.RAISECONVEYOR_ENERGY.label': 'Convoyeur incliné',
  'additionalEnergyMeasurement.entries.MAGCONVEYOR_ENERGY.label': 'Séparateur magnétique',

  // ReportHistory
  'reportHistory.type.scheduled': 'automatique',
  'reportHistory.type.single': 'unique',
  'reportHistory.status.error': 'Erreur',
  'reportHistory.status.pending': 'en service',
  'reportHistory.noResults': 'Aucun rapport précédent.',

  // Create Report
  'createReport.headline': 'Nouveau rapport',
  'createReport.action.create': 'Nouveau rapport de performances',

  // ReportForm
  'reportForm.type.single.title': 'Rapport unique',
  'reportForm.type.single.description': 'Vous recevez le résultat du rapport de performances une seule fois par e-mail. Veuillez noter que la création du rapport peut prendre jusqu’à 10 minutes.',
  'reportForm.type.scheduled.title': 'Rapport automatique',
  'reportForm.type.scheduled.description': 'Chaque jour ou chaque période sélectionné(e) est noté(e) dans le rapport de performances. Le rapport est envoyé chaque semaine, une heure après la dernière période sélectionnée (par exemple, si la dernière période est jeudi de 16h00 à 18h00, alors le rapport est envoyé le jeudi à 19h00).',
  'reportForm.shredder.title': 'Broyeur',
  'reportForm.shredder.description': 'Choisissez le broyeur pour lequel le rapport de performances doit être créé.',
  'reportForm.period.title': 'Période',
  'reportForm.period.description': 'Les rapports de performances peuvent comprendre une période de 8 heures maximum. Les rapports sont possibles 4 mois dans le passé.',
  'reportForm.materialDensity.title': 'Densité des matériaux (option)',
  'reportForm.materialDensity.description': 'La densité des matériaux est utilisée pour le calcul du débit.',
  'reportForm.materialWeight.title': 'Masse traitée dans la période spécifiée (option)',
  'reportForm.materialWeight.description': 'Le poids est utilisé pour le calcul des indicateurs de base.',
  'reportForm.energyCosts.title': 'Coûts énergétiques',
  'reportForm.energyCosts.description': 'Les coûts énergétiques sont utilisés pour le calcul des coûts totaux et des coûts par tonne.',
  'reportForm.shredder.select.placeholder': 'Sélectionnez un broyeur',
  'reportForm.technical.title': 'Représentation',
  'reportForm.technical.description': 'Les courbes caractéristiques techniques ne sont affichées que si la période sélectionnée est de 8 heures ou moins.',
  'reportForm.technical.checkbox.label': 'Afficher les courbes caractéristiques techniques',

  // ScheduledReports
  'scheduledReports.modal.title': 'Éditer le rapport de performances',
  'scheduledReports.noResults': 'Aucun rapport automatique.',
  'scheduledReports.limit': '{count} sur {limit} rapports automatiques',

  // CreatePreset
  'createPreset.title.create': 'Enregistrer la configuration actuelle en tant que favori',
  'createPreset.title.update': 'Mettre à jour le favori actuel',

  // KickoffCard
  'explorerKickoffCard.headline': 'Sélectionner les points de données',
  'explorerKickoffCard.body': 'L’explorateur de données permet de comparer différents points de données ou valeurs de processus d\'un broyeur. Sélectionnez d\'abord les points de données à comparer.',
  'explorerKickoffCard.action.choose': 'Sélectionner les points de données',

  // ExplorerPreset
  'explorerPreset.body': `{count, plural,
    =0 {Aucun point de données}
    one {1 point de données}
    other {{count} points de données}
  }`,

  // ExplorerLayout
  'explorerLayout.breadcrumb': 'Explorateur de données',
  'explorerLayout.action.realtime': 'Temps réel',
  'explorerLayout.action.data': 'Points de données',
  'explorerLayout.action.favorites': 'Favoris',
  'explorerLayout.aggregation.title': 'Période d’affichage',

  // ExplorerEntry
  'explorerEntry.controls.filter.switch.costs': 'Afficher les coûts énergétiques',
  'explorerEntry.controls.filter.switch.activePower': 'Afficher la puissance apparente',
  'explorerEntry.emptySearch.text': 'Aucune donnée disponible pour la période choisie.',

  // Shredder Status
  'shredderStatus.since': 'depuis',
  'shredderStatus.status.active.label': '<strong>Production</strong>',
  'shredderStatus.status.idle.label': '<strong>Marche à vide</strong>',
  'shredderStatus.status.rest.label': '<strong>Arrêt</strong>',
  'shredderStatus.status.overload.label': '<strong>Inversion</strong> surcharge',
  'shredderStatus.status.cleaning.label': '<strong>Inversion</strong> nettoyage du rotor',
  'shredderStatus.status.defect.label': '<strong>Panne</strong> machine',
  'shredderStatus.status.defectExternal.label': '<strong>Panne</strong> externe',
  'shredderStatus.status.maintenance.label': '<strong>Maintenance</strong>',
  'shredderStatus.status.standby.label': '<strong>Veille</strong>',
  'shredderStatus.status.offline.label': '<strong>Hors ligne</strong>',
  'shredderStatus.status.eco.label': '<strong>Mode ECO</strong>',

  'stateOneMeasurement.preset.last24Hours': 'Les dernières 24 heures',
  'stateOneMeasurement.preset.prev7Days': 'Les 7 derniers jours',
  'stateOneMeasurement.preset.prev30Days': 'Les 30 derniers jours',
  'stateOneMeasurement.state.active.label': 'Production',
  'stateOneMeasurement.state.idle.label': 'Marche à vide',
  'stateOneMeasurement.state.standby.label': 'Veille',
  'stateOneMeasurement.state.rest.label': 'Immobilisation',
  'stateOneMeasurement.state.overload.label': 'Inversion surcharge',
  'stateOneMeasurement.state.cleaning.label': 'Inversion nettoyage du rotor',
  'stateOneMeasurement.state.defect.label': 'Panne machine',
  'stateOneMeasurement.state.defectExternal.label': 'Panne externe',
  'stateOneMeasurement.state.maintenance.label': 'Maintenance',
  'stateOneMeasurement.state.offline.label': 'Hors ligne',
  'stateOneMeasurement.state.eco.label': 'Mode ECO',

  'notificationSettings.text': 'Soyez informé par une alarme lors de la nouvelle réception.',
  'notificationSettings.type.email': 'Notifications par e-mail',
  'notificationSettings.type.sms': 'Notifications par SMS',
  'notificationSettings.template.subject': '{priority} : Alarme broyeur {shredder}',
  'notificationSettings.template.text': 'L’alarme suivante vient d’être déclenchée au niveau du broyeur {shredder} : « {title} » | {priority}',
  'notificationSettings.toast.sms.update.success': 'Notification par SMS actualisée',
  'notificationSettings.toast.email.update.success': 'Notification par e-mail actualisée',
  'notificationSettings.toast.sms.delete.success': 'Notification par SMS supprimée',
  'notificationSettings.toast.email.delete.success': 'Notification par e-mail supprimée',
  'notificationSettings.toast.sms.create.success': 'Notification par SMS créée',
  'notificationSettings.toast.email.create.success': 'Notification par e-mail créée',
  'notificationSettings.action.recipient.add': 'Ajouter destinataire',
  'notificationSettings.action.recipient.remove': 'Supprimer un destinataire',

  'units.label.celsius': 'Celsius',
  'units.label.fahrenheit': 'Fahrenheit',
  'units.label.voltage': 'Volt',
  'units.label.rpm': 'Tour par minute',
  'units.label.watt': 'Watt',
  'units.label.kilowatt': 'Kilowatt',
  'units.label.voltampere': 'Volt Ampere',
  'units.label.kva': 'Kilovolt Ampere',
  'units.label.microsiemensPerMeter': 'Mikrosiemens par mètre',
  'units.label.fillLevel': 'Niveau de remplissage',
  'units.label.hours': 'Heures',
  'units.label.newton': 'Newton',
  'units.label.torque': 'Couple',
  'units.label.permittivity': 'Permittivité',
  'units.label.percentage': 'Pourcent',
  'units.label.bar': 'Bar',
  'units.label.ampere': 'Ampère',
  'units.label.psi': 'Livre par pouce carré',
  'units.label.acceleration': 'Accélération',
  'units.label.frequency': 'Hertz',

  'units.symbol.celsius': '°C',
  'units.symbol.fahrenheit': '°F',
  'units.symbol.voltage': 'V',
  'units.symbol.rpm': 'tr/min',
  'units.symbol.watt': 'W',
  'units.symbol.kilowatt': 'kW',
  'units.symbol.voltampere': 'VA',
  'units.symbol.kva': 'kVA',
  'units.symbol.kwh': 'kWh',
  'units.symbol.microsiemensPerMeter': 'µS/m',
  'units.symbol.percentage': '%',
  'units.symbol.hours': 'h',
  'units.symbol.newton': 'N',
  'units.symbol.torque': 'Nm',
  'units.symbol.bar': 'bar',
  'units.symbol.psi': 'psi',
  'units.symbol.count': 'x',
  'units.symbol.ampere': 'A',
  'units.symbol.acceleration': 'm/s²',
  'units.symbol.frequency': 'Hz',

  'datePicker.presets.today': 'Aujourd’hui',
  'datePicker.presets.yesterday': 'Hier',
  'datePicker.presets.past3Days': '3 derniers jours',
  'datePicker.presets.past7Days': 'Dernière semaine',

  'date.day': 'Jour',
  'date.week': 'Semaine',
  'date.month': 'Mois',

  'csvImport.step.companies': 'Sociétés',
  'csvImport.step.users': 'Utilisateur',
  'csvImport.step.shredders': 'Broyeur',
  'csvImport.step.shredderAttributes': 'Caractéristique',
  'csvImport.step.salesPersons': 'Vendeur',
  'csvImport.dropArea.headline': 'Déplacer ici le fichier CSV',
  'csvImport.dropArea.body': 'ou',
  'csvImport.dropArea.cta': 'Sélectionner le fichier CSV',
  'csvImport.warning': 'Avertissement : L’importation écrase la base de données actuelle.',
  'csvImport.action.upload.success': 'Chargement du fichier {fileName} réussi.',
  'csvImport.action.upload.error': 'Une erreur s’est produite pendant l’actualisation des données. Veuillez réessayer ou vous adresser au service d’assistance UNTHA.',
  'csvImport.action.complete.success': 'Chargement des fichiers réussi.',

  'csvExport.data.companies': 'Sociétés',
  'csvExport.data.users': 'Utilisateur',
  'csvExport.data.shredders': 'Broyeur',
  'csvExport.data.shredderAttributes': 'Caractéristique',
  'csvExport.data.salesPersons': 'Vendeur',

  'companyTable.deleteDialog.title': 'Désactiver le client',
  'companyTable.deleteDialog.headline': 'Prudence',
  'companyTable.deleteDialog.body': 'Si un client est désactivé, les utilisateurs auxquels il est associé ne pourront plus se connecter au portail client.',
  'companyTable.deleteDialog.confirmation': 'Voulez-vous continuer ?',
  'companyTable.deleteDialog.notify': 'Envoyer un e-mail prévenant de la désactivation de tous les utilisateurs du client',
  'companyTable.search.input.placeholder': 'Recherche du client ...',
  'companyTable.toast.impersonate.active.error': 'Erreur lors de l’activation de la vue Client',

  'currencies.eur': 'Euro',
  'currencies.usd': 'Dollar US',
  'currencies.gbp': 'Livre sterling',
  'currencies.jpy': 'Yen',

  'userTable.dialog.delete.title': 'Supprimer un utilisateur',
  'userTable.dialog.delete.headline': 'Attention',
  'userTable.dialog.delete.text': 'L’utilisateur « {firstName} {lastName} » va être irrémédiablement effacé.',

  'explorerAnnouncement.title': 'Tutoriel Explorateur de données',
  'explorerAnnouncement.body': 'L’explorateur de données permet de comparer diverses données de fonctionnement et de processus.',
  'explorerAnnouncement.videoSrc.webm': '/videos/tutorials/untha_tutorial_data_explorer_en.webm',
  'explorerAnnouncement.videoSrc.mp4': '/videos/tutorials/untha_tutorial_data_explorer_en.mp4',

  'reportAnnouncement.title': 'Tutoriel Rapport de performances',
  'reportAnnouncement.body': 'Le rapport de performances permet de générer simplement une présentation des données de la machine. Les données sont réunies dans un fichiers PDF envoyé à l’adresse électronique indiquée, une fois ou toutes les semaines.',
  'reportAnnouncement.videoSrc.webm': '/videos/tutorials/untha_tutorial_report_en.webm',
  'reportAnnouncement.videoSrc.mp4': '/videos/tutorials/untha_tutorial_report_en.mp4',

  'historyChart.empty': 'Aucune donnée disponible pour la sélection.',
  'historyChart.action.reselect': 'Nouvelle sélection',
  'historyChart.error': 'Une erreur s’est produite pendant le traitement des données de l’historique. Veuillez réessayer ou vous adresser au service d’assistance UNTHA.',

  'tag.new.text': 'Nouveauté',
  'tag.newAbbr.text': 'Nouveauté',

  'aggregation.minutely': 'toutes les minutes',
  'aggregation.secondly': 'toutes les secondes',
  'aggregation.hourly': 'toutes les heures',

  'explorerSupportCard.headline': 'Attention !',
  'explorerSupportCard.body': "Cette fonction n’est malheureusement pas disponible sur cet appareil. Veuillez utiliser un appareil d'au moins 768 pixels.",

  'timeFormat.24h': '24 heures',
  'timeFormat.12h': '12 heures',

  'unitSystem.metricUnits': 'métrique',
  'unitSystem.imperialUnits': 'impérial',

  'salutation.mr': 'Monsieur',
  'salutation.mrs': 'Madame',

  'energyCosts.currency.title': 'Devise',
  'energyCosts.pricePerkWh.title': 'Prix au kWh',
  'energyCosts.action.update.success': 'Mise à jour des coûts énergétiques réussie',

  'userDetails.fallback.text': 'Chargement du profil ...',

  'useShredderSub.handshake.error': 'Impossible d’établir la connexion aux données en direct',
  'useShredderSub.subscription.error': 'Erreur lors de la connexion aux données en direct',

  'formValidation.error.email.required': 'Le champ Adresse e-mail ne doit pas être vide.',
  'formValidation.error.energyCosts.required': 'Le champ Coûts énergétiques ne doit pas être vide.',

  'downloads.headline.fallback': 'Téléchargements',

  'signIn.toast.spam': 'Connexion en cours.',

  'explorerZoom.action.reset': 'Annuler le zoom',

  'notificationDetails.stepByStep': 'Instructions étape par étape',

  'emptySearch.headline': 'Aucun résultat',

  'announcer.action.read': 'Ne plus afficher',

  'uiSettings.update.success': 'Actualisation de l’interface utilisateur réussie',

  'notificationTable.search.input.placeholder': 'Recherche de l’alarme ...',

  'copyright.label': '© {year} by UNTHA shredding technology GmbH',

  'shredderDataTile.body.count': '{count, plural,\n    =0 {Pas d’enregistrement}\n    one {1 enregistrement}\n    other {{count} enregistrements}\n  }',

  // TimestampIndicator
  'timestampIndicator.live': 'Données actuelles',
  'timestampIndicator.recent': 'dernière mise à jour {timestamp}',

  'pdfReport.header.label': 'Rapport de performances',
  'pdfReport.header.machine': 'Machine',
  'pdfReport.header.timerange': 'Période',
  'pdfReport.logBook.header': 'Journal',
  'pdfReport.stateOne.entryHeader': 'Statut',
  'pdfReport.stateOne.states.active': 'Production',
  'pdfReport.stateOne.states.idle': 'Marche à vide',
  'pdfReport.stateOne.states.standby': 'Veille',
  'pdfReport.stateOne.states.rest': 'Arrêt',
  'pdfReport.stateOne.states.overload': 'Inversion surcharge',
  'pdfReport.stateOne.states.cleaning': 'Inversion nettoyage du rotor',
  'pdfReport.stateOne.states.defect': 'Panne machine',
  'pdfReport.stateOne.states.defectExternal': 'Panne externe',
  'pdfReport.stateOne.states.maintenance': 'Maintenance',
  'pdfReport.stateOne.states.offline': 'Hors ligne',
  'pdfReport.stateOne.states.eco': 'Mode ECO',
  'pdfReport.globalParams.revUnplanned': 'Inversions par surcharge',
  'pdfReport.globalParams.operatingHours': 'Heures de service (à la fin de la période d\'observation)',
  'pdfReport.globalParams.disruptiveOffActive': 'Arrêts dûs à des substances parasites',
};

export default messages;
