import { useQuery } from 'react-query';
import { queryKeys } from 'src/constants';
import { db } from 'src/idb';
import type { Announcement } from 'src/types';

async function getAnnouncement(id: string): Promise<Announcement | undefined> {
  const tx = (await db).transaction('announcements', 'readonly');
  const store = tx.store;
  const announcement = await store.get(id);

  await tx.done;

  return (
    announcement && {
      ...announcement,
      read: !!announcement.read,
    }
  );
}

export function useAnnouncementReadOnly(id: string) {
  return useQuery([queryKeys.getAnnouncementReadOnly, id], () => getAnnouncement(id), {
    refetchInterval: 5000,
    useErrorBoundary: false,
    retry: false,
  });
}
