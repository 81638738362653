import clsx from 'clsx';
import React from 'react';
import { Spinner } from './Spinner';

export function AppSpinner() {
  return (
    <Spinner
      isLoading
      size="larger"
      className={clsx(
        'flex',
        'justify-center',
        'items-center',
        'fixed',
        'top-0',
        'left-0',
        'w-full',
        'h-full',
        'text-gray',
      )}
    />
  );
}
