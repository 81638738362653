import React from 'react';
import { useAlarms } from './useAlarms';
import { useAuth } from './useAuth';

type UseUnreadNotificationCountProps = {
  cumulocityId?: string;
};

export function useUnreadNotificationCount({
  cumulocityId,
}: UseUnreadNotificationCountProps = {}) {
  const { isSignedIn } = useAuth();

  const { data } = useAlarms({
    enabled: isSignedIn,
    pageSize: 100,
    suspense: false,
    refetchInterval: 10_000, // 10sec
    cumulocityId,
    useErrorBoundary: false,
  });

  return {
    count: data?.length ?? 0
  };
}
